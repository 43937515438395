import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Image = styled.div<{ background?: string }>`
    background-color: ${({ theme }) => theme.colors.light};
    height: 12rem;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ background }) =>
        background &&
        css`
            background-image: url('${background}');
            background-size: cover;
            background-position: center center;
        `}
`;

export const Logo = styled.img``;
