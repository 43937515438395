import React, { FC } from 'react';
import { Menu as Wrapper } from './styles';
import { NavItem } from 'components/atoms/NavigationMenuItem';
import { NavItemProps } from 'components/atoms/NavigationMenuItem/types';

export type NavigationMenuProps = {
    isOpen: boolean;
    items: NavItemProps[];
};

export const NavigationMenu: FC<NavigationMenuProps> = ({ items = [], isOpen }) => (
    <Wrapper>
        {items.map((item) => (
            <NavItem key={item.title} isMenuOpen={isOpen} {...item} />
        ))}
    </Wrapper>
);
