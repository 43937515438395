import { useApi } from 'hooks/api';
import { GymPlainModel } from 'hooks/gyms';
import { DayOfWeek } from 'hooks/timeSlotGroups';
import { GenderEnum } from 'hooks/user';
import { TimeSpan } from 'utils/momentFormatter';
import { buildUriWithParams } from 'utils/uriHelper';


export enum StatusRequirementType {
    NotActive = 1,
    Sleeping = 2
}

export interface StatusRequirementModel {
    id: string;
    type: StatusRequirementType;
    requiredDaysOfAbsence: number;
}

export interface LocationModel {
    id: string;
    name: string;
    slug: string;
    email?: string;
    street?: string;
    houseNumber?: string;
    postalCode?: string;
    city?: string;
    gym: GymPlainModel;
    statusRequirements: StatusRequirementModel[];
    memberExpectedInsideTime: number;
    qrIdCode: string;
    visibleOnRegistrationPage: boolean;
    hasProductOrderAccess: boolean;
}

export interface LocationPlainModel {
    id: string;
    name: string;
    street?: string;
    houseNumber?: string;
    postalCode?: string;
    city?: string;
    memberExpectedInsideTime: number;
}

export interface LocationPublicModel {
    id: string;
    name: string;
    street?: string;
    houseNumber?: string;
    postalCode?: string;
    city?: string;
    gymSlug?: string;
}

export interface LocationPublicPlainModel {
    id: string;
    name: string;
    street?: string;
    houseNumber?: string;
    postalCode?: string;
    city?: string;
}

export interface AddLocationModel {
    gymId: string;
    name: string;
    street?: string;
    houseNumber?: string;
    postalCode?: string;
    city?: string;
}

export interface EditLocationModel {
    id: string;
    name: string;
    slug: string;
    email: string;
    street?: string;
    houseNumber?: string;
    postalCode?: string;
    city?: string;
    memberExpectedInsideTime: number;
    visibleOnRegistrationPage: boolean;
    hasProductOrderAccess: boolean;
}

export enum PaymentProviderTypeEnum {
    Mollie = 0
}

export interface LocationPaymentProviderModel {
    id: string;
    type: PaymentProviderTypeEnum;
    apiKey: string;
}

export interface AddLocationPaymentProviderModel {
    type: PaymentProviderTypeEnum;
    apiKey: string;
}

export interface EditLocationPaymentProviderModel {
    id: string;
    type: PaymentProviderTypeEnum;
    apiKey: string;
}

export interface EditLocationStatusRequirementModel {
    id: string;
    type: StatusRequirementType;
    requiredDaysOfAbsence: number;
}

export interface EditLocationStatusRequirementsModel {
    locationId: string;
    statusRequirements: EditLocationStatusRequirementModel[];
}

export interface AllLocationsQueryOptions {
    employeeId?: string;
    locationId?: string;
    gymId?: string;
}

export interface AllPublicLocationsQueryOptions {
    gymId?: string;
    locationId?: string;
    locationSlug?: string;
}

export interface AllPostalCodeLocationsQueryOptions {
    postalCode: string;
    maxDistanceInKm: number;
}

export interface OpeningHoursInputModel {
    dayOfWeek: number;
    openingTime?: number;
    closingTime?: number;
}

export interface LocationOpeningHoursInputModel {
    locationId: string;
    openingHours: OpeningHoursInputModel[]
}

export interface OpeningHoursModel {
    dayOfWeek: DayOfWeek;
    openingTime?: TimeSpan;
    closingTime?: TimeSpan;
}

export interface DetailedUserModel {
    id: string;
    fullName: string;
    firstName: string;
    insertion?: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    street?: string;
    houseNumber?: string;
    city?: string;
    postalCode?: string;
    gender?: GenderEnum;
}

export interface LocationUserIdsInputModel {
    userId: string;
    locationId: string;
}

export interface LocationCustomerModel {
    id: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    planned?: Date;
}

export const useLocations = () => {
    const { callApi } = useApi();

    const allLocations = async (queryOptions?: AllLocationsQueryOptions) => {
        const uri = buildUriWithParams('/Location', queryOptions);

        const response = await callApi.current<LocationPlainModel[]>(uri);

        return response;
    };

    const allPublicLocations = async (queryOptions?: AllPublicLocationsQueryOptions) => {
        const uri = buildUriWithParams('/Location/Public', queryOptions);

        const response = await callApi.current<LocationPlainModel[]>(uri);

        return response;
    };

    const LocationsNearPostalCode = async (queryOptions?: AllPostalCodeLocationsQueryOptions) => {
        const uri = buildUriWithParams('/Location/InRangeOfPostalCode', queryOptions);

        const response = await callApi.current<LocationPublicModel[]>(uri);

        return response;
    };

    const addLocation = async (addLocationModel: AddLocationModel) => {
        const response = await callApi.current(
            '/Location',
            {
                method: 'POST',
                body: JSON.stringify(addLocationModel)
            }
        );

        return response;
    };

    const editLocation = async (editLocationModel: EditLocationModel) => {
        const response = await callApi.current(
            '/Location',
            {
                method: 'PUT',
                body: JSON.stringify(editLocationModel)
            }
        );

        return response;
    };

    const editLocationStatusRequirements = async (values: EditLocationStatusRequirementsModel) => {
        const response = await callApi.current(
            `/Location/${values.locationId}/EditStatusRequirements`,
            {
                method: 'PUT',
                body: JSON.stringify(values.statusRequirements)
            }
        );

        return response;
    };

    const locationDetails = async (locationId: string) => {
        const response = await callApi.current<LocationModel>(`/Location/${locationId}`);

        return response;
    };

    const editLocationQrCode = async (locationId: string) => {
        const response = await callApi.current<string>(
            `/Location/${locationId}/QrCode`,
            {
                method: 'PUT'
            }
        );

        return response;
    };

    const locationPaymentProvider = async (locationId: string) => {
        const response = await callApi.current<LocationPaymentProviderModel | null>(`/Location/${locationId}/PaymentProvider`);

        return response;
    };

    const addPaymentProvider = async (addLocationPaymentProviderModel: AddLocationPaymentProviderModel, locationId: string) => {
        const response = await callApi.current(`/Location/${locationId}/AddPaymentProvider`, {
            method: 'POST',
            body: JSON.stringify(addLocationPaymentProviderModel)
        });

        return response;
    };

    const editPaymentProvider = async (editLocationPaymentProviderModel: EditLocationPaymentProviderModel) => {
        const response = await callApi.current(`/LocationPaymentProvider/${editLocationPaymentProviderModel.id}`, {
            method: 'PUT',
            body: JSON.stringify(editLocationPaymentProviderModel)
        });

        return response;
    };

    const addNewLinkedEmployees = async (linkedEmployeeIds: string[], locationId: string) => {
        const response = await callApi.current(`/Location/${locationId}/NewEmployees`, {
            method: 'PUT',
            body: JSON.stringify(linkedEmployeeIds)
        });

        return response;
    };

    const removeLinkedEmployee = async (employeeId: string, locationId: string) => {
        const response = await callApi.current(`/Location/${locationId}/Employee/${employeeId}`, {
            method: 'DELETE'
        });

        return response;
    };

    const updateOpeningHours = async (model: LocationOpeningHoursInputModel) => {
        const response = await callApi.current('/LocationOpeningHours/openingHours', {
            method: 'POST',
            body: JSON.stringify(model)
        });
        return response;
    };

    const getOpeningHours = async (locationId: string) => {
        const response = await callApi.current<OpeningHoursModel[]>(`/LocationOpeningHours/openingHours/${locationId}`);
        return response;
    };

    const allCustomers = async (locationId: string) => {
        const response = await callApi.current<LocationCustomerModel[]>(`/Location/Customers/${locationId}`);
        return response;
    };

    return {
        allLocations,
        allPublicLocations,
        LocationsNearPostalCode,
        addLocation,
        editLocation,
        locationDetails,
        locationPaymentProvider,
        addPaymentProvider,
        editPaymentProvider,
        editLocationStatusRequirements,
        addNewLinkedEmployees,
        removeLinkedEmployee,
        editLocationQrCode,
        updateOpeningHours,
        getOpeningHours,
        allCustomers
    };
};
