import React from 'react';
import { Buttons } from './styles';
import InvalidFeedback from '../InvalidFeedback';
import { Option } from 'components/atoms/form/FormSelect';
import { Button } from 'components/atoms/button';

export interface FormButtonSelectProps<T extends number | string> {
    options: Option<T>[];
    error?: string;
    onChange?: (value: T) => void;
    value?: T;
    stretch?: boolean;
}

const FormButtonSelect = <T extends number | string>({ options, error, value, onChange, stretch }: FormButtonSelectProps<T>) => {
    // Methods.
    const handleOnChange = (option: Option<T>) => {
        if (onChange) {
            onChange(option.value);
        }
    };

    // Render.
    const selected = options.find((option) => option.value === value);

    return (
        <>
            <Buttons stretch={stretch}>
                {options.map((option) => {
                    const active = selected?.value === option.value;

                    return (
                        <Button type="button" brand="blue" white={!active} onClick={() => handleOnChange(option)} noFocus>
                            {option.title}
                        </Button>
                    );
                })}
            </Buttons>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormButtonSelect;
