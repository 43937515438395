import React, { FC } from 'react';
import { Footer as Wrapper, Text } from './styles';
import Pagination, { PaginationProps } from '../Pagination';

export interface FooterProps extends PaginationProps {
    totalRows: number;
}

const Footer: FC<FooterProps> = ({ totalRows, ...props }) => {
    const from = props.pageIndex * props.pageSize + (totalRows > 0 ? 1 : 0);
    const to = Math.min((props.pageIndex + 1) * props.pageSize, totalRows);

    return (
        <Wrapper>
            <Text>Resultaten: {from} - {to} van {totalRows}</Text>
            <Pagination {...props} />
        </Wrapper>
    );
};

export default Footer;
