export enum Claim {
    UserId = 'api.entreo.userId',
    UserName = 'api.entreo.userName',
    Roles = 'api.entreo.role',
    Rights = 'api.entreo.right',
    ActiveGymId = 'api.entreo.activeGymId',
    ActiveLocationId = 'api.entreo.activeLocationId',
    ActiveEmployerId = 'api.entreo.activeEmployerId',
    ActiveAssociationId = 'api.entreo.activeAssociationId',
    ActiveContentEnvironmentId = 'api.entreo.activeContentEnvironmentId',
    ActiveSupplierId = 'api.entreo.activeSupplierId',
    ActiveProviderId = 'api.entreo.activeProviderId',
    ActiveEnvironment = 'api.entreo.activeEnvironment',
    GymRights = 'api.entreo.gymRight',
    LocationRights = 'api.entreo.locationRight',
    LocationId = 'api.entreo.locationId',
    AccessLocationOrder = 'api.entreo.accessLocationOrder',
    EmployerId = 'api.entreo.employerId',
    SupplierId = 'api.entreo.Supplier'
}
