import React, { useCallback, useState } from 'react';
import useModal from '../modal';
import Modal from 'components/organisms/Modal';
import { useTranslation } from 'react-i18next';
import Loading from 'components/atoms/Loading';
import { toast } from 'react-toastify';
import { Form, Formik, FormikHelpers } from 'formik';
import { toFormikErrors } from 'utils/errorhelper';
import Panel from 'components/organisms/Panel';
import { useProviderSubscription } from 'hooks/entreoSubscription';
import { AddSubscriptionInputModel, EditSubscriptionInputModel, InvoicePeriodType, SubscriptionInputModel, SubscriptionProviderDetailModel, SubscriptionType } from 'hooks/entreoSubscription/types';
import { FormGroup, FormikFormControl, FormikFormDate, FormikFormDropFile, FormikFormMultiSelect, FormikFormNewSelect, FormikFormTextarea } from 'components/atoms/form';
import { IOption } from 'components/atoms/form/FormNewSelect';
import { useProviderProduct } from 'hooks/entreoProduct';
import { EditProviderProductInputModel } from 'hooks/entreoProduct/types';
import { TagModel } from 'hooks/entreoTag/types';
import { useTag } from 'hooks/entreoTag';
import { useSession } from 'contexts/sessionContext';
import FormikFormCurrency from 'components/atoms/form/FormCurrency/formik';
import { cdnFileUrl } from 'utils/cdnHelper';
import * as yup from 'yup';
import { Col, Row } from 'react-grid-system';

const useProviderSubscriptionModal = (onRefresh: () => void): [(subscriptionId?: string) => void, () => void, boolean] => {
    const { t } = useTranslation('entreoSubscription');
    const { addSubscription, getSubscription, editSubscription } = useProviderSubscription();
    const { session } = useSession();

    const { allProducts } = useProviderProduct();
    const { allTags } = useTag();
    const [products, setProducts] = useState<EditProviderProductInputModel[]>([]);
    const [tags, setTags] = useState<TagModel[]>([]);

    const fetchProducts = async () => {
        setIsProductsLoading(true);
        const response = await allProducts();

        if (response.ok && response.data) {
            setProducts(response.data);
        } else {
            toast.error(t('fetchAllError'));
        }
        setIsProductsLoading(false);
    };
    const fetchTags = async () => {
        setIsTagsLoading(true);
        const response = await allTags();

        if (response.ok && response.data) {
            setTags(response.data);
        } else {
            toast.error(t('fetchAllError'));
        }
        setIsTagsLoading(false);
    };

    const productOptions: IOption[] = products.map((product: EditProviderProductInputModel) => { return { label: product.name, value: product?.id ?? '' }; });
    const tagOptions: IOption[] = tags.map((tag: TagModel) => { return { label: tag.text, value: tag?.id ?? '' }; });
    const invoicePeriodOptions: IOption[] = [{ label: t('modal.form.invoicePeriod.monthly'), value: InvoicePeriodType.Monthly }/* , { label: t('modal.form.invoicePeriod.fourWeeks'), value: InvoicePeriodType.FourWeeks } */];
    const subscriptionTypeOptions: IOption[] = [{ label: t('modal.form.subscriptionType.standard'), value: SubscriptionType.Standard }, { label: t('modal.form.subscriptionType.information'), value: SubscriptionType.Information }, { label: t('modal.form.subscriptionType.external'), value: SubscriptionType.External }];

    // State.
    const [subscription, setSubscription] = useState<SubscriptionProviderDetailModel | undefined>();
    const [isTagsLoading, setIsTagsLoading] = useState(false);
    const [isProductsLoading, setIsProductsLoading] = useState(false);
    const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);
    const isLoading = isTagsLoading || isProductsLoading || isSubscriptionLoading;

    const fetchSubscription = async (subscriptionId: string) => {
        setIsSubscriptionLoading(true);
        const response = await getSubscription(subscriptionId ?? '');
        if (response && response.data) {
            const subscriptionData = response.data;
            setSubscription(subscriptionData);
        }
        setIsSubscriptionLoading(false);
    };

    // Methods.
    const handleOnSubmit = async (values: SubscriptionInputModel, helpers: FormikHelpers<SubscriptionInputModel>) => {
        if (!!subscription && ('id' in values)) {
            handleEdit(values, helpers as FormikHelpers<EditSubscriptionInputModel>);
        } else {
            handleAdd(values, helpers);
        }
        setSubscription(undefined);
    };

    const handleAdd = async (values: AddSubscriptionInputModel, helpers: FormikHelpers<AddSubscriptionInputModel>) => {
        const data = { ...values };
        switch (values.invoicePeriod) {
            case 0:
                data.fourWeekCosts = 0;
                break;
            case 1:
                data.monthlyCosts = 0;
                break;
            default:
                data.fourWeekCosts = 0;
                data.monthlyCosts = 0;
                break;
        }
        const response = await addSubscription(data);

        if (response.ok) {
            onRefresh();
            toast.success(t('common:successAdd'));
            hide();
        } else if (response.errors) {
            const errors = toFormikErrors(response.errors);
            helpers.setErrors(errors);
        } else {
            toast.warning(t('common:general_error'));
            //Show errors in the steps?
        }
    };

    const handleEdit = async (values: EditSubscriptionInputModel, helpers: FormikHelpers<EditSubscriptionInputModel>) => {
        const data = { ...values };
        switch (values.invoicePeriod) {
            case 0:
                data.fourWeekCosts = 0;
                break;
            case 1:
                data.monthlyCosts = 0;
                break;
            default:
                data.fourWeekCosts = 0;
                data.monthlyCosts = 0;
                break;
        }
        const response = await editSubscription(data);

        if (response.ok) {
            onRefresh();
            toast.success(t('common:successEdit'));
            hide();
        } else if (response.errors) {
            const errors = toFormikErrors(response.errors);
            helpers.setErrors(errors);
        } else {
            toast.warning(t('common:general_error'));
            //Show errors in the steps?
        }
    };

    const setInitialValues = (): SubscriptionInputModel => {
        return subscription ? {
            id: subscription.id,
            providerId: session?.activeProviderId ?? '',
            name: subscription.name,
            description: subscription.description !== null ? subscription.description : '',
            invoicePeriod: (subscription?.id && subscription.monthlyCosts && Number(subscription.monthlyCosts) > 0) ? 0 : (subscription?.id && subscription.fourWeekCosts && Number(subscription.fourWeekCosts) > 0) ? 1 : undefined,
            monthlyCosts: subscription.monthlyCosts?.toString(),
            fourWeekCosts: subscription.fourWeekCosts?.toString(),
            registrationCosts: subscription.registrationCosts?.toString(),
            frequency: subscription.frequency,
            durationInMonths: subscription.durationInMonths,
            inactive: subscription.inactive !== null ? new Date(subscription.inactive + 'Z') : undefined,
            startDate: subscription.startDate !== null ? new Date(subscription.startDate + 'Z') : undefined,
            subscriptionType: subscription.subscriptionType,
            tags: subscription.tags,
            products: subscription.products,
            order: subscription.order,
            banner: undefined,
            bannerCdnHash: subscription.bannerCdnHash,
        } : {
            providerId: session?.activeProviderId ?? '',
            name: '',
            description: '',
            invoicePeriod: InvoicePeriodType.Monthly,
            monthlyCosts: '',
            fourWeekCosts: '',
            frequency: '',
            subscriptionType: SubscriptionType.Standard,
            tags: [],
            products: [],
            banner: undefined,
            bannerCdnHash: ''
        };
    };

    const validationSchema = yup.object().shape({
        banner: yup.mixed()
            .when('bannerCdnHash', {
                is: () => setInitialValues().bannerCdnHash?.length === 0,
                then: yup.mixed().required(t('common:validation.isRequired')),
                otherwise: yup.mixed().nullable().notRequired(),
            }),
        name: yup.string()
            .required(t('common:validation.isRequired'))
            .min(8, t('common:validation.min', { value: 8 }).toString())
            .max(64, t('common:validation.max', { value: 65 }).toString()),
        description: yup.string()
            .min(8, t('common:validation.min', { value: 8 }).toString())
            .max(256, t('common:validation.max', { value: 256 }).toString()),
        invoicePeriod: yup.number()
            .required(t('common:validation.isRequired')),
        monthlyCosts: yup.string()
            .when('invoicePeriod', {
                is: (val: number) => val === 0,
                then: yup.string().required(t('common:validation.isRequired')).min(1),
            }),
        fourWeekCosts: yup.string()
            .when('invoicePeriod', {
                is: (val: number) => val === 1,
                then: yup.string().required(t('common:validation.isRequired')).min(1),
            }),
        tags: yup.array()
            .required(t('common:validation.isRequired'))
            .min(1, t('common:validation.minLength', { count: 1 })),
        products: yup.array()
            .required(t('common:validation.isRequired'))
            .min(1, t('common:validation.minLength', { count: 1 })),
    });


    // Render.
    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => {
            if (isLoading) {
                return (
                    <Modal onHide={onHideModal} inProp={inProp} onExited={onExited} title={t('title')} size="large">
                        <Loading />
                    </Modal>
                );
            }
            const initialValues = setInitialValues();

            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            return (
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleOnSubmit}
                    validationSchema={validationSchema}
                    validateOnChange
                    validateOnBlur
                >
                    {({ submitForm, isSubmitting, values, touched }) => {
                        return (
                            <Panel
                                onHide={onHideModal}
                                inProp={inProp}
                                onExited={onExited}
                                title={t(`modal.form.title.${subscription === undefined ? 'add' : 'edit'}`)}
                                onCloseSide
                                buttons={[{ title: t('common:button.confirm'), onClick: submitForm, loading: isSubmitting, disabled: isSubmitting }]}
                                onTouched={touched}
                            >
                                <Form>
                                    <>
                                        <FormikFormDropFile
                                            label={t('modal.form.image')}
                                            name="banner"
                                            image={values.banner
                                                ? URL.createObjectURL(values.banner)
                                                : (values.bannerCdnHash
                                                    ? cdnFileUrl(values.bannerCdnHash, null)
                                                    : undefined)
                                            }
                                            required
                                        />
                                        <FormGroup label={t('modal.form.name')} required>
                                            <FormikFormControl name="name" />
                                        </FormGroup>
                                        <FormGroup label={t('modal.form.description')}>
                                            <FormikFormTextarea name="description" />
                                        </FormGroup>
                                        <Row>
                                            <Col>
                                                <FormGroup label={t('modal.form.invoicePeriod.title')} required>
                                                    <FormikFormNewSelect name="invoicePeriod" options={invoicePeriodOptions} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {values.invoicePeriod === 0 && (
                                            <FormGroup label={t('modal.form.monthlyCosts')} required>
                                                <FormikFormCurrency name="monthlyCosts" defaultValue={values.monthlyCosts ?? 0} />
                                            </FormGroup>
                                        )}
                                        {values.invoicePeriod === 1 && (
                                            <FormGroup label={t('modal.form.fourWeekCosts')} required>
                                                <FormikFormCurrency name="fourWeekCosts" defaultValue={values.fourWeekCosts ?? 0} />
                                            </FormGroup>
                                        )}
                                        <FormGroup label={t('modal.form.registrationCosts')}>
                                            <FormikFormCurrency name="registrationCosts" defaultValue={values.registrationCosts ?? 0} />
                                        </FormGroup>
                                        <FormGroup label={t('modal.form.frequency')}>
                                            <FormikFormControl name="frequency" />
                                        </FormGroup>
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup label={t('modal.form.durationInMonths')}>
                                                    <FormikFormControl name="durationInMonths" type="number" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup label={t('modal.form.startDate')}>
                                                    <FormikFormDate name="startDate" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup label={t('modal.form.subscriptionType.title')} required>
                                                    <FormikFormNewSelect name="subscriptionType" options={subscriptionTypeOptions} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup label={t('modal.form.tags')} required>
                                                    <FormikFormMultiSelect isMulti name="tags" options={tagOptions} placeholder={t('modal.form.tagsPlaceholder')} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup label={t('modal.form.products')} required>
                                                    <FormikFormMultiSelect isMulti name="products" options={productOptions} placeholder={t('modal.form.productsPlaceholder')} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup label={t('modal.form.order')}>
                                            <FormikFormControl name="order" type="number" />
                                        </FormGroup>
                                    </>
                                </Form>
                            </Panel>
                        );
                    }}
                </Formik>
            );
        },
        [isLoading, subscription, tagOptions, productOptions]
    );

    const showModal = useCallback(
        async (subscriptionId?: string) => {
            fetchProducts();
            fetchTags();
            if (subscriptionId) {
                fetchSubscription(subscriptionId);
            }
            show();
        },
        [show]
    );

    const onHideModal = () => {
        setSubscription(undefined);
        hide();
    };

    return [showModal, hide, isShowing];
};

export default useProviderSubscriptionModal;

