import styled from '@emotion/styled';

export const Tabs = styled.div`
    padding: 0 ${({ theme }) => theme.card.padding.horizontal}px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.light};
    display: flex;
    align-items: center;
`;

export const Tab = styled.div<{ active?: boolean }>`
    margin-right: 32px;
    height: 4rem;
    display: flex;
    border-bottom: 3px solid ${({ theme, active }) => (active ? theme.brand.pink : 'transparent')};

    &:hover {
        cursor: pointer;
    }
`;
