import React, { useState } from 'react';
import useModal from '../modal';
import Modal from 'components/organisms/Modal';
import { FormGroup, FormikFormControl, FormikFormSwitch, FormikFormTextarea } from 'components/atoms/form';
import { Form, Formik } from 'formik';
import { Button } from 'components/atoms/button';

interface ContactValues {
    id?: string;
    firstName: string;
    insertion?: string;
    lastName: string;
    description?: string;
    phoneNumber: string;
    email: string;
    registrations: boolean;
    newsletters: boolean;
    invoices: boolean;
}


const useAddContactModal = (
    onSubmit: (values: ContactValues, index?: number) => void
): [(index?: number, values?: ContactValues) => void, () => void, boolean] => {
    // State.
    const [index, setIndex] = useState<number>();
    const [values, setValues] = useState<ContactValues>();

    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => {
            const initialValues: ContactValues = values ?? {
                firstName: '',
                insertion: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                registrations: false,
                newsletters: false,
                invoices: false
            };

            return (
                <Modal onHide={hide} inProp={inProp} onExited={onExited} title="Contactpersoon toevoegen">
                    <Formik<ContactValues> onSubmit={handleOnSubmit} initialValues={initialValues}>
                        <Form>
                            <FormGroup label="Voornaam">
                                <FormikFormControl name="firstName" />
                            </FormGroup>
                            <FormGroup label="Tussenvoegsel">
                                <FormikFormControl name="insertion" />
                            </FormGroup>
                            <FormGroup label="Achternaam">
                                <FormikFormControl name="lastName" />
                            </FormGroup>
                            <FormGroup label="Omschrijving">
                                <FormikFormTextarea name="description" />
                            </FormGroup>
                            <FormGroup label="Telefoonnummer">
                                <FormikFormControl name="phoneNumber" />
                            </FormGroup>
                            <FormGroup label="E-mail adres">
                                <FormikFormControl name="email" />
                            </FormGroup>
                            <FormGroup>
                                <FormikFormSwitch
                                    name="registrations"
                                    label="Deze contactpersoon dient berichten over nieuwe aanmeldingen te ontvangen."
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormikFormSwitch name="newsletters" label="Deze contactpersoon dient nieuwsbrieven te ontvangen. " />
                            </FormGroup>
                            <FormGroup>
                                <FormikFormSwitch name="invoices" label="Deze contactpersoon dient facturen te ontvangen" />
                            </FormGroup>
                            <Button brand="yellow" type="submit">
                                Toevoegen
                            </Button>
                        </Form>
                    </Formik>
                </Modal>
            );
        },
        [index, values]
    );

    // Methods.
    const handleOnSubmit = (values: ContactValues) => {
        hide();
        onSubmit(values, index);
    };

    const onShowModal = (index?: number, values?: ContactValues) => {
        setValues(values);
        setIndex(index);
        show();
    };

    return [onShowModal, hide, isShowing];
};

export default useAddContactModal;
