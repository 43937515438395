import styled from '@emotion/styled';

export const Wrapper = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: ${({ theme }) => theme.borderRadius.large}px;
    padding: 2rem;
    text-align: center;
    p {
        color: ${({ theme }) => theme.colors.blue};
        font-size: .9rem;
        font-weight: 600;
    }
`;

export const FormControl = styled.input`
    width: 100%;
    height: auto;
    font-weight: 700;
    border: none;
    padding: 1rem;
    margin: -1rem 0;
    font-size: ${({ theme }) => theme.fontSizes.h1}px;
    box-shadow: none;
    background: none;
    text-align: center;

    &:focus {
        outline: none;
    }

    &:hover {
    }

    ::placeholder {
        color: ${({ theme }) => theme.colors.disabled};
    }
`;
