import { useUserAuthorization } from 'hooks/authorization/useUserAuthorization';
import { useNavigationMenu } from 'hooks/menu/useNavigationMenu';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

type NavState = 'open' | 'closed';
type PageLocationInformation = { currentLocation: string; menuPaths: Record<string, boolean> };

const NAVIGATION_STATE_KEY = 'navState';

const isRouteValid = ({ currentLocation = '', menuPaths = {} }: PageLocationInformation) =>
    !!Object.keys(menuPaths).find((key) => key === currentLocation);
const getCurrentPagePermission = ({ currentLocation = '', menuPaths = {} }: PageLocationInformation) => menuPaths[currentLocation];

export const usePageTemplate = () => {
    const [navState, setNavState] = useState<NavState>((localStorage.getItem(NAVIGATION_STATE_KEY) as NavState) ?? 'open');
    const location = useLocation();
    const navigate = useNavigate();
    const { userAuthorization, getUserAuthorizationData } = useUserAuthorization();
    const { menuPaths, menuItems } = useNavigationMenu();

    const currentLocation = location.pathname;
    const isOpen = navState === 'open';

    useEffect(() => {
        getUserAuthorizationData();
    }, []);

    useEffect(() => {
        if (!menuItems.length) return;

        const pageLocationInfo = { currentLocation, menuPaths };

        if (isRouteValid(pageLocationInfo) && !getCurrentPagePermission(pageLocationInfo)) {
            navigate('/access-denied');

            return;
        }

        if (currentLocation === '/') {
            navigate(menuItems[0].to);
        }
    }, [currentLocation, menuItems, menuPaths]);

    const handleNavBarToggle = useCallback(() => {
        const newNavState = isOpen ? 'closed' : 'open';

        setNavState(newNavState);
        localStorage.setItem(NAVIGATION_STATE_KEY, newNavState);
    }, [isOpen]);

    return {
        isOpen,
        menuItems,
        navState,
        userAuthorization,
        handleNavBarToggle
    };
};
