import { Global } from '@emotion/react';
import React, { FC } from 'react';
import CurrencyInput from 'react-currency-input-field';
import InvalidFeedback from '../InvalidFeedback';
import { styles } from './styles';

export interface FormCurrencyProps {
    name: string;
    defaultValue?: number | string;
    onChange: (e: any, fieldValue?: string | undefined) => void;
    disableGroupSeparator?: boolean;
    locale?: 'nl-NL';
    currency?: 'EUR';
    decimalSeparator?: '.' | ',';
    groupSeparator?: '.' | ',';
    error?: string
}

const FormCurrencyInput: FC<FormCurrencyProps> = ({ name, defaultValue, onChange, disableGroupSeparator = false, locale = 'nl-NL', currency = 'EUR', decimalSeparator = ',', groupSeparator = '.', error, ...props }) => {

    return (
        <>
            <Global styles={styles} />
            <CurrencyInput
                intlConfig={{ locale: locale, currency: currency }}
                className="react-currency-input-field"
                name={name}
                defaultValue={defaultValue}
                decimalScale={2}
                decimalSeparator={decimalSeparator}
                disableGroupSeparators={disableGroupSeparator}
                groupSeparator={groupSeparator}
                onValueChange={(value, name) => onChange(name, value)}
            />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormCurrencyInput;
