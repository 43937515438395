import React, { FC } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import Icon from 'components/atoms/Icon';
import Dropzone from 'react-dropzone';
import { Text, Wrapper } from './styles';

export interface FormDropFileProps {
    error?: string;
    value?: File;
    onClick?: () => void;
    onChange?: (value: File) => void;
    label?: string;
    image?: string;
    showName?: boolean;
    required?: boolean;
    onAfterChange?: (value: File) => void;
}

const FormDropFile: FC<FormDropFileProps> = ({ error, label, onClick, onChange, value, image, showName = true, required, onAfterChange }) => {
    const handleOnDrop = (files: File[]) => {
        if (onChange && files.length > 0) {
            onChange(files[0]);
            onAfterChange && onAfterChange(files[0]);
        }
    };

    const handleOnClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return (
        <Dropzone onDrop={handleOnDrop} maxFiles={1} onFileDialogCancel={handleOnClick}>
            {({ getRootProps, getInputProps }) => (
                <Wrapper {...getRootProps()} background={image}>
                    <input {...getInputProps()} />
                    {
                        showName && (
                            value ? (
                                <>
                                    <Icon name="edit" size={2} />
                                    <Text required={required}>{value.name}</Text>
                                </>
                            ) : (
                                <>
                                    <Icon name="add-file" size={2} />
                                    <Text required={required}>{label}</Text>
                                </>
                            )
                        )
                    }
                    {error && <InvalidFeedback>{error}</InvalidFeedback>}
                </Wrapper>
            )}
        </Dropzone>
    );
};

export default FormDropFile;
