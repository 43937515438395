import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rgba } from 'polished';

import { Theme } from 'static/theme';
import IconButton from '../IconButton';
import { NavLink } from 'react-router-dom';

const getSubMenuColor = (sub: boolean | undefined, theme: Theme) => (sub ? rgba(theme.brand.pink, 0.5) : theme.brand.pink);
const commonFlexProperties = css`
    display: flex;
    align-items: center;
`;

export const MenuItemWrapper = styled.div<{ active?: boolean; customPadding?: number; sub?: boolean }>`
    ${commonFlexProperties}
    position: relative;
    list-style-type: none;
    user-select: none;
    text-decoration: none;

    ${({ sub }) =>
        !sub
            ? css`
                  min-height: 3.5rem;
                  height: auto;
                  margin-right: 1.5rem;
                  padding: 0 1rem 0 2rem;
                  border-radius: 1.75rem;
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
              `
            : css`
                  margin: 0.5rem 0 0.5rem 1.5rem;
                  padding: 0 1rem;
                  border-radius: 1.125rem;
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
              `}

    ${({ active, sub, theme }) =>
        active
            ? css`
                  background-color: ${getSubMenuColor(sub, theme)};
              `
            : css`
                  &:hover {
                      background-color: ${rgba(theme.brand.pink, 0.2)};
                  }
              `}

    ${({ customPadding }) =>
        customPadding &&
        css`
            padding-top: ${customPadding}rem;
            padding-bottom: ${customPadding}rem;
        `}
`;

export const StyledIconButton = styled(IconButton)<{ sub?: boolean; color: string }>`
    margin-right: ${({ sub }) => sub ?? '16px'};
    svg {
        color: ${({ color }) => color};
    }
`;

export const StyledNavLink = styled(NavLink)`
    ${commonFlexProperties}
`;

export const TitleWrapper = styled.div`
    min-height: 3rem;
    ${commonFlexProperties}
`;

export const Title = styled.p<{ active?: boolean }>`
    margin: 0;
    ${commonFlexProperties}
    font-weight: ${({ active }) => (active ? 600 : 500)};
    font-size: 1rem;
    color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.dark)};
`;
