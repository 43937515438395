import Group from 'components/molecules/Group';
import React, { FC, PropsWithChildren, useState } from 'react';
import { Content, Text, Wrapper } from './styles';

export interface EditRowProps {
    title: string;
}

const EditRow: FC<PropsWithChildren<EditRowProps>> = ({ children, title }) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);

    if (isEditing) {
        return (
            <Wrapper>
                <Content>{children}</Content>
                <Group>
                    <Text onClick={() => setIsEditing(false)}>Annuleren</Text>
                    <Text onClick={() => setIsEditing(false)} underline>
                        Gereed
                    </Text>
                </Group>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Content />
            <Text onClick={() => setIsEditing(true)} underline>
                {title}
            </Text>
        </Wrapper>
    );
};

export default EditRow;

