import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FormRadioProps } from '.';

export const Radio = styled.div<{ checked?: boolean }>`
    width: 1rem;
    height: 1rem;
    border: 1px solid ${({ theme }) => theme.greys[100]};
    background-color: white;
    border-radius: 50%;
    display: flex;
    flex: 0 0 1rem;
    justify-content: center;
    align-items: center;

    &:after {
        content: '';
        background-color: ${({ theme }) => theme.colors.primary};
        width: 0;
        height: 0;
        border-radius: 50%;
        transition: width 0.15s ease, height 0.15s ease;
    }

    &:hover {
        border-color: ${({ theme }) => theme.greys[300]};

        ${({ checked }) =>
        !checked &&
        css`
                &:after {
                    width: 4px;
                    height: 4px;
                }
            `}
    }

    ${({ checked }) =>
        checked &&
        css`
            &:after {
                width: 0.5rem;
                height: 0.5rem;
            }
        `}
`;

export const HiddenRadio = styled.input<FormRadioProps>`
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:focus + div {
        outline: none;
        box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.greys[50]};
    }
`;

export const Label = styled.span`
    color: ${({ theme }) => theme.colors.dark};
    margin-left: 8px;
    margin-right: 16px;
    font-size: 1rem;
    line-height: 1rem;
`;

export const Wrapper = styled.label`
    display: inline-flex;
    align-items: center;
    user-select: none;

    &:hover {
        cursor: pointer;
    }
`;
