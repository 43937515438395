import { useTranslation } from 'react-i18next';
import { Button } from 'components/atoms/button';
import { Paragraph } from 'components/atoms/text';
import { Actions, Floating } from './styles';
import { FC } from 'react';
import React from 'react';

interface ActionProps {
    actions: { label: string, onClick: () => void }[];
    selected: number;
}

const FloatingComponent: FC<ActionProps> = ({ actions, selected }) => {
    const { t } = useTranslation('common');
    return (
        <Floating>
            <Paragraph noMargin>
                {t('listview.selection', { count: selected })}
            </Paragraph>
            <Actions>
                {actions.map((action, index) => {
                    return (
                        <Button noMargin rounded isOutline={index !== 0} key={index} onClick={action.onClick} type="button">
                            {action.label}
                        </Button>
                    );
                })}
            </Actions>
        </Floating>
    );
};

export default FloatingComponent;
