import React, { useCallback, useState } from 'react';
import useModal from 'hooks/modals/modal';
import { cdnFileUrl } from 'utils/cdnHelper';
import Modal from 'components/organisms/Modal';
import { useTranslation } from 'react-i18next';
import { Paragraph } from 'components/atoms/text';

const useShowImageModal = ()
    : [(image: string) => void, () => void, boolean] => {

    const [storeItemImage, setStoreItemImage] = useState<string>('');
    const { t } = useTranslation('store');

    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => {
            return (
                <Modal onHide={hide} inProp={inProp} onExited={onExited} size="large">
                    {
                        storeItemImage !== null
                            ?
                            <img
                                src={cdnFileUrl(storeItemImage, null)}
                                alt=""
                                width="100%"
                            />
                            :
                            <Paragraph>{t('noImage')}</Paragraph>
                    }
                </Modal>
            );
        }, [storeItemImage]
    );

    // Methods.
    const showModal = useCallback((image: string) => {
        setStoreItemImage(image);
        show();
    }, [show]);

    return [showModal, hide, isShowing];
};

export default useShowImageModal;
