import React, { FC, InputHTMLAttributes } from 'react';
import { Switch, Wrapper, Label } from './styles';

export interface FormSwitchProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
}

const FormSwitch: FC<FormSwitchProps> = (props) => {
    return (
        <Wrapper>
            <Switch>
                <input type="checkbox" {...props} />
                <div className="switch" />
            </Switch>
            {props.label && <Label>{props.label}</Label>}
        </Wrapper>
    );
};

export default FormSwitch;
