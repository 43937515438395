import { useApi } from 'hooks/api';
import { useSession } from 'contexts/sessionContext';
import { LocationPlainModel } from 'hooks/locations';

export interface FileModel {
    hash: string;
    fileName: string;
}

export interface GymPlainModel {
    id: string;
    name: string;
    slug: string;
}

export interface GymPublicModel {
    id: string;
    name: string;
    banner?: FileModel;
}

export interface GymPublicPlainModel {
    id: string;
    name: string;
}

export interface AddGymModel {
    name: string;
}

export interface EditGymModel {
    id: string;
    name: string;
    slug: string;
}

export interface GymModel {
    id: string;
    name: string;
    slug: string;
    locations: LocationPlainModel[];
}

export interface EditGymRegistrationSettingsInputModel {
    id: string;
    banner?: File;
}

export interface GymRegistrationSettingsModel {
    id: string;
    banner?: FileModel;
}

export const useGyms = () => {
    const { callApi } = useApi();
    const { session } = useSession();

    const allPlainGyms = async () => {
        const response = await callApi.current<GymPlainModel[]>(
            '/Gym',
            {
                method: 'GET'
            }
        );

        return response;
    };

    const getPublicGym = async (slug: string) => {
        const response = await callApi.current<GymPublicModel>(
            `/Gym/v1/public/${slug}`,
            {
                method: 'GET'
            }
        );

        return response;
    };

    const addGym = async (addGymModel: AddGymModel) => {
        const response = await callApi.current(
            '/Gym/v1',
            {
                method: 'POST',
                body: JSON.stringify(addGymModel)
            }
        );

        return response;
    };

    const editGym = async (editGymModel: EditGymModel) => {
        const response = await callApi.current(
            '/Gym/v1',
            {
                method: 'PUT',
                body: JSON.stringify(editGymModel)
            }
        );

        return response;
    };

    const deleteGym = async (gymId: string) => {
        const response = await callApi.current(
            '/Gym/v1',
            {
                method: 'DELETE',
                body: JSON.stringify({ id: gymId })
            }
        );

        return response;
    };

    const getGym = async (gymId: string) => {
        const response = await callApi.current<GymModel>(`/Gym/v1/${gymId}`);

        return response;
    };

    const getCurrentGym = async () => {
        const response = await getGym(session?.activeGymId ?? '');
        return response;
    };

    const editGymRegistrationSettings = async (values: EditGymRegistrationSettingsInputModel) => {
        const fd = new FormData();
        fd.append('id', values.id);
        if (values.banner != null) {
            fd.append('banner', values.banner);
        }
        const response = await callApi.current(
            '/Gym/v1/Registration',
            {
                method: 'PUT',
                body: fd
            });

        return response;
    };

    const getGymRegistrationSettings = async (gymId: string) => {
        const response = await callApi.current<GymRegistrationSettingsModel>(`/Gym/v1/${gymId}/registrationSettings`);

        return response;
    };

    const deleteGymBanner = async (gymId: string) => {
        const response = await callApi.current(`/Gym/v1/${gymId}/banner`, {
            method: 'DELETE'
        });

        return response;
    };

    return {
        allPlainGyms,
        getPublicGym,
        addGym,
        editGym,
        deleteGym,
        getGym,
        getCurrentGym,
        editGymRegistrationSettings,
        getGymRegistrationSettings,
        deleteGymBanner
    };
};
