import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { CardProps } from '../Card';

export const Card = styled.div<CardProps>`
    height: ${({ full }) => full ? '100%' : 'auto'};
    padding-bottom: ${({ noMargin }) => (noMargin ? 0 : '2rem')};
`;

export const Inner = styled.div<CardProps>`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.large}px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: ${({ overflow }) => overflow};
    height: ${({ full }) => full ? '100%' : 'auto'};

    ${({ theme, variant }) => {
        const color = theme.colors[variant || 'white'];

        return css`
            background-color: ${color};
        `;
    }};
`;
