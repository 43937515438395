import React, { FC } from 'react';
import { ReactSVG } from 'react-svg';
import colors from 'static/theme/colors';
import classnames from 'classnames';
import { Icons } from 'static/theme/icons';

interface IconProps {
    size?: number;
    color?: string;
    name: keyof Icons;
    className?: string;
    onClick?: React.MouseEventHandler & React.MouseEventHandler<SVGSVGElement>;
}

const Icon: FC<IconProps> = ({ size = 1.5, color = colors.dark, name, className, onClick }) => {
    let icon;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    try {
        icon = require(`static/icons/${name}.svg`);
    } catch {
        return null;
    }

    return (
        <ReactSVG
            wrapper="span"
            className={classnames('icon-wrapper', className)}
            beforeInjection={(svg) => {
                svg.setAttribute('style', `width: ${size}rem; height: ${size}rem;`);
                color && svg.setAttribute('fill', color);
            }}
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            src={icon.default}
            onClick={onClick}
        />
    );
};

export default Icon;
