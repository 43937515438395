import { useApi } from 'hooks/api';
import { ActivityType } from 'hooks/challengeTemplate';
import { TimeFilter } from 'utils/timeFilterHelper/timeFilterHelper';


export interface MiniatureGraphDataModel {
    amountDoneInPeriod: number;
    percentageChangeFromLastPeriod: number;
}

export interface ActivitiesPerDayModel {
    date: Date;
    amount: number;
}

export interface XpOverviewModel {
    currentXp: number;
    totalBoughtXp: number;
    totalUsedXp: number;
}

export interface EmployeesUsingEntreoModel {
    amountUsingEntreo: number;
    amountInvited: number;
    acceptationIncrease: number;
}

export interface SubscriptionsGrowthModel {
    percentageGlobalGymSubscription: number;
    percentageOfEmployeesGymSubscription: number;
}

export interface SubscriptionsGrowthChartCategoryModel {
    color: string;
    label: string;
    amountChange?: number;
    periods: SubscriptionsGrowthChartPeriodModel[];
}

export interface SubscriptionsGrowthChartPeriodModel {
    periodName: string;
    subscriptionsData: number;
}

export interface CurrentChallengeModel {
    name: string;
    activityType: ActivityType;
    endsInDays: number;
    attendees: number;
    totalEmployees: number;
    percentageFinished: number;
}

export const useEmployerData = () => {
    const { callApi } = useApi();

    const amountOfStepsGraph = async (employerId: string, timeFilter: TimeFilter) => {
        const response = await callApi.current<MiniatureGraphDataModel>(`/employerData/v1/${employerId}/steps/${timeFilter}`);

        return response;
    };

    const amountOfGymVisitsGraph = async (employerId: string, timeFilter: TimeFilter) => {
        const response = await callApi.current<MiniatureGraphDataModel>(`/employerData/v1/${employerId}/gymVisits/${timeFilter}`);

        return response;
    };

    const amountOfChallengeParticipantsGraph = async (employerId: string, timeFilter: TimeFilter) => {
        const response = await callApi.current<MiniatureGraphDataModel>(`/employerData/v1/${employerId}/challengeParticipants/${timeFilter}`);

        return response;
    };

    const amountOfEmployeesActivitiesGraph = async (employerId: string, timeFilter: TimeFilter) => {
        const response = await callApi.current<MiniatureGraphDataModel>(`/employerData/v1/${employerId}/employeeActivities/${timeFilter}`);

        return response;
    };

    const employeesUsingEntreo = async (employerId: string, timeFilter: TimeFilter) => {
        const response = await callApi.current<EmployeesUsingEntreoModel>(`/employerData/v1/${employerId}/employeesUsingEntreo/${timeFilter}`);
        return response;
    };

    const employeeSubscriptionsGrowth = async (employerId: string) => {
        const response = await callApi.current<SubscriptionsGrowthModel>(`/employerData/v1/EmployeeSubscriptionsGrowth/${employerId}`);
        return response;
    };

    const employeeSubscriptionsGrowthOverPeriod = async (employerId: string) => {
        const response = await callApi.current<SubscriptionsGrowthChartCategoryModel[]>(`/employerData/v1/${employerId}/EmployeeSubscriptionsGrowthOverPeriod`);
        return response;
    };

    const currentChallenges = async (employerId: string) => {
        const response = await callApi.current<CurrentChallengeModel[]>(`/employerData/v1/currentChallenges/${employerId}`);
        return response;
    };

    const xpOverview = async (employerId: string) => {
        const response = await callApi.current<XpOverviewModel>(`/employerData/v1/xpOverview/${employerId}`);
        return response;
    };


    return {
        amountOfStepsGraph,
        amountOfGymVisitsGraph,
        amountOfChallengeParticipantsGraph,
        amountOfEmployeesActivitiesGraph,
        employeesUsingEntreo,
        employeeSubscriptionsGrowth,
        employeeSubscriptionsGrowthOverPeriod,
        currentChallenges,
        xpOverview
    };
};