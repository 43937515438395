import { useField } from 'formik';
import React, { FC } from 'react';
import FormControl, { FormControlProps } from '..';

interface FormikFormControlProps extends FormControlProps {
    name: string;
}

const FormikFormControl: FC<FormikFormControlProps> = (props) => {
    const [field, meta, helpers] = useField(props.name);

    // Methods.
    const handleOnChange = (value: string) => {
        helpers.setValue(value);
    };

    // Render.
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    return <FormControl {...field} {...props} onChange={handleOnChange} error={error} />;
};

export default FormikFormControl;
