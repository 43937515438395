import React, { FC, PropsWithChildren, useState } from 'react';
import { Panel as Wrapper, Overlay, Content, Header, Title, Subtitle, HeaderText, IconWrapper } from './styles';
import { Transition } from 'react-transition-group';
import Button, { ButtonProps } from 'components/atoms/button/Button';
import Group from 'components/molecules/Group';
import Decorations from 'static/images/decorations-background.svg';
import Icon from 'components/atoms/Icon';
import { FormikTouched } from 'formik';
import { useTranslation } from 'react-i18next';
import Alert from 'components/molecules/Alert';
import Loading from 'components/atoms/Loading';

const DURATION = 300;

export interface PanelProps {
    inProp: boolean;
    onExited: () => void;
    onHide: () => void;
    isLoading?: boolean;
    title: string;
    subtitle?: string;
    buttons?: ButtonProps[];
    onTouched?: FormikTouched<any>;
    onCloseSide?: boolean;
    width?: number;
}

const Panel: FC<PropsWithChildren<PanelProps>> = ({ children, inProp, isLoading, onExited, onHide, title, subtitle, buttons, onTouched, onCloseSide = false, width }) => {
    const [showHideConfirm, setShowHideConfirm] = useState<boolean>(false);

    const { t } = useTranslation('common');

    const hideButtons: ButtonProps[] = [
        { title: t('common:button.return'), onClick: () => setShowHideConfirm(false), isOutline: true },
        { title: t('common:button.close'), onClick: () => onHide() }
    ];

    const handleOnHide = () => {
        if (onTouched !== undefined && Object.entries(onTouched).length !== 0) {
            setShowHideConfirm(true);
        } else {
            onHide();
        }
    };

    return (
        <Transition in={inProp} onExited={onExited} timeout={DURATION}>
            {(state) => (
                <Overlay state={state} duration={DURATION} onClick={onCloseSide ? handleOnHide : undefined}>
                    <Wrapper state={state} duration={DURATION} onClick={(e) => e.stopPropagation()} width={width}>
                        <Header>
                            <HeaderText>
                                <Title>{title}</Title>
                                <Subtitle>{subtitle}</Subtitle>
                            </HeaderText>
                            <IconWrapper onClick={handleOnHide}>
                                <Icon name="cancel" />
                            </IconWrapper>
                        </Header>
                        <Content decorations={Decorations}>
                            {isLoading ? (
                                <Loading isFull />
                            ) : (
                                !showHideConfirm ? (
                                    <>
                                        {children}
                                        {buttons && (
                                            <Group size="lg">
                                                {buttons?.map((button, index) => (
                                                    <Button key={index} {...button} rounded brand="pink" size="lg" />
                                                ))}
                                            </Group>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Alert
                                            title={t('common:form.close.title')}
                                            description={t('common:form.close.text')}
                                            type="warning"
                                        />
                                        <Group size="lg">
                                            {hideButtons?.map((button, index) => (
                                                <Button key={index} {...button} rounded brand="pink" size="lg" />
                                            ))}
                                        </Group>
                                    </>
                                )
                            )}
                        </Content>
                    </Wrapper>
                </Overlay>
            )}
        </Transition>
    );
};

export default Panel;

