import { useTheme } from '@emotion/react';
import { Action } from 'components/molecules/table/Table/components/Action/types';
import React from 'react';
import Icon from '../Icon';
import { Action as Wrapper } from './styles';

interface ActionProps<T> extends Action<T> {
    item: T;
    index: number;
}

const ActionComponent = <T extends object>({ onClick, icon, title, item, index }: ActionProps<T>) => {
    const theme = useTheme();

    const handleOnClick = () => {
        if (onClick) {
            onClick(item, index);
        }
    };

    return (
        <Wrapper onClick={handleOnClick} type="button" title={title}>
            <Icon name={icon} size={1.5} color={theme.greys[500]} />
        </Wrapper>
    );
};

export default ActionComponent;
