import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { NavItemProps } from './types';

const compareLocationToPath = (locationPath: string, itemPath: string) => locationPath === itemPath;

export const useNavHeader = (props: { item: NavItemProps }) => {
    const location = useLocation();
    const [expanded, setExpand] = useState(compareLocationToPath(location.pathname, props.item.to));
    const [isActive, setIsActive] = useState(compareLocationToPath(location.pathname, props.item.to));

    const handleExpandButtonClick = () => setExpand((expanded) => !expanded);
    const handleHeaderItemClick = () => {
        handleExpandButtonClick();
        setIsActive(true);
    };

    useEffect(() => {
        const isInList = props.item?.items?.some(({ to }) => compareLocationToPath(location.pathname, to)) ?? false;

        setIsActive(isInList);
        setExpand(isInList);
    }, [location.pathname, props.item?.items]);

    return { expanded, isActive, handleHeaderItemClick, handleExpandButtonClick };
};
