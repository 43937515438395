import React, { FC, FocusEventHandler } from 'react';
import {
    FormSelect as StyledControl,
    Option as StyledOption,
    Menu as StyledMenu,
    MenuList,
    ValueContainer as StyledValueContainer,
    LabelWrapper
} from './styles';
import Select, { ControlProps, GroupBase, MenuProps, OptionProps, ValueContainerProps } from 'react-select';
import InvalidFeedback from '../InvalidFeedback';
import Icon from 'components/atoms/Icon';
import { Icons } from 'static/theme/icons';
import { useTranslation } from 'react-i18next';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';

export interface IOption {
    value: string | number;
    label: string;
    icon?: keyof Icons;
    extraData?: any;
}

export interface FormSelectProps extends Omit<StateManagerProps<IOption, boolean, GroupBase<IOption>>, 'value'> {
    icon?: keyof Icons;
    isLight?: boolean;
    options: IOption[];
    value?: readonly IOption[] | IOption | null;
    error?: string;
    loading?: boolean;
    open?: boolean;
    onBlur?: FocusEventHandler;
    rounded?: boolean;
    applyfilterDropdownStyle?: boolean;
    label?: string;
}

const FormSelect: FC<FormSelectProps> = ({
    icon,
    isLight,
    options,
    error,
    loading,
    open,
    placeholder,
    value,
    onBlur,
    onMenuClose,
    isSearchable,
    rounded,
    isMulti,
    applyfilterDropdownStyle,
    label,
    ...props
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Select
                {...props}
                noOptionsMessage={() => t('common:noOptions')}
                isMulti={isMulti}
                onBlur={onBlur}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                placeholder={placeholder ?? t('common:select')}
                menuIsOpen={open}
                menuPortalTarget={document.body}
                isLoading={loading}
                value={value}
                closeMenuOnSelect={!isMulti}
                isLight={isLight}
                components={{
                    Control: (props) => <Control {...props} applyfilterDropdownStyle={applyfilterDropdownStyle} label={label} />,
                    ValueContainer,
                    Option,
                    DropdownIndicator: () => <Icon name="search" size={1.5} color="greyDark" />,
                    IndicatorSeparator: () => null,
                    Menu,
                    MenuList: ({ children, innerRef }) => <MenuList ref={innerRef}>{children}</MenuList>
                }}
                options={options}
            />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

const Option = ({ children, data, innerProps, innerRef, isSelected }: OptionProps<IOption, boolean>) => {
    return (
        <StyledOption ref={innerRef} {...innerProps} isSelected={isSelected}>
            {data?.icon && <Icon name={data.icon} />}
            {children}
        </StyledOption>
    );
};

const Menu = ({ children, innerProps, innerRef }: MenuProps<IOption, false>) => {
    return (
        <StyledMenu ref={innerRef} {...innerProps}>
            {children}
        </StyledMenu>
    );
};

const Control = (props: ControlProps<IOption, boolean> & { label?: string; applyfilterDropdownStyle?: boolean }) => (
    <>
        {props?.label && <Label text={props.label} />}
        <StyledControl
            ref={props.innerRef}
            {...props.innerProps}
            isLight={props.selectProps.isLight}
            applyfilterDropdownStyle={props?.applyfilterDropdownStyle}
        >
            {props.children}
        </StyledControl>
    </>
);

const ValueContainer = ({ children, innerProps, selectProps }: ValueContainerProps<IOption, false, GroupBase<IOption>>) => {
    return <StyledValueContainer {...innerProps}>{children}</StyledValueContainer>;
};
const Label = ({ text }: { text: string }) => (
    <LabelWrapper>
        <label>{text}</label>
    </LabelWrapper>
);

export default FormSelect;
