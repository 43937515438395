
import { useSuppliers } from 'hooks/suppliers';
import React, { FC } from 'react';
import { useParams } from 'react-router';
import InvitationRegisterTemplate from 'templates/invitationRegister';

interface SupplierRegisterProps {
    code?: string;
}

const SupplierRegister: FC<SupplierRegisterProps> = () => {
    const { code = '' } = useParams();
    const { invitationSupplierInfo, completeInvitationSupplier, acceptSupplier, denySupplier } = useSuppliers();

    return (
        <InvitationRegisterTemplate
            code={code}
            completeRegistrationAction={completeInvitationSupplier}
            invitationInfoAction={invitationSupplierInfo}
            acceptInvitationAction={acceptSupplier}
            denyInvitationAction={denySupplier}
        />
    );
};

export default SupplierRegister;
