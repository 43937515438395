import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TransitionStatus, ENTERING, ENTERED, EXITING, EXITED } from 'react-transition-group/Transition';

export const Overlay = styled.div<{ state: TransitionStatus; duration: number }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 103;
    transition: opacity ${({ duration }) => duration}ms ease;
    opacity: 0;

    ${({ state }) =>
        (state === ENTERING || state === ENTERED) &&
        css`
            opacity: 1;
        `}

    ${({ state }) =>
        (state === EXITING || state === EXITED) &&
        css`
            opacity: 0;
        `}
`;

export const Modal = styled.div<{ state: TransitionStatus; duration: number; size: 'small' | 'medium' | 'large' }>`
    width: 480px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.large}px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, .1);
    margin: 2.5rem 0;
    max-height: calc(100% - 5rem);
    transition: transform ${({ duration }) => duration}ms ease;
    transform: translateY(-24px);

    ${({ state }) =>
        (state === ENTERING || state === ENTERED) &&
        css`
            transform: translateY(0);
        `}

    ${({ state }) =>
        (state === EXITING || state === EXITED) &&
        css`
            transform: translateY(-24px);
        `}

	display: flex;
    flex-direction: column;

    ${({ size }) =>
        size === 'large' &&
        css`
            width: 720px;
        `}
`;

export const Content = styled.div`
    padding: ${({ theme }) => theme.card.padding.horizontal}px;
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.large}px;
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.large}px;
    overflow-y: auto;
`;

export const Header = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    border-top-left-radius: ${({ theme }) => theme.borderRadius.large}px;
    border-top-right-radius: ${({ theme }) => theme.borderRadius.large}px;
`;
