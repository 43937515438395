export enum LocationRight {
    AlterLocationInformation = 'AlterLocationInformation',
    ManageLocationRights = 'ManageLocationRights',
    ManageLocationAreas = 'ManageLocationAreas',
    ManageMemberships = 'ManageMemberships',
    ManageMembershipEntries = 'ManageMembershipEntries',
    ManageEmployees = 'ManageEmployees',
    ManageLessons = 'ManageLessons',
    AccessLocationToOrder = 'AccessLocationToOrder'
}
