import React, { FC, PropsWithChildren } from 'react';
import { Header } from './styles';
import { H1 } from 'components/atoms/text';

export interface TitleHeaderProps {
    title: string;
}

const TitleHeader: FC<PropsWithChildren<TitleHeaderProps>> = ({ title, children }) => {
    return (
        <Header>
            <H1 noMargin>{title}</H1>
            {children}
        </Header>
    );
};

export default TitleHeader;
