import React, { useCallback, useState } from 'react';
import useModal from '../modal';
import Modal from 'components/organisms/Modal';
import { useTranslation } from 'react-i18next';
import Loading from 'components/atoms/Loading';
import { toast } from 'react-toastify';
import { Form, Formik, FormikHelpers } from 'formik';
import { toFormikErrors } from 'utils/errorhelper';
import Panel from 'components/organisms/Panel';
import { useTag } from 'hooks/entreoTag';
import { useTagGroup } from 'hooks/entreoTagGroup';
import { AddTagInputModel, EditTagInputModel } from 'hooks/entreoTag/types';
import { FormGroup, FormikFormControl, FormikFormMultiSelect, FormikFormSelect } from 'components/atoms/form';
import { DropdownItemProps } from 'components/atoms/item/DropdownItem';
import { interfaceSelector } from 'utils/objectAppender';
import { icons } from 'static/theme/icons';
import { RoundButton } from 'components/atoms/button';
import Group from 'components/molecules/Group';
import { TagGroupPlainModel } from 'hooks/entreoTagGroup/types';
import { IOption } from 'components/atoms/form/FormNewSelect';

const useTagModal = (onRefresh: () => void): [(tagId?: string) => void, () => void, boolean] => {
    const { t } = useTranslation('entreoProductTag');
    const { addTag, getTag, editTag } = useTag();
    const { allTagGroups } = useTagGroup();

    // State.
    const [tag, setTag] = useState<EditTagInputModel | undefined>();
    const [tagGroups, setTagGroups] = useState<TagGroupPlainModel[]>([]);
    const [iconOptions, setIconOptions] = useState<DropdownItemProps[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const groupOptions: IOption[] = tagGroups.map((tagGroup) => { return { label: tagGroup.description, value: tagGroup.id ?? '' }; });

    const fetchTags = async (tagId: string) => {
        setIsLoading(true);
        const response = await getTag(tagId ?? '');
        if (response && response.data) {
            const tagData = response.data;
            setTag(tagData);
        }
        setIsLoading(false);
    };

    // Methods.
    const handleOnSubmit = async (data: EditTagInputModel, helpers: FormikHelpers<EditTagInputModel>) => {
        if (isSubmitting) {
            return;
        }
        if (!!tag && ('id' in data)) {
            handleEdit(data, helpers as unknown as FormikHelpers<EditTagInputModel>);
        } else {
            handleAdd(data, helpers as unknown as FormikHelpers<AddTagInputModel>);
        }
    };

    const handleAdd = async (values: AddTagInputModel, helpers: FormikHelpers<AddTagInputModel>) => {
        setIsSubmitting(true);

        const response = await addTag(values);

        if (response.ok) {
            onRefresh();
            toast.success(t('common:successAdd'));
            hide();
        } else if (response.errors) {
            const errors = toFormikErrors(response.errors);
            helpers.setErrors(errors);
        } else {
            toast.warning(t('common:general_error'));
            //Show errors in the steps?
        }
        setIsSubmitting(false);
    };

    const handleEdit = async (values: EditTagInputModel, helpers: FormikHelpers<EditTagInputModel>) => {
        setIsSubmitting(true);
        const response = await editTag(values);

        if (response.ok) {
            onRefresh();
            toast.success(t('common:successEdit'));
            hide();
        } else if (response.errors) {
            const errors = toFormikErrors(response.errors);
            helpers.setErrors(errors);
        } else {
            toast.warning(t('common:general_error'));
            //Show errors in the steps?
        }
        setIsSubmitting(false);
    };
    const getIcons = () => {
        let allIcons: DropdownItemProps[] = interfaceSelector(icons);
        allIcons = [{ value: '', title: t('modal.form.pickIcon') }, ...allIcons];
        setIconOptions(allIcons);
    };

    const getGroups = async () => {
        const response = await allTagGroups();
        if (response.ok && response.data) {
            setTagGroups(response.data);
        }
    };

    const setInitialValues = (): EditTagInputModel => {
        return tag ? {
            id: tag.id,
            text: tag.text,
            tagGroupIds: tag?.tagGroupIds,
            icon: tag.icon
        } : {
            text: '',
            tagGroupIds: [],
            icon: iconOptions[0]?.value as string ?? ''
        };
    };

    // Render.
    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => {
            if (isLoading) {
                return (
                    <Modal onHide={onHideModal} inProp={inProp} onExited={onExited} title={t('title')} size="large">
                        <Loading />
                    </Modal>
                );
            }
            const initialValues = setInitialValues();

            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            return (
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleOnSubmit}
                    isSubmitting={isSubmitting}
                    validateOnMount
                    validateOnChange
                >
                    {({ values, submitForm }) => (
                        <Panel
                            onHide={onHideModal}
                            inProp={inProp}
                            onExited={onExited}
                            title={t(`modal.form.title.${tag === undefined ? 'add' : 'edit'}`)}
                            onCloseSide
                            buttons={[{ title: t('common:button.confirm'), onClick: submitForm }]}
                        >
                            <Form>
                                <FormGroup label={t('modal.form.name')} required>
                                    <FormikFormControl name="text" />
                                </FormGroup>
                                <FormGroup label={t('modal.form.group')} required>
                                    <FormikFormMultiSelect isMulti name="tagGroupIds" options={groupOptions} />
                                </FormGroup>
                                <FormGroup label={t('modal.form.icon')} required>
                                    <Group>
                                        <RoundButton disabled size="lg" brand="pink" icon={values?.icon ?? ''} />
                                        <FormikFormSelect<string> name="icon" options={iconOptions} />
                                    </Group>
                                </FormGroup>
                            </Form>
                        </Panel>
                    )}
                </Formik>
            );
        },
        [isLoading, tag, groupOptions, iconOptions, isSubmitting]
    );

    const showModal = useCallback(
        async (tagId?: string) => {
            getGroups();
            getIcons();
            if (tagId) {
                fetchTags(tagId);
            }
            show();
        },
        [show]
    );

    const onHideModal = () => {
        setTag(undefined);
        hide();
    };

    return [showModal, hide, isShowing];
};

export default useTagModal;

