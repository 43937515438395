import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Paragraph = styled.p<{ color: string; center?: boolean; noMargin?: boolean, lines?: number }>`
    color: ${({ color }) => color};
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: ${({ noMargin }) => (!noMargin ? '1.5rem' : 0)};
    word-break: white-space;
    
    ${({ lines }) =>
        lines !== undefined &&
        css`
            -webkit-line-clamp: ${lines};
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
        `
    }

`;

