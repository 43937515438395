import React, { FC, InputHTMLAttributes } from 'react';
import InvalidFeedback from '../InvalidFeedback';

export interface FormSingleErrorProps extends InputHTMLAttributes<HTMLInputElement> {
    error?: string;
}

const FormSingleError: FC<FormSingleErrorProps> = ({ error }) => {

    return (
        <>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormSingleError;
