import { useApi } from 'hooks/api';
import { formatNumber } from 'utils/numeralFormatter';
import { appendObject } from 'utils/objectAppender';

export enum StoreItemCategory {
    None = 0,
    SportClothes = 1,
    Accessories = 2,
    Tickets = 3,
}

export interface StoreItemOrderModel {
    orderId: string,
    customer: string,
    date: Date,
    amount: number,
    entreoCost: number,
    status: StoreItemOrderStatus
    items: OrderItemModel[]
    address: string;
    houseNumber: string;
    postalCode: string;
    city: string;
}

export interface OrderItemModel {
    name: string,
    entreoCost: number,
    amount: number,
    cdnHash: string
}

export enum StoreItemOrderStatus {
    awaitingDispatch = 0,
    dispatched = 1
}

export interface UpdateStoreItemOrderStatusInputModel {
    StoreItemOrderId: string,
    EntreoStoreOrderStatus: StoreItemOrderStatus
}

export interface StoreItemPlainModel {
    id: string,
    name: string,
    description: string,
    entreoCost: number,
    storeItemCategory: StoreItemCategory,
    stock?: number,
    cdnHash: string
}

export interface AddStoreItemInputModel {
    name: string,
    description: string,
    entreoCost: number,
    storeItemCategory: StoreItemCategory,
    employerId?: string,
    stock?: number,
    image?: File
}

export interface EditStoreItemInputModel {
    id: string
    name: string,
    description: string,
    entreoCost: number,
    image?: File,
    storeItemCategory: StoreItemCategory,
    employerId?: string,
    stock?: number,
    cdnHash: string
}

export interface StoreItemModel {
    id: string,
    name: string,
    description: string,
    entreoCost: number,
    storeItemCategory: StoreItemCategory,
    stock?: number,
    cdnHash: string
}

export const useStore = () => {
    const { callApi } = useApi();

    const allStoreItems = async () => {
        const response = await callApi.current<StoreItemPlainModel[]>(
            '/storeitem/v1'
        );
        return response;
    };

    const allEmployerStoreItems = async (employerId: string) => {
        const response = await callApi.current<StoreItemPlainModel[]>(
            `/storeitem/v1/employer/${employerId}`
        );
        return response;
    };

    const getStoreItem = async (storeItemId: string) => {
        const response = await callApi.current<StoreItemModel>(
            `/storeitem/v1/${storeItemId}`
        );
        return response;
    };

    const addStoreItem = async (newStoreItem: AddStoreItemInputModel) => {
        let formData = new FormData();

        formData = appendObject(newStoreItem);
        if (newStoreItem.image !== undefined) {
            formData.append('image', newStoreItem.image);
        }
        formData.append('entreoCost', formatNumber(newStoreItem.entreoCost));

        const response = await callApi.current(
            '/storeitem/v1',
            {
                method: 'POST',
                body: formData
            }
        );
        return response;
    };

    const editStoreItem = async (changedStoreItem: EditStoreItemInputModel) => {
        let formData = new FormData();
        formData = appendObject(changedStoreItem);
        if (changedStoreItem.image === undefined) {
            formData.append('imageCdnHash', changedStoreItem.cdnHash);
        } else {
            formData.append('image', changedStoreItem.image);
        }

        const response = await callApi.current(
            '/storeitem/v1',
            {
                method: 'PUT',
                body: formData
            }
        );
        return response;
    };

    const removeStoreItem = async (storeItemId: string) => {
        const response = await callApi.current(
            `/storeitem/v1/${storeItemId}`,
            {
                method: 'DELETE'
            }
        );
        return response;
    };

    const allStoreItemOrders = async () => {
        const response = await callApi.current<StoreItemOrderModel[]>(
            '/StoreItemOrder/v1'
        );
        return response;
    };

    const updateOrderStatus = async (inputModel: UpdateStoreItemOrderStatusInputModel) => {

        const response = await callApi.current(
            '/StoreItemOrder/v1/StatusUpdate',
            {
                method: 'PUT',
                body: JSON.stringify(inputModel)
            }
        );
        return response;
    };


    return {
        allStoreItemOrders,
        allStoreItems,
        allEmployerStoreItems,
        getStoreItem,
        addStoreItem,
        editStoreItem,
        updateOrderStatus,
        removeStoreItem
    };
};