import { ChallengeThemeType } from 'hooks/challengeTemplate';
import React, { FC } from 'react';
import { ImageWrapper, Title, Image } from '../PictureBankTheme/styles';

export interface ChallengeTemplateThemeProps {
    id: string;
    image: string;
    theme: ChallengeThemeType;
    onClick: (theme?: ChallengeThemeType) => void;
    isActive?: boolean;
    title: string;
}

const ChallengeTemplateTheme: FC<ChallengeTemplateThemeProps> = ({ id, image, theme, isActive, onClick, title }) => {
    // Render.
    return (
        <ImageWrapper isActive={isActive}>
            <Image src={image} isActive={isActive} onClick={() => onClick && onClick(theme)} />
            <Title>{title}</Title>
        </ImageWrapper>
    );
};

export default ChallengeTemplateTheme;

