import { Role } from 'hooks/roles';
import { UserPermissions } from 'permissions';
import React, { useMemo, useState } from 'react';

export type UserAuthorization = {
    permissions: UserPermissions[];
    roles: Role[];
    userId: string;
};

const initialUserAuthorization: UserAuthorization = {
    permissions: [],
    roles: [],
    userId: ''
};

const UserAuthorizationContext = React.createContext<{
    userAuthorization: UserAuthorization;
    hasPermission: (permission: UserPermissions) => boolean;
    hasPermissions: (permissions: UserPermissions[]) => boolean[];
    setUserAuthorization: React.Dispatch<React.SetStateAction<UserAuthorization>>;
}>({
    userAuthorization: initialUserAuthorization,
    hasPermission: () => false,
    hasPermissions: () => [false],
    setUserAuthorization: () => null
});

export const useUserAuthorizationContext = () => {
    const context = React.useContext(UserAuthorizationContext);

    if (!context) {
        throw new Error('useUserAuthorizationContext must be used within a UserAuthorizationProvider');
    }

    return context;
};

export const UserAuthorizationProvider = ({ children }: { children: React.ReactNode }) => {
    const [userAuthorization, setUserAuthorization] = useState(initialUserAuthorization);

    const hasPermission = React.useCallback(
        (permission: UserPermissions) => userAuthorization.permissions.includes(permission),
        [userAuthorization.permissions]
    );

    const hasPermissions = React.useCallback(
        (permissions: UserPermissions[]) => {
            if (!permissions.length) return [];

            const foundPermissions = permissions.filter((permissionName: UserPermissions) =>
                userAuthorization.permissions.includes(permissionName)
            );

            return permissions.map((permissionName: UserPermissions) => foundPermissions.includes(permissionName));
        },
        [userAuthorization.permissions]
    );

    const context = useMemo(
        () => ({
            userAuthorization,
            hasPermission,
            hasPermissions,
            setUserAuthorization
        }),
        [userAuthorization, hasPermission, hasPermissions, setUserAuthorization]
    );

    return <UserAuthorizationContext.Provider value={context}>{children}</UserAuthorizationContext.Provider>;
};
