import React, { useCallback, useState } from 'react';
import useModal from '../modal';
import Modal from 'components/organisms/Modal';
import { useTranslation } from 'react-i18next';
import Loading from 'components/atoms/Loading';
import { MedalModel } from 'hooks/medal';
import { toast } from 'react-toastify';
import { Formik, FormikHelpers } from 'formik';
import { toFormikErrors } from 'utils/errorhelper';
import { MeasurableType } from 'hooks/challenge';
import {
    ActivityType,
    AddChallengeTemplateInputModel,
    ChallengeCompletionPeriodType,
    ChallengeMeasurementType,
    ChallengeMeasurementUnitType,
    ChallengeRatingType,
    ChallengeThemeType,
    EditChallengeTemplateInputModel,
    ChallengeTemplateModel,
    ChallengeTemplateStatusType,
    useChallengeTemplate,
    ChallengeThirdPartyType
} from 'hooks/challengeTemplate';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step4 from './components/Step4';
import Step3 from './components/Step3';
import Panel from 'components/organisms/Panel';
import RoundWizard from 'components/organisms/RoundWizard';
import Alert from 'components/molecules/Alert';
import * as yup from 'yup';

export interface ActivityOptions {
    id: number;
    activityName: string;
    measurableType: MeasurableType;
    activityType?: ActivityType;
    measurableTypeOptions?: MeasurableType[];
}

export interface ChallengeTemplateValues {
    banner?: File;
    challengeBannerHash?: string;
    name: string;
    description: string;
    uniqueFeatureDescription?: string;
    activityDescription: string;
    entreoPointsReward: number;
    hasDate: boolean;
    startDate?: Date;
    endDate?: Date;
    hasFromDate: boolean;
    fromDate?: Date;
    fromEndDate?: Date;
    theme: ChallengeThemeType;
    completionAmount: number;
    completionPeriod: ChallengeCompletionPeriodType;
    frequencyAmount: number;
    frequencyPeriodAmount: number;
    frequencyPeriod: ChallengeCompletionPeriodType;
    measurementAmount: number;
    measurement: ChallengeMeasurementType;
    measurementUnit: ChallengeMeasurementUnitType;
    activity: ActivityType;
    difficulty: ChallengeRatingType;
    medal?: MedalModel;
    separateDays: boolean;
    //pictureBankItemModel?: PictureBankInputModel;
    pictureBankItemId?: string;
    status: ChallengeTemplateStatusType;
    publishDate?: Date;
    thirdParty?: ChallengeThirdPartyType;
    sendNotifications: boolean;
}

export interface EnvironmentProps {
    employerId?: string;
    locationId?: string;
    associationId?: string;
    entreo?: boolean;
}

const useChallengeTemplateModal = (
    environmentProps: EnvironmentProps, onRefresh: () => void
): [(challengeId?: string) => void, () => void, boolean] => {
    const { t } = useTranslation('challengeTemplate');
    const { addChallengeTemplate, getChallengeTemplate, editChallengeTemplate } = useChallengeTemplate();

    // State.
    const [isThemePicking, setIsThemePicking] = useState(true);
    const [isImagePicking, setIsImagePicking] = useState(false);
    const [pictureBankImageId, setPictureBankImageId] = useState<string | undefined>();
    const [pictureBankThemeId, setPictureBankThemeId] = useState<string | undefined>();
    const [challenge, setChallenge] = useState<ChallengeTemplateModel | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0);

    const fetchChallengeTemplates = async (challengeId: string) => {
        setIsLoading(true);
        const response = await getChallengeTemplate(challengeId ?? '');
        if (response && response.data) {
            const challengeData = response.data;
            setChallenge(challengeData);
        }
        setIsLoading(false);
    };

    // Methods.
    const handleOnSubmit = async (values: ChallengeTemplateValues, helpers: FormikHelpers<ChallengeTemplateValues>) => {
        if (isSubmitting) return;
        if (!!challenge) {
            handleEdit(values, helpers);
        } else {
            handleAdd(values, helpers);
        }
    };

    const handleAdd = async (values: ChallengeTemplateValues, helpers: FormikHelpers<ChallengeTemplateValues>) => {
        setIsSubmitting(true);
        const challengeInputModel: AddChallengeTemplateInputModel = {
            name: values.name,
            description: values.description,
            startDate: values.hasDate === true ? values.startDate : undefined,
            endDate: values.hasDate === true ? values.endDate : undefined,
            fromDate: values.hasFromDate === true ? values.fromDate : undefined,
            fromEndDate: values.hasFromDate === true ? values.fromEndDate : undefined,
            entreoPointsReward: values.entreoPointsReward,
            employerId: environmentProps.employerId,
            locationId: environmentProps.locationId,
            associationId: environmentProps.associationId,
            challengeBanner: values.banner,
            uniqueFeatureDescription: values.uniqueFeatureDescription ?? '',
            theme: values.theme,
            completionPeriod: values.completionPeriod,
            completionAmount: values.completionAmount,
            frequencyAmount: values.frequencyAmount,
            frequencyPeriod: values.frequencyPeriod,
            frequencyPeriodAmount: values.frequencyPeriodAmount,
            measurementAmount: values.measurementAmount,
            measurement: values.measurement,
            separateDays: values.separateDays,
            measurementUnit: values.measurementUnit,
            activity: values.activity,
            difficulty: values.difficulty,
            activityDescription: values.activityDescription ?? '',
            medalId: values.medal?.id ?? undefined,
            pictureBankItemId: values.pictureBankItemId,
            status: values.status,
            sendNotifications: values.sendNotifications,
            thirdParty: values.thirdParty
        };

        const response = await addChallengeTemplate(challengeInputModel);

        if (response.ok) {
            onRefresh();
            setIsSuccess(true);
        } else if (response.errors) {
            const errors = toFormikErrors(response.errors);
            helpers.setErrors(errors);
            toast.error(`error:${response.errors[0].key}`);
        } else {
            toast.warning(t('common:general_error'));
            //Show errors in the steps?
        }
        setIsSubmitting(false);
    };

    const handleEdit = async (values: ChallengeTemplateValues, helpers: FormikHelpers<ChallengeTemplateValues>) => {
        setIsSubmitting(true);
        const challengeInputModel: EditChallengeTemplateInputModel = {
            id: challenge?.id ?? '',
            name: values.name,
            description: values.description,
            startDate: values.hasDate ? values.startDate : undefined,
            endDate: values.hasDate ? values.endDate : undefined,
            fromDate: values.hasFromDate ? values.fromDate : undefined,
            fromEndDate: values.hasFromDate ? values.fromEndDate : undefined,
            entreoPointsReward: values.entreoPointsReward,
            challengeBanner: values.banner,
            uniqueFeatureDescription: values.uniqueFeatureDescription ?? '',
            theme: values.theme,
            completionAmount: values.completionAmount,
            completionPeriod: values.completionPeriod,
            frequencyAmount: values.frequencyAmount,
            frequencyPeriod: values.frequencyPeriod,
            frequencyPeriodAmount: values.frequencyPeriodAmount,
            measurementAmount: values.measurementAmount,
            separateDays: values.separateDays,
            measurement: values.measurement,
            measurementUnit: values.measurementUnit,
            activity: values.activity,
            difficulty: values.difficulty,
            activityDescription: values.activityDescription ?? '',
            pictureBankItemId: values.pictureBankItemId,
            status: values.status,
            sendNotifications: values.sendNotifications,
            thirdParty: values.thirdParty
        };
        const response = await editChallengeTemplate(challengeInputModel);

        if (response.ok) {
            onRefresh();
            setIsSuccess(true);
        } else if (response.errors) {
            const errors = toFormikErrors(response.errors);
            helpers.setErrors(errors);
            toast.error(response.errors[0].message);
        } else {
            toast.warning(t('common:general_error'));
            //Show errors in the steps?
        }
        setIsSubmitting(false);
    };

    const setInitialValues = () => {
        return {
            name: challenge?.name ?? '',
            description: challenge?.description ?? '',
            challengeBannerHash: challenge?.challengeBannerHash,
            entreoPointsReward: challenge?.entreoPointsReward ?? 0,
            hasDate: challenge?.startDate !== undefined && challenge?.startDate !== null,
            startDate: challenge?.startDate !== undefined && challenge?.startDate !== null ? new Date(challenge.startDate + 'Z') : undefined,
            endDate: challenge?.endDate !== undefined && challenge?.startDate !== null ? new Date(challenge.endDate + 'Z') : undefined,
            hasFromDate: challenge?.fromDate !== undefined && challenge?.fromDate !== null,
            fromDate: challenge?.fromDate !== undefined && challenge?.fromDate !== null ? new Date(challenge.fromDate + 'Z') : undefined,
            fromEndDate: challenge?.fromEndDate !== undefined && challenge?.fromEndDate !== null ? new Date(challenge.fromEndDate + 'Z') : undefined,
            uniqueFeatureDescription: challenge?.uniqueFeatureDescription ?? '',
            activityDescription: challenge?.activityDescription ?? '',
            theme: challenge?.theme ? (challenge.theme as ChallengeThemeType) : ChallengeThemeType.Movement,
            completionAmount: challenge?.completionAmount ?? 1,
            completionPeriod: challenge?.completionPeriod
                ? (challenge.completionPeriod as ChallengeCompletionPeriodType)
                : ChallengeCompletionPeriodType.PerDay,
            frequencyAmount: challenge?.frequencyAmount ?? 1,
            frequencyPeriod: challenge?.frequencyPeriod
                ? (challenge.frequencyPeriod as ChallengeCompletionPeriodType)
                : ChallengeCompletionPeriodType.PerDay,
            frequencyPeriodAmount: challenge?.frequencyPeriodAmount ?? 1,
            measurementAmount: challenge?.measurementAmount ?? 1,
            measurement: challenge?.measurement ? (challenge.measurement as ChallengeMeasurementType) : ChallengeMeasurementType.Amount,
            measurementUnit: challenge?.measurementUnit
                ? (challenge.measurementUnit as ChallengeMeasurementUnitType)
                : ChallengeMeasurementUnitType.Steps,
            activity: challenge?.activity ? (challenge.activity as ActivityType) : ActivityType.Walk,
            difficulty: challenge?.difficulty ? (challenge.difficulty as ChallengeRatingType) : ChallengeRatingType.Beginner,
            medal: undefined,
            separateDays: challenge?.separateDays ?? true,
            pictureBankItemId: challenge?.pictureBankItemId ?? undefined,
            publishDate: challenge?.publishDate ?? undefined,
            status: challenge?.status ?? ChallengeTemplateStatusType.Published,
            thirdParty: challenge?.thirdParty ?? undefined,
            sendNotifications: challenge?.sendNotifications ?? false
        };
    };

    // Render.
    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => {
            if (isLoading) {
                return (
                    <Modal onHide={onHideModal} inProp={inProp} onExited={onExited} title={t('title')} size="large">
                        <Loading />
                    </Modal>
                );
            }
            const initialValues = setInitialValues();

            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            const initialStep = challenge === undefined ? 0 : 3;
            return (
                !isSuccess ? (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleOnSubmit}
                        isSubmitting={isSubmitting}
                        validateOnMount
                        validateOnChange
                    >
                        {() => (
                            <Panel
                                onHide={onHideModal}
                                inProp={inProp}
                                onExited={onExited}
                                title={t(`titleModal.${challenge === undefined ? 'add' : 'edit'}`)}
                                subtitle={t(`step.${step}`).toString()}
                                onCloseSide={false}
                            >
                                <RoundWizard<ChallengeTemplateValues>
                                    initialValues={initialValues}
                                    initialStep={initialStep}
                                    onSubmit={handleOnSubmit}
                                    handleOnNextStep={setStep}
                                    steps={[
                                        {
                                            title: t('step1.add.title'),
                                            child: <Step1 mode={challenge === undefined ? 'add' : 'edit'} />,
                                            step: 1
                                        },
                                        {
                                            title: t('step2.title'),
                                            child: <Step2 mode={challenge === undefined ? 'add' : 'edit'} />,
                                            step: 2
                                        },
                                        {
                                            title: t('step3.title'),
                                            child: <Step3 mode={challenge === undefined ? 'add' : 'edit'} />,
                                            step: 3,
                                            validationSchema: yup.object().shape({
                                                name: yup
                                                    .string()
                                                    .required(t('step3.isRequired'))
                                                    .min(6, t('step3.length', { min: '6', max: '64' }).toString())
                                                    .max(64, t('step3.length', { min: '6', max: '64' }).toString()),
                                                description: yup
                                                    .string()
                                                    .required(t('step3.isRequired'))
                                                    .min(1, t('step3.length', { min: '1', max: '1024' }).toString())
                                                    .max(1024, t('step3.length', { min: '1', max: '1024' }).toString()),
                                                uniqueFeatureDescription: yup
                                                    .string()
                                                    .notRequired()
                                                    .max(64, t('step3.length', { min: '1', max: '64' }).toString()),
                                                activityDescription: yup
                                                    .string()
                                                    .min(1, t('step3.length', { min: '1', max: '64' }).toString())
                                                    .max(64, t('step3.length', { min: '1', max: '64' }).toString()),
                                                completionAmount: yup
                                                    .string()
                                                    .required(t('step3.isRequired'))
                                                    .matches(/^[0-9]+$/, {
                                                        message: t('step3.number'),
                                                        excludeEmptyString: true
                                                    })
                                                    .test('completionAmount', t('step3.number'), (value?: string) => !isNaN((Number(value))))
                                                    .test('completionAmount', t('step3.positive'), (value?: string) => Number(value) > 0),
                                                frequencyAmount: yup
                                                    .string()
                                                    .required(t('step3.isRequired'))
                                                    .matches(/^[0-9]+$/, {
                                                        message: t('step3.number'),
                                                        excludeEmptyString: true
                                                    })
                                                    .test('frequencyAmount', t('step3.number'), (value?: string) => !isNaN((Number(value))))
                                                    .test('frequencyAmount', t('step3.positive'), (value?: string) => Number(value) > 0),
                                                entreoPointsReward: yup
                                                    .string()
                                                    .required(t('step3.isRequired'))
                                                    .matches(/^[0-9]+$/, {
                                                        message: t('step3.number'),
                                                        excludeEmptyString: true
                                                    })
                                                    .test('number', t('step3.number'), (value?: string) => !isNaN((Number(value)))),
                                                //     .test('positive', t('step3.positive'), (value?: string) => Number(value) >= 0),
                                                sendNotifictions: yup.bool(),
                                                measurementAmount: yup
                                                    .string()
                                                    .required(t('step3.isRequired'))
                                                    .matches(/^[0-9]+$/, {
                                                        message: t('step3.number'),
                                                        excludeEmptyString: true
                                                    })
                                                    .test('measurementAmount', t('step3.number'), (value?: string) => !isNaN((Number(value))))
                                                    .test('measurementAmount', t('step3.positive'), (value?: string) => Number(value) >= 0),
                                                frequencyPeriodAmount: yup
                                                    .string()
                                                    .required(t('step3.isRequired'))
                                                    .matches(/^[0-9]+$/, {
                                                        message: t('step3.number'),
                                                        excludeEmptyString: true
                                                    })
                                                    .test('frequencyPeriodAmount', t('step3.number'), (value?: string) => !isNaN((Number(value))))
                                                    .test('frequencyPeriodAmount', t('step3.positive'), (value?: string) => Number(value) >= 0),
                                                startDate: yup.date().when('hasDate', {
                                                    is: true,
                                                    then: yup.date().required(t('step3.isRequired'))
                                                }),
                                                endDate: yup.date().when('hasDate', {
                                                    is: true,
                                                    then: yup.date().min(yup.ref('startDate'), (t('step3.minDate')))
                                                }),
                                                fromDate: yup.date().when('hasFromDate', {
                                                    is: true,
                                                    then: yup.date().required(t('step3.isRequired'))
                                                }),
                                                fromEndDate: yup.date().when('hasFromDate', {
                                                    is: true,
                                                    then: yup.date().min(yup.ref('fromDate'), (t('step3.minDate')))
                                                }),
                                            }),
                                        },
                                        {
                                            title: t('step4.title'),
                                            child: <Step4 mode={challenge === undefined ? 'add' : 'edit'} />,
                                            step: 4
                                        }
                                    ]}
                                />
                            </Panel>
                        )}
                    </Formik>
                ) : (
                    <Panel
                        onCloseSide
                        onHide={onHideModal}
                        inProp={inProp}
                        onExited={onExited}
                        title={t(`titleModal.${challenge === undefined ? 'add' : 'edit'}`)}
                        buttons={[{ title: t('common:button.confirm'), onClick: () => onHideModal() }]}
                    >
                        <Alert
                            title={t('message.success.title')}
                            description={challenge === undefined ? t('message.success.text') : t('common:message.save')}
                        />
                    </Panel>
                )
            );
        },
        [isLoading, challenge, isImagePicking, isThemePicking, pictureBankImageId, pictureBankThemeId, isSubmitting, isSuccess, step]
    );

    const showModal = useCallback(
        async (challengeId?: string) => {
            if (challengeId) {
                fetchChallengeTemplates(challengeId);
            }
            show();
        },
        [show]
    );

    const onHideModal = () => {
        setChallenge(undefined);
        setChallenge(undefined);
        setIsThemePicking(true);
        setIsImagePicking(false);
        setPictureBankImageId(undefined);
        setPictureBankThemeId(undefined);
        setIsSuccess(false);
        hide();
    };

    return [showModal, hide, isShowing];
};

export default useChallengeTemplateModal;

