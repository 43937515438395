import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FaceProps } from '.';

export const Wrapper = styled.div<FaceProps>`
    width: 2.5rem;
    height: 2.5rem;
    flex: 0 0 2.5rem;
    border-radius: ${({ round, theme }) => (round ? '50%' : `${theme.borderRadius.small}px`)};
    background-color: ${({ theme }) => theme.greys[50]};
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ image }) => css`
        background-image: url(${image});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    `}

    img {
        width: 100%;
        height: auto;
        border-radius: 50%;
    }

    ${({ small }) =>
        small &&
        css`
            width: 2rem;
            height: 2rem;
            flex: 0 0 2rem;
        `}

    ${({ big }) =>
        big &&
        css`
            width: 3.4rem;
            height: 3.4rem;
            flex: 0 0 3.4rem;
        `}

    ${({ borderColor }) =>
        borderColor &&
        css`
            border-color: ${borderColor};
        `}
            
    ${({ borderWidth }) =>
        borderWidth &&
        css`
            border-width: ${borderWidth}px;
            border-style: solid;
        `}
`;

export const Letter = styled.span`
    color: ${({ theme }) => theme.greys[300]}; ;
`;
