import greys, { Greys } from './greys';
import header, { Header } from './header';
import colors, { Colors } from './colors';
import brand, { Brand } from './brand';
import form, { Form } from './form';
import card, { Card } from './card';
import fontSizes, { FontSizes } from './fontSizes';
import navigation, { Navigation } from './navigation';
import borderRadius, { BorderRadius } from './borderRadius';

export interface Size {
    sm: number;
    md: number;
    lg: number;
}

export interface Theme {
    brand: Brand;
    colors: Colors;
    borderRadius: BorderRadius;
    navigation: Navigation;
    header: Header;
    greys: Greys;
    fontSizes: FontSizes;
    card: Card;
    form: Form;
    // TODO: Remove this shit below.
    constants: {
        borderRadius: number;
        bar: number;
        spacer: number;
        panelPadding: number;
        panelBodyPaddingHorizontal: number;
        panelBodyPaddingVertical: string;
        panelHeaderPaddingHorizontal: number;
        panelHeaderPaddingVertical: string;
        menuWidth: number;
        topBarHeight: number;
        topBarContentHeight: number;
        footerHeight: number;
        iframeHeight: number;
        shadow: string;
        containerPaddingHorizontal: number;
        containerPaddingTop: number;
    };
    formElements: {
        label: {
            fontSize: number;
            fontWeight: number;
            color: string;
        };
        backgroundColor: string;
        border: string;
        shadow: string;
        borderRadius: number;
        padding: number;
        paddingSelect: number;
        paddingHorizontal: number;
        color: string;
    };
    breakpoints: number[];
}

const theme: Theme = {
    card,
    greys,
    brand,
    header,
    colors,
    fontSizes,
    navigation,
    borderRadius,
    form,
    // TODO: Remove this shit below.
    constants: {
        borderRadius: 14,
        bar: 85,
        spacer: 24,
        panelPadding: 30,
        panelBodyPaddingHorizontal: 32,
        panelBodyPaddingVertical: '2rem',
        panelHeaderPaddingHorizontal: 32,
        panelHeaderPaddingVertical: '1.25rem',
        menuWidth: 340,
        topBarHeight: 88,
        topBarContentHeight: 40,
        footerHeight: 70,
        iframeHeight: 500,
        shadow: `3px 3px 14px ${colors.darkCyan}`,
        containerPaddingHorizontal: 56,
        containerPaddingTop: 40
    },
    formElements: {
        label: {
            fontSize: 13,
            fontWeight: 600,
            color: colors.darkBlue
        },
        backgroundColor: colors.cyan,
        border: `1px solid ${colors.darkCyan}`,
        shadow: `3px 3px 14px ${colors.darkCyan}`,
        borderRadius: 10,
        padding: 14,
        paddingSelect: 4,
        paddingHorizontal: 14,
        color: colors.black
    },
    breakpoints: [400, 600, 900, 1300, 1550]
};

export default theme;
