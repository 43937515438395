import React from 'react';
import InvalidFeedback from '../InvalidFeedback';
import { Option } from 'components/atoms/form/FormSelect';
import { FormControl, FormSelect } from 'components/atoms/form';
import { ControlSelect } from './styles';

export interface FormControlSelectProps<T extends number | string> {
    valueControl?: string;
    valueSelect?: T;
    onChangeControl?: (value: string) => void;
    onBlur?: (e: any) => void;
    onChangeSelect?: (value: T) => void;
    options: Option<T>[];
    error?: string;
    placeholder: string;
}

const FormControlSelect = <T extends number | string>({
    error,
    options,
    valueControl,
    valueSelect,
    onChangeControl,
    onChangeSelect,
    placeholder,
    onBlur
}: FormControlSelectProps<T>) => {
    return (
        <>
            <ControlSelect>
                <FormControl style={{ paddingRight: '7rem' }} value={valueControl} onChange={onChangeControl} placeholder={placeholder} onBlur={onBlur} />
                <FormSelect value={valueSelect} options={options} onChange={onChangeSelect} inline />
            </ControlSelect>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormControlSelect;
