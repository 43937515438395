import React, { FC, useEffect } from 'react';
import { FormGroup, FormikFormControl, FormikFormDate, FormikFormSelect, FormRadio } from 'components/atoms/form';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { ChallengeValues } from '..';
import { H3 } from 'components/atoms/text';
import { ChallengeRatingType, ChallengeTemplatePlainModel, ChallengeTemplateStatusType, ChallengeThirdPartyType } from 'hooks/challengeTemplate';
import { getDaysOfChallenge } from 'utils/challengeHelper';
import { differenceInDays } from 'date-fns';
import Margin from 'components/atoms/Margin';
import EditRow from 'components/atoms/EditRow';

interface AddChallengeFormProps {
    template?: ChallengeTemplatePlainModel;
}

const AddChallengeForm: FC<AddChallengeFormProps> = ({ template }) => {
    const { t } = useTranslation('challengeModal');
    const { values, setFieldValue } = useFormikContext<ChallengeValues>();

    useEffect(() => {
        setFieldValue('challengeTemplatePlainModel', template);
        setFieldValue('challengeTemplateId', template?.id);
        setFieldValue('name', template?.name);
    }, []);

    const periodOptions = [{ title: t('visiblePeriod.limitedVisible'), value: true }, { title: t('visiblePeriod.alwaysVisible'), value: false }];

    const validateFromDate = (date: Date, name: string) => {
        if (values.hasFromDate && values.fromDate !== undefined && values.fromEndDate !== undefined) {
            const differenceDays = differenceInDays(values.fromEndDate, date);
            return generateValidateDateError(differenceDays);
        }
    };

    const validateFromEndDate = (date: Date, name: string) => {
        if (values.hasFromDate && values.fromDate !== undefined && values.fromEndDate !== undefined) {
            const differenceDays = differenceInDays(date, values.fromDate);
            return generateValidateDateError(differenceDays);
        }
    };

    // check if maybe can be cleaned up with using setFormikState instead
    const validateStartDate = (date: Date, name: string) => {
        if (values.hasDate && values.startDate !== undefined && values.endDate !== undefined) {
            const differenceDays = differenceInDays(values.endDate, date);
            return generateValidateDateError(differenceDays);
        }
    };

    const validateEndDate = (date: Date, name: string) => {
        if (values.hasDate && values.startDate !== undefined && values.endDate !== undefined) {
            const differenceDays = differenceInDays(date, values.startDate);
            return generateValidateDateError(differenceDays);
        }
    };

    const generateValidateDateError = (differenceDays: number) => {
        if (values.challengeTemplatePlainModel !== undefined) {
            const minimumDurationInDays = getDaysOfChallenge(values.challengeTemplatePlainModel?.completionPeriod, values.challengeTemplatePlainModel?.completionAmount);
            if (differenceDays < minimumDurationInDays) {
                return (t('step2.validateDate', { minimumDurationInDays: minimumDurationInDays }));
            }
        }
    };

    const updateActiveStatus = (isActive: boolean, status: ChallengeTemplateStatusType) => {
        setFieldValue('isActive', isActive);
        setFieldValue('status', status);
    };

    return (
        <>
            <>
                <Margin bottom={2}>
                    <EditRow title={t('step2.status.title')}>
                        <FormRadio
                            checked={values.isActive === true}
                            onChange={() => updateActiveStatus(true, ChallengeTemplateStatusType.Published)}
                            label={t('step2.status.published')}
                        />
                        <FormRadio
                            checked={values.isActive === false}
                            onChange={() => updateActiveStatus(false, ChallengeTemplateStatusType.Disabled)}
                            label={t('step2.status.disabled')}
                        />
                    </EditRow>
                </Margin>
            </>
            <FormGroup horizontal isLarge isLight label={t('step2.name')}>
                <FormikFormControl name="name" />
            </FormGroup>
            <FormGroup horizontal isLarge isLight label={t('step2.activityDescription')}>
                <H3 wrap>{values.challengeTemplatePlainModel?.activityDescription}</H3>
            </FormGroup>
            <FormGroup horizontal isLarge isLight label={t('step2.description')}>
                <H3 wrap>{values.challengeTemplatePlainModel?.description}</H3>
            </FormGroup>
            <FormGroup horizontal isLarge isLight label={t('step2.thirdPartyRequired')}>
                <H3>{values.challengeTemplatePlainModel?.thirdParty !== undefined
                    ? t('step2.uniqueType.' + ChallengeThirdPartyType[values.challengeTemplatePlainModel?.thirdParty]).toString()
                    : t('common:none')}</H3>
            </FormGroup>
            <FormGroup horizontal isLarge isLight label={t('step2.activePeriod')} tooltip={t('step2.hasDateTooltip')}>
                <FormikFormSelect<string>
                    name="hasDate"
                    options={periodOptions}
                />
            </FormGroup>
            {values.hasDate && (
                <>
                    <FormGroup horizontal isLarge isLight label={t('step2.startDate')}>
                        <FormikFormDate name="startDate" onValidate={validateStartDate} />
                    </FormGroup>
                    <FormGroup horizontal isLarge isLight label={t('step2.endDate')}>
                        <FormikFormDate name="endDate" onValidate={validateEndDate} />
                    </FormGroup>
                </>
            )}
            <FormGroup horizontal isLarge isLight label={t('step2.visiblePeriod')} tooltip={t('step2.fromDateTooltip')}>
                <FormikFormSelect<string>
                    name="hasFromDate"
                    options={periodOptions}
                />
            </FormGroup>
            {values.hasFromDate && (
                <>
                    <FormGroup horizontal isLarge isLight label={t('step2.startDate')}>
                        <FormikFormDate name="fromDate" onValidate={validateFromDate} />
                    </FormGroup>
                    <FormGroup horizontal isLarge isLight label={t('step2.endDate')}>
                        <FormikFormDate name="fromEndDate" onValidate={validateFromEndDate} />
                    </FormGroup>
                </>
            )}
            <FormGroup horizontal isLarge isLight label={t('step2.reward')}>
                <FormikFormControl name="entreoPointsReward" />
            </FormGroup>
            {/* <FormGroup horizontal isLarge isLight label={t('step2.benefitDescription')}>
                <H3>{values.challengeTemplatePlainModel?.benefitDescription}</H3>
            </FormGroup> */}
            <FormGroup horizontal isLarge isLight label={t('step2.difficulty')}>
                <H3>
                    {values.challengeTemplatePlainModel !== undefined
                        && t('step1.difficultyType.' + ChallengeRatingType[values.challengeTemplatePlainModel?.difficulty])}
                </H3>
            </FormGroup>
            {/* <FormGroup horizontal isLarge isLight label={t('step2.sendNotifications')}>
                <FormikFormSwitch
                    name="sendNotifications"
                />
            </FormGroup> */}
        </>
    );
};

export default AddChallengeForm;
