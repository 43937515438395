import React from 'react';
import { useField } from 'formik';
import FormButtonSelect, { FormButtonSelectProps } from '..';

interface FormikFormButtonSelectProps<T extends string | number> extends FormButtonSelectProps<T> {
    name: string;
    afterChange?: (value: T) => void;
}

const FormikFormButtonSelect = <T extends string | number>({ name, afterChange, ...props }: FormikFormButtonSelectProps<T>) => {
    const [field, , helpers] = useField<T>(name);

    const handleOnChange = (value: T) => {
        helpers.setValue(value);
        afterChange && afterChange(value);
    };

    // Render.
    return <FormButtonSelect {...props} value={field.value} onChange={handleOnChange} />;
};

export default FormikFormButtonSelect;
