import RoundStep, { RoundStepProps } from 'components/atoms/RoundStep';
import React, { FC } from 'react';
import { Visible } from 'react-grid-system';
import { Stepper as Wrapper } from './styles';

export interface RoundStepperProps {
    steps: RoundStepProps[];
}

const RoundStepper: FC<RoundStepperProps> = ({ steps }) => {
    return (
        <Wrapper>
            {steps.map((step) => (
                <Visible key={step.step} xs={step.active} sm={step.active} md lg xl xxl>
                    <RoundStep key={step.step} {...step} />
                </Visible>
            ))}
        </Wrapper>
    );
};

export default RoundStepper;

