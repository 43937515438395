import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Icon from 'components/atoms/Icon';

export const H5 = styled.h5<{ color: string; center?: boolean; underline?: boolean }>`
    color: ${({ color }) => color};
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-size: 0.75rem;
    line-height: 0.75rem;
    font-weight: 500;
    margin-bottom: 0;

    ${({ underline }) =>
        underline &&
        css`
            text-decoration: underline;

            &:hover {
                cursor: pointer;
            }
        `};
`;

export const Wrapper = styled.div`
    display: flex;
    margin-bottom: 0;
    align-items: center;
`;

export const H5Icon = styled(Icon)`
    margin-right: 8px;
`;
