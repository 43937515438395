export enum UserPermissions {
    //Dashboard
    DashboardEntreoMenu = 'Dashboard Entreo Menu',
    DashboardEntreoRead = 'Dashboard Entreo Read',
    DashboardEmployersMenu = 'Dashboard Employers Menu',
    DashboardEmployersRead = 'Dashboard Employers Read',
    DashboardProvidersMenu = 'Dashboard Providers Menu',
    DashboardProvidersRead = 'Dashboard Providers Read',

    //Authorization
    AuthorizationUsersMenu = 'Authorization Users Menu',
    AuthorizationUsersCreate = 'Authorization Users Create',
    AuthorizationUsersRead = 'Authorization Users Read',
    AuthorizationUsersUpdate = 'Authorization Users Update',
    AuthorizationUsersDelete = 'Authorization Users Delete',
    AuthorizationRolesMenu = 'Authorization Roles Menu',
    AuthorizationRolesCreate = 'Authorization Roles Create',
    AuthorizationRolesRead = 'Authorization Roles Read',
    AuthorizationRolesUpdate = 'Authorization Roles Update',
    AuthorizationRolesDelete = 'Authorization Roles Delete',
    AuthorizationInvitationsMenu = 'Authorization Invitations Menu',
    AuthorizationInvitationsCreate = 'Authorization Invitations Create',
    AuthorizationInvitationsRead = 'Authorization Invitations Read',
    AuthorizationInvitationsUpdate = 'Authorization Invitations Update',
    AuthorizationInvitationsDelete = 'Authorization Invitations Delete',
    AuthorizationAdminMenu = 'Authorization Admin Menu',
    AuthorizationAdminCreate = 'Authorization Admin Create',
    AuthorizationAdminRead = 'Authorization Admin Read',
    AuthorizationAdminUpdate = 'Authorization Admin Update',
    AuthorizationAdminDelete = 'Authorization Admin Delete',

    //Finance
    FinancePaymentsMenu = 'Finance Payments Menu',
    FinancePaymentsCreate = 'Finance Payments Create',
    FinancePaymentsRead = 'Finance Payments Read',
    FinancePaymentsUpdate = 'Finance Payments Update',
    FinancePaymentsDelete = 'Finance Payments Delete',
    FinanceDeclarationsMenu = 'Finance Declarations Menu',
    FinanceDeclarationsCreate = 'Finance Declarations Create',
    FinanceDeclarationsRead = 'Finance Declarations Read',
    FinanceDeclarationsUpdate = 'Finance Declarations Update',
    FinanceDeclarationsDelete = 'Finance Declarations Delete',
    FinanceEmployerLicenseMenu = 'Finance Employer License Menu',
    FinanceEmployerLicenseCreate = 'Finance Employer License Create',
    FinanceEmployerLicenseRead = 'Finance Employer License Read',
    FinanceEmployerLicenseUpdate = 'Finance Employer License Update',
    FinanceEmployerLicenseDelete = 'Finance Employer License Delete',
    FinanceLicenseMenu = 'Finance License Menu',
    FinanceLicenseCreate = 'Finance License Create',
    FinanceLicenseRead = 'Finance License Read',
    FinanceLicenseUpdate = 'Finance License Update',
    FinanceLicenseDelete = 'Finance License Delete',
    FinanceBfaMenu = 'Finance BFA Menu',
    FinanceBfaCreate = 'Finance BFA Create',
    FinanceBfaRead = 'Finance BFA Read',
    FinanceBfaUpdate = 'Finance BFA Update',
    FinanceBfaDelete = 'Finance BFA Delete',

    //Employers
    EmployersUsersMenu = 'Employers Users Menu',
    EmployersUsersCreate = 'Employers Users Create',
    EmployersUsersRead = 'Employers Users Read',
    EmployersUsersUpdate = 'Employers Users Update',
    EmployersUsersDelete = 'Employers Users Delete',
    EmployersChallengesMenu = 'Employers Challenges Menu',
    EmployersChallengesCreate = 'Employers Challenges Create',
    EmployersChallengesRead = 'Employers Challenges Read',
    EmployersChallengesUpdate = 'Employers Challenges Update',
    EmployersChallengesDelete = 'Employers Challenges Delete',
    EmployersEmployersMenu = 'Employers Employers Menu',
    EmployersEmployersCreate = 'Employers Employers Create',
    EmployersEmployersRead = 'Employers Employers Read',
    EmployersEmployersUpdate = 'Employers Employers Update',
    EmployersEmployersDelete = 'Employers Employers Delete',

    //Providers
    ProvidersEmployeesMenu = 'Providers Employees Menu',
    ProvidersEmployeesCreate = 'Providers Employees Create',
    ProvidersEmployeesRead = 'Providers Employees Read',
    ProvidersEmployeesUpdate = 'Providers Employees Update',
    ProvidersEmployeesDelete = 'Providers Employees Delete',
    ProvidersMenu = 'Providers Menu',
    ProvidersCreate = 'Providers Create',
    ProvidersRead = 'Providers Read',
    ProvidersUpdate = 'Providers Update',
    ProvidersDelete = 'Providers Delete',
    ProvidersChallengesMenu = 'Providers Challenges Menu',
    ProvidersChallengesCreate = 'Providers Challenges Create',
    ProvidersChallengesRead = 'Providers Challenges Read',
    ProvidersChallengesUpdate = 'Providers Challenges Update',
    ProvidersChallengesDelete = 'Providers Challenges Delete',
    ProvidersSubscriptionsMenu = 'Providers Subscriptions Menu',
    ProvidersSubscriptionsCreate = 'Providers Subscriptions Create',
    ProvidersSubscriptionsRead = 'Providers Subscriptions Read',
    ProvidersSubscriptionsUpdate = 'Providers Subscriptions Update',
    ProvidersSubscriptionsDelete = 'Providers Subscriptions Delete',
    ProvidersEmployersMenu = 'Providers Employers Menu',
    ProvidersEmployersCreate = 'Providers Employers Create',
    ProvidersEmployersRead = 'Providers Employers Read',
    ProvidersEmployersUpdate = 'Providers Employers Update',
    ProvidersEmployersDelete = 'Providers Employers Delete',
    ProvidersEmployersChallengesMenu = 'Providers Employers Challenges Menu',
    ProvidersEmployersChallengesCreate = 'Providers Employers Challenges Create',
    ProvidersEmployersChallengesRead = 'Providers Employers Challenges Read',
    ProvidersEmployersChallengesUpdate = 'Providers Employers Challenges Update',
    ProvidersEmployersChallengesDelete = 'Providers Employers Challenges Delete',
    ProvidersOrdersMenu = 'Providers Orders Menu',
    ProvidersOrdersCreate = 'Providers Orders Create',
    ProvidersOrdersRead = 'Providers Orders Read',
    ProvidersOrdersUpdate = 'Providers Orders Update',
    ProvidersOrdersDelete = 'Providers Orders Delete',

    //Challenges
    ChallengesTemplatesMenu = 'Challenges Templates Menu',
    ChallengesTemplatesCreate = 'Challenges Templates Create',
    ChallengesTemplatesRead = 'Challenges Templates Read',
    ChallengesTemplatesUpdate = 'Challenges Templates Update',
    ChallengesTemplatesDelete = 'Challenges Templates Delete',
    ChallengesApproveMenu = 'Challenges Approve Menu',
    ChallengesApproveCreate = 'Challenges Approve Create',
    ChallengesApproveRead = 'Challenges Approve Read',
    ChallengesApproveUpdate = 'Challenges Approve Update',
    ChallengesApproveDelete = 'Challenges Approve Delete',

    //Products
    ProductsMenu = 'Products Menu',
    ProductsCreate = 'Products Create',
    ProductsRead = 'Products Read',
    ProductsUpdate = 'Products Update',
    ProductsDelete = 'Products Delete',
    ProductsOrdersMenu = 'Products Orders Menu',
    ProductsOrdersCreate = 'Products Orders Create',
    ProductsOrdersRead = 'Products Orders Read',
    ProductsOrdersUpdate = 'Products Orders Update',
    ProductsOrdersDelete = 'Products Orders Delete',
    ProductsSuppliersMenu = 'Products Suppliers Menu',
    ProductsSuppliersCreate = 'Products Suppliers Create',
    ProductsSuppliersRead = 'Products Suppliers Read',
    ProductsSuppliersUpdate = 'Products Suppliers Update',
    ProductsSuppliersDelete = 'Products Suppliers Delete',
    ProductsSupplierProductsMenu = 'Products Supplier Products Menu',
    ProductsSupplierProductsCreate = 'Products Supplier Products Create',
    ProductsSupplierProductsRead = 'Products Supplier Products Read',
    ProductsSupplierProductsUpdate = 'Products Supplier Products Update',
    ProductsSupplierProductsDelete = 'Products Supplier Products Delete',

    //Content
    ContentBlogMenu = 'Content Blog Menu',
    ContentBlogCreate = 'Content Blog Create',
    ContentBlogRead = 'Content Blog Read',
    ContentBlogUpdate = 'Content Blog Update',
    ContentBlogDelete = 'Content Blog Delete',
    ContentRecipesMenu = 'Content Recipes Menu',
    ContentRecipesCreate = 'Content Recipes Create',
    ContentRecipesRead = 'Content Recipes Read',
    ContentRecipesUpdate = 'Content Recipes Update',
    ContentRecipesDelete = 'Content Recipes Delete',
    ContentImagesMenu = 'Content Images Menu',
    ContentImagesCreate = 'Content Images Create',
    ContentImagesRead = 'Content Images Read',
    ContentImagesUpdate = 'Content Images Update',
    ContentImagesDelete = 'Content Images Delete',
    ContentContentCreatorsMenu = 'Content ContentCreators Menu',
    ContentContentCreatorsCreate = 'Content ContentCreators Create',
    ContentContentCreatorsRead = 'Content ContentCreators Read',
    ContentContentCreatorsUpdate = 'Content ContentCreators Update',
    ContentContentCreatorsDelete = 'Content ContentCreators Delete',

    //Store
    StoreProductsMenu = 'Store Products Menu',
    StoreProductsCreate = 'Store Products Create',
    StoreProductsRead = 'Store Products Read',
    StoreProductsUpdate = 'Store Products Update',
    StoreProductsDelete = 'Store Products Delete',

    //Configuration
    ConfigurationTagsMenu = 'Configuration Tags Menu',
    ConfigurationTagsCreate = 'Configuration Tags Create',
    ConfigurationTagsRead = 'Configuration Tags Read',
    ConfigurationTagsUpdate = 'Configuration Tags Update',
    ConfigurationTagsDelete = 'Configuration Tags Delete',
    ConfigurationCategoriesAndProductsMenu = 'Configuration CategoriesAndProducts Menu',
    ConfigurationCategoriesAndProductsCreate = 'Configuration CategoriesAndProducts Create',
    ConfigurationCategoriesAndProductsRead = 'Configuration CategoriesAndProducts Read',
    ConfigurationCategoriesAndProductsUpdate = 'Configuration CategoriesAndProducts Update',
    ConfigurationCategoriesAndProductsDelete = 'Configuration CategoriesAndProducts Delete',
    ConfigurationSubscriptionMenu = 'Configuration Subscription Menu',
    ConfigurationSubscriptionCreate = 'Configuration Subscription Create',
    ConfigurationSubscriptionRead = 'Configuration Subscription Read',
    ConfigurationSubscriptionUpdate = 'Configuration Subscription Update',
    ConfigurationSubscriptionDelete = 'Configuration Subscription Delete'
}
