import styled from '@emotion/styled';
import { rgba } from 'polished';

export const Wrapper = styled.div`
    min-height: calc(100vh -${({ theme }) => theme.header.height}rem);
    position: relative;
`;

export const Content = styled.div<{ decorations: string; navState: 'open' | 'closed' }>`
    height: 100vh;
    min-height: 100vh;
    position: relative;
    overflow: auto;
    margin-left: ${({ theme, navState }) => (navState === 'open' ? theme.navigation.width : 100)}px;
    padding: ${({ theme }) => theme.header.height + 2}rem ${({ theme }) => theme.header.padding.horizontal}px 0;
    border-radius: 32px 0 0 32px;
    backdrop-filter: blur(40px);
    background-color: ${({ theme }) => rgba(theme.colors.white, 0.5)};
    background-image: ${({ decorations }) => `url(${decorations})`};
    background-size: 544px 527px;
    background-position: right top;
    background-repeat: no-repeat;
    transition: margin-left 0.3s ease-in-out;
`;

export const Empty = styled.div`
    height: 100vh;
`;
