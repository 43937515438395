import React, { FC } from 'react';
import { Button } from 'components/atoms/button';
import { FormControl } from 'components/atoms/form';
import Group from 'components/molecules/Group';
import { useTranslation } from 'react-i18next';

export interface SearchProps {
    filter: string;
    setFilter: (filter: string) => void;
    onAddClick?: () => void;
    onAddClickLabel?: string;
}

const Search: FC<SearchProps> = ({ filter, setFilter, onAddClick, onAddClickLabel }) => {
    const { t } = useTranslation('common');
    // Methods.
    const handleOnChange = (value: string) => {
        setFilter(value);
    };

    return (
        <Group size="lg">
            <FormControl name="search" placeholder={t('search.placeholder')} startIcon="search" value={filter ?? ''} onChange={handleOnChange} />
            {onAddClick && <Button alignSelf="center" noMargin rounded brand="pink" onClick={onAddClick}>{onAddClickLabel ? onAddClickLabel : 'Toevoegen'}</Button>}
        </Group>
    );
};

export default Search;
