import { DropdownItemProps } from 'components/atoms/item/DropdownItem';
import React, { FC, PropsWithChildren, useCallback, useState } from 'react';
import { Dropdown as Wrapper, Header, Toggle } from './styles';
import { useLayer } from 'react-laag';
import { DropdownMenu } from 'components/molecules/dropdown';
import { H4, H5 } from 'components/atoms/text';
import colors, { Colors } from 'static/theme/colors';
import { AnimatePresence } from 'framer-motion';

export interface DropdownProps {
    items: ((close?: () => void) => DropdownItemProps[]) | DropdownItemProps[];
    onSubItemClick?: (value: string | number | boolean) => void;
    isFull?: boolean;
    isButton?: boolean;
    isFill?: boolean;
    smallText?: boolean;
    isBig?: boolean;
    color?: keyof Colors;
}

const Dropdown: FC<PropsWithChildren<DropdownProps>> = ({ children, items, onSubItemClick, isFull, isButton, smallText, isBig, isFill, color }) => {
    const [isOpen, setIsOpen] = useState(false);

    // Dropdown's methods.
    const close = useCallback(() => setIsOpen(false), []);

    const toggle = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setIsOpen((open) => !open);
    }, []);

    // Laag.
    const { renderLayer, triggerProps, layerProps, triggerBounds } = useLayer({
        isOpen,
        onOutsideClick: close,
        onParentClose: close,
        onDisappear: close,
        overflowContainer: true,
        placement: 'bottom-end',
        possiblePlacements: ['bottom-start', 'bottom-end'],
        auto: true,
        triggerOffset: 8,
        containerOffset: 16
    });

    // Render.
    return (
        <Wrapper {...triggerProps} isFull={isFull} isButton={isButton}>
            <Header isFull={isFull} isFill={isFill} isBig={isBig} isButton={isButton} onClick={toggle}>
                {typeof children === 'string' ? smallText ? <H5 variant={color}>{children}</H5> : <H4 variant={color}>{children}</H4> : children}
                <Toggle isFull={isFull} size={0.5} name="chevron-down-small" color={colors[color || 'black']} />
            </Header>
            {isOpen &&
                renderLayer(
                    <AnimatePresence>
                        <DropdownMenu close={close} items={items} onSubItemClick={onSubItemClick} width={triggerBounds?.width} {...layerProps} ref={layerProps.ref} />
                    </AnimatePresence>
                )
            }
        </Wrapper>
    );
};

export default Dropdown;

