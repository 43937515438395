import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Line = styled.div<{ last?: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem;
    align-items: center;
    font-weight: 100;
    border-bottom: ${({ theme, last }) => (!last ? `1px solid ${theme.colors.light}` : 'none')};
`;

export const Key = styled.span<{ small?: boolean }>`
    font-size: ${({ small }) => small ? 0.6875 : 0.875}rem;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Value = styled.div<{ small?: boolean; highlightValue?: boolean }>`
    line-height: 1;
    font-weight: 500;
    font-size: ${({ small }) => small ? 0.875 : 1.1}rem
    ${({ highlightValue, theme }) =>
        highlightValue &&
        css`
        color: ${theme.colors.blue};
    `}
`;
