import { useApi } from 'hooks/api';
import { ActiveEnvironmentEnum, useSession } from 'contexts/sessionContext';
import { ClaimModel, SessionModel } from 'api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Role } from 'hooks/roles';

export enum GenderEnum {
    Male = 0,
    Female = 1
}

export enum EmailInfoResponseTypeEnum {
    NotExisting = 1,
    NotCompleted = 2,
    Completed = 3
}

export interface EditProfileDetailsModel {
    id: string;
    firstName: string;
    insertion?: string;
    lastName: string;
    email: string;
}

export interface UserProfileModel {
    id: string;
    firstName: string;
    insertion?: string;
    lastName: string;
    email: string;
}

export interface UserOverviewModel {
    id: string;
    fullName: string;
    email: string;
    phone: string;
    address: string;
    city: string;
    employer: string;
    provider: string;
    roles: string[];
    created: string;
    changed: string;
    userName: string;
    avatar?: string;
    dateOfBirth?: string;
    gender?: GenderEnum;
    houseNumber: string;
    postalCode: string;
    employeeNumber?: string;
    ibanName?: string;
    ibanNumber?: string;
}

export interface PasswordForgottenModel {
    userName: string;
}

export interface ResetPasswordModel {
    code: string;
    userName: string;
    password: string;
    confirmPassword: string;
}

export interface DeleteAccountInputModel {
    token: string;
    userId: string;
}

export type DetailedUserModel = {
    userIsEmployee: boolean;
    gender?: GenderEnum;
    firstName: string;
    insertion?: string;
    lastName: string;
    dateOfBirth?: string;
    phoneNumber?: string;
    email: string;
    address?: string;
    houseNumber?: string;
    postalCode?: string;
    city?: string;
    id: string;
    employeeNumber?: string;
    iban?: string;
    ibanName?: string;
    roles: Role[];
};

export const useUser = () => {
    const { callApi, refreshSession } = useApi();
    const { t } = useTranslation('gyms');
    const { clearSession } = useSession();

    const switchActiveGym = async (gymId: string) => {
        const response = await callApi.current<SessionModel>('/Account/v1/SwitchActiveGym', {
            method: 'PUT',
            body: JSON.stringify({ gymId })
        });

        if (response.ok) {
            await refreshSession();
        } else {
            toast.error(t('gyms:switch.error'));
        }

        return response;
    };

    const switchActiveEmployer = async (employerId: string) => {
        const response = await callApi.current<SessionModel>('/Account/v1/SwitchActiveEmployer', {
            method: 'PUT',
            body: JSON.stringify({ employerId })
        });

        if (response.ok) {
            await refreshSession();
        } else {
            toast.error(t('employers:switch.error'));
        }

        return response;
    };

    const switchActiveLocation = async (locationId: string) => {
        const response = await callApi.current<SessionModel>('/Account/v1/SwitchActiveLocation', {
            method: 'PUT',
            body: JSON.stringify({ locationId })
        });

        if (response.ok) {
            await refreshSession();
        } else {
            toast.error(t('locations:switch.error'));
        }

        return response;
    };

    const switchActiveAssociation = async (associationId: string) => {
        const response = await callApi.current<SessionModel>('/Account/v1/SwitchActiveAssociation', {
            method: 'PUT',
            body: JSON.stringify({ associationId })
        });
        if (response.ok) {
            await refreshSession();
        } else {
            toast.error(t('associations:switch.error'));
        }
        return response;
    };

    const switchActiveContentEnvironment = async (contentEnvironmentId: string) => {
        const response = await callApi.current<SessionModel>('/Account/v1/SwitchActiveContentEnvironment', {
            method: 'PUT',
            body: JSON.stringify({ contentEnvironmentId })
        });
        if (response.ok) {
            await refreshSession();
        } else {
            toast.error(t('associations:switch.error'));
        }
        return response;
    };

    const switchActiveSupplierEnvironment = async (supplierEnvironmentId: string) => {
        const response = await callApi.current<SessionModel>('/Account/v1/SwitchActiveSupplierEnvironment', {
            method: 'PUT',
            body: JSON.stringify({ supplierEnvironmentId })
        });
        if (response.ok) {
            await refreshSession();
        } else {
            toast.error(t('associations:switch.error'));
        }
        return response;
    };

    const switchActiveProviderEnvironment = async (providerEnvironmentId: string) => {
        const response = await callApi.current<SessionModel>('/Account/v1/SwitchActiveProviderEnvironment', {
            method: 'PUT',
            body: JSON.stringify({ providerEnvironmentId })
        });
        if (response.ok) {
            await refreshSession();
        } else {
            toast.error(t('associations:switch.error'));
        }
        return response;
    };

    const switchActiveEnvironment = async (environment: ActiveEnvironmentEnum) => {
        const response = await callApi.current<SessionModel>('/Account/v1/SwitchActiveEnvironment', {
            method: 'PUT',
            body: JSON.stringify({ Environment: environment })
        });

        if (response.ok) {
            await refreshSession();
        } else {
            if (environment === ActiveEnvironmentEnum.Location) {
                toast.error(t('common:switchEnvironment.locationError'));
            } else {
                toast.error(t('common:switchEnvironment.error'));
            }
        }

        return response;
    };

    const currentProfileInfo = async () => {
        const response = callApi.current<UserProfileModel>('/User/v1/CurrentProfileInfo');

        return response;
    };

    const editProfileDetails = async (values: EditProfileDetailsModel) => {
        const response = await callApi.current<UserProfileModel>('/User/v1', {
            method: 'PUT',
            body: JSON.stringify(values)
        });

        return response;
    };

    const allUserOverviews = async () => {
        const response = await callApi.current<UserOverviewModel[]>('/user/v1');
        return response;
    };

    const passwordForgotten = async (values: PasswordForgottenModel) => {
        const response = await callApi.current('/Account/v1/forgotPassword', {
            method: 'POST',
            body: JSON.stringify(values)
        });

        return response;
    };

    const resetPassword = async (values: ResetPasswordModel) => {
        const response = await callApi.current('/Account/v1/resetPassword', {
            method: 'PUT',
            body: JSON.stringify(values)
        });

        return response;
    };

    const checkUsername = async (username: string) => {
        const response = await callApi.current<EmailInfoResponseTypeEnum>(`/Account/v1/${username}/checkUsername`);

        return response;
    };

    const logOut = async () => {
        const response = await callApi.current('/account/v1/cookielogout', {
            method: 'POST'
        });

        if (response.ok) {
            clearSession();
        }

        return response;
    };

    const deleteUser = async (model: DeleteAccountInputModel) => {
        const response = await callApi.current('/account/v1/deleteAccount', { method: 'DELETE', body: JSON.stringify(model) });
        return response;
    };

    const requestDeleteUser = async (userId: string) => {
        const response = await callApi.current('/account/v1/requestAccountDeletion', { method: 'POST', body: JSON.stringify({ userId }) });
        return response;
    };

    const unsubscribeFromEmails = async (code: string) => {
        const response = await callApi.current(`/Account/v1/unsubscribe/${code}`, {
            method: 'PUT'
        });
        return response;
    };

    const getUserDetails = async (userId: string) => {
        const response = await callApi.current<DetailedUserModel>(`/User/v1/Detailed/${userId}`, {
            method: 'GET'
        });

        return response;
    };

    const unsubscribeFromChallengeEmails = async (code: string) => {
        const response = await callApi.current(
            `/Account/v1/unsubscribe/challenge/${code}`,
            {
                method: 'PUT'
            }
        );
        return response;
    };

    const remainingEmployerContribution = async (userId: string) => {
        const response = await callApi.current<number>(
            `/User/v1/RemainingEmployerContribution/${userId}`,
            {
                method: 'GET'
            }
        );
        return response;
    };

    const deleteUserById = async (id: string) => callApi.current('/User/v1/deleteUser', { method: 'DELETE', body: JSON.stringify(id) });

    const getCurrentUserClaims = async () =>
        await callApi.current<ClaimModel[]>('/Account/v1/claims', {
            method: 'GET'
        });

    const editUserRole = async (userId: string, roles: string[]) =>
        await callApi.current(`/User/v1/${userId}/ChangeUserRole`, {
            method: 'PUT',
            body: JSON.stringify(roles)
        });

    return {
        switchActiveGym,
        switchActiveEmployer,
        switchActiveAssociation,
        switchActiveEnvironment,
        switchActiveContentEnvironment,
        switchActiveSupplierEnvironment,
        switchActiveProviderEnvironment,
        currentProfileInfo,
        editProfileDetails,
        allUserOverviews,
        passwordForgotten,
        resetPassword,
        switchActiveLocation,
        checkUsername,
        logOut,
        deleteUser,
        requestDeleteUser,
        getUserDetails,
        deleteUserById,
        getCurrentUserClaims,
        editUserRole,
        unsubscribeFromEmails,
        unsubscribeFromChallengeEmails,
        remainingEmployerContribution
    };
};
