import { useField } from 'formik';
import React, { FC } from 'react';
import FormFieldArray, { FormSingleErrorProps } from '..';

interface FormikFormikFormSingleErrorProps extends FormSingleErrorProps {
    name: string;
}

const FormikFormSingleError: FC<FormikFormikFormSingleErrorProps> = (props) => {
    const [field, meta] = useField(props.name);

    // Render.
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    return <FormFieldArray {...field} {...props} error={error} />;
};

export default FormikFormSingleError;