import React from 'react';
import { PageWrapper } from '../styles';
import { H2 } from 'components/atoms/text';
import { useTranslation } from 'react-i18next';

export const MissingPage: React.FC = () => {
    const { t } = useTranslation('missingPage');

    return (
        <PageWrapper>
            <H2 center>{t('title')}</H2>
        </PageWrapper>
    );
};
