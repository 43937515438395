import { useApi } from 'hooks/api';
import { AddCategoryInputModel, EditCategoryInputModel, CategoryModel } from './types';


export const useCategory = () => {
    const { callApi } = useApi();

    const allCategories = async () => {
        const response = await callApi.current<CategoryModel[]>('/Category/v1');

        return response;
    };

    const addCategory = async (values: AddCategoryInputModel) => {
        const response = await callApi.current('/Category/v1', {
            method: 'POST',
            body: JSON.stringify(values)
        });

        return response;
    };

    const editCategory = async (values: EditCategoryInputModel) => {
        const response = await callApi.current('/Category/v1', {
            method: 'PUT',
            body: JSON.stringify(values)
        });
        return response;
    };

    const getCategory = async (id: string) => {
        const response = await callApi.current<CategoryModel>(`/Category/v1/${id}`);
        return response;
    };

    const removeCategory = async (id: string) => {
        const response = await callApi.current(`/Category/v1/${id}`, {
            method: 'DELETE'
        });
        return response;
    };

    return {
        allCategories,
        addCategory,
        editCategory,
        getCategory,
        removeCategory
    };
};
