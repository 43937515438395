import { useTheme } from '@emotion/react';
import { MeasurableType } from 'hooks/challenge';
import { rgba } from 'polished';
import React, { FC } from 'react';
import { getIconByMeasurableType } from 'utils/challengeHelper';
import Icon from '../Icon';
import { Medal as Wrapper, Type } from './styles';

interface MedalProps {
    type: MeasurableType;
}

const Medal: FC<MedalProps> = ({ type }) => {
    const theme = useTheme();

    return (
        <Wrapper>
            <Icon name="prize" size={3} />
            <Type>
                <Icon name={getIconByMeasurableType(type)} size={1} color={rgba(theme.colors.black, 0.3)} />
            </Type>
        </Wrapper>
    );
};

export default Medal;

