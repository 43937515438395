import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Pagination = styled.ul`
    list-style: none;
    list-style-image: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-left: auto;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: .85rem;
    color: ${({ theme }) => theme.colors.grey};

    button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        font-weight: inherit;
        border-radius: 50%;
    }

    li {
        margin: 0 .15rem;

        &:first-of-type {
            margin-left: 0;
        }   
        &:last-of-type {
            margin-right: 0;
        }
    }
`;

export const Number = styled.button<{ active?: boolean }>`
    color: inherit;
    &:hover {
        color: ${({ theme }) => theme.colors.black};
    }
    ${({ active, theme }) =>
        active &&
        css`
        color: ${theme.colors.white};
        background-color: ${theme.brand.pink};
        &:hover {
            color: ${theme.colors.white};
        }
    `};
`;


export const NavButton = styled.button``;
