import styled from '@emotion/styled';

export const H2 = styled.h2<{ color: string; center?: boolean; noMargin?: boolean }>`
    color: ${({ color }) => color};
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-size: 1.125rem;
    line-height: 1.125rem;
    font-weight: 600;
    margin-bottom: ${({ noMargin }) => (!noMargin ? '.5rem' : 0)};
    position: relative;
`;

