import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';

export const Wrapper = styled.div`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.greys[50]};
    border-radius: ${({ theme }) => theme.form.height / 2}rem;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 1rem;
`;

export const InputWrapper = styled.div<{ isEmpty?: boolean }>`
    height: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.greys[50]};
    box-sizing: content-box;
    display: flex;
    margin-bottom: 1rem;

    ${({ isEmpty }) =>
        isEmpty &&
        css`
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        `}
`;

export const IconWrapper = styled.div`
    height: 1.5rem;
    width: 1.5rem;
    flex: 0 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Input = styled.input`
    height: 1.5rem;
    flex: 1;
    border: none;

    &:focus {
        outline: none;
    }
`;

export const Items = styled.div`
    max-height: 13.5rem;
    overflow: auto;
`;

export const Item = styled.div<{ isActive?: boolean }>`
    height: 4.5rem;
    display: flex;
    border-radius: ${({ theme }) => theme.borderRadius.large}px;
    align-items: center;
    padding: 0 1.5rem;

    &:hover {
        background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
    }

    ${({ isActive, theme }) =>
        isActive &&
        css`
            background-color: ${rgba(theme.colors.primary, 0.2)};
        `}
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.span`
    font-size: 0.875rem;
    line-height: 1;
    font-weight: 500;
    display: block;
`;

export const Subtitle = styled.span`
    color: #8e8e8e; // TODO: var.
    font-size: 0.875rem;
    line-height: 1;
    margin-top: 6px;
    display: block;
`;

