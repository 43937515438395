import React, { FC } from 'react';
import { UseSelectedProviderReturn } from './useSelectedProvider';
import { useUserAuthorization } from 'hooks/authorization/useUserAuthorization';
import { CustomSelect, CustomSelectProps, CustomSelectValue, CustomSelectOption, CustomSelectSingleValue } from 'components/atoms/Select';
import { useTranslation } from 'react-i18next';

export type ProviderDropdownProps = UseSelectedProviderReturn & Partial<CustomSelectProps>;

export const ProviderDropdown: FC<ProviderDropdownProps> = ({ providers = [], selectedProvider, handleProviderChange, ...rest }) => (
    <CustomSelect
        isLight
        options={providers.map((provider) => ({
            label: provider.name,
            value: provider.id
        }))}
        isLoading={rest.isProviderLoading}
        onChange={(selected: CustomSelectValue) => handleProviderChange((selected as CustomSelectSingleValue)?.value as string, providers)}
        value={{ value: selectedProvider?.id, label: selectedProvider?.name } as CustomSelectOption}
        {...rest}
    />
);

export const ProviderDropdownWithAuth: FC<ProviderDropdownProps> = (props) => {
    const { isAdmin, isProvider } = useUserAuthorization();
    const { t } = useTranslation('manageEmployees'); 

    return isAdmin || isProvider ? <ProviderDropdown {...props} placeholder={t('search.placeholder')} loadingMessage={() => t('search.loading')} noOptionsMessage={() => t('search.noResults')} /> : null;
};
