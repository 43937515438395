import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { ChallengeThemeType } from 'hooks/challengeTemplate';
import Margin from 'components/atoms/Margin';
import { H3 } from 'components/atoms/text';
import Group from 'components/molecules/Group';
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';
import { getDefaultValueByTheme } from 'utils/challengeHelper';
import { ChallengeTemplateValues } from '..';
import ChallengeTemplateTheme, { ChallengeTemplateThemeProps } from 'templates/ChallengeTemplate/components/ChallengeTemplateTheme';

interface Step1Props {
    mode: 'edit' | 'add';
}

const Step1: FC<Step1Props> = ({ mode }) => {
    const { t } = useTranslation('challengeTemplate');
    const { values, setFieldValue } = useFormikContext<ChallengeTemplateValues>();

    const handleSelectTheme = (theme?: ChallengeThemeType) => {
        if (theme === undefined) {
            return;
        }
        setFieldValue('theme', theme);
    };

    const themes: (DropdownItemProps & Omit<ChallengeTemplateThemeProps, 'onClick'>)[] = useMemo(
        () => [
            { id: '0', image: 'https://picsum.photos/400/300?random=2', title: 'Bewegen', theme: ChallengeThemeType.Movement },
            { id: '1', image: 'https://picsum.photos/400/300?random=3', title: 'Voeding', theme: ChallengeThemeType.Nutrition },
            { id: '2', image: 'https://picsum.photos/400/300?random=4', title: 'Ontspannen', theme: ChallengeThemeType.Relaxation }
        ],
        [t]
    );

    useEffect(() => {
        const defaultThemeValues = getDefaultValueByTheme(values.theme);
        setFieldValue('activity', defaultThemeValues.activity);
        setFieldValue('measurement', defaultThemeValues.measurementType);
        setFieldValue('measurementUnit', defaultThemeValues.measurementUnit);
        setFieldValue('thirdParty', undefined);
    }, [values.theme]);

    return (
        <>
            <Margin bottom={1}>
                <H3>{t(`step1.${mode}.title`)}</H3>
            </Margin>
            <Margin bottom={1}>
                <Group>
                    {themes
                        .map((theme) => (
                            <ChallengeTemplateTheme
                                key={theme.id}
                                {...theme}
                                isActive={values.theme === theme.theme}
                                onClick={(theme) => handleSelectTheme(theme)}
                            />
                        ))}
                </Group>
            </Margin>
        </>
    );
};

export default Step1;

