import React, { FC, PropsWithChildren } from 'react';
import { Title } from './styles';

interface SectionProps {
    number: number;
    subNumber?: number;
}

const Section: FC<PropsWithChildren<SectionProps>> = ({ number, subNumber, children }) => {
    return (
        <Title>
            {number}.{subNumber != null ? `${subNumber}.` : ''} {children}
        </Title>
    );
};

export default Section;
