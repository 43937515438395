import { useApi } from 'hooks/api';
import { appendGuidArray, appendObject } from 'utils/objectAppender';
import { AddSubscriptionInputModel, EditSubscriptionInputModel, SubscriptionProviderDetailModel, SubscriptionPlainModel, SubscriptionAdminModel, GetSubscriptionsInputModel } from './types';


export const useProviderSubscription = () => {
    const { callApi } = useApi();

    const adminSubscriptions = async () => {
        const response = await callApi.current<SubscriptionAdminModel[]>('/Subscription/v1/Plain/Admin');

        return response;
    };

    const allSubscriptions = async (providerId: string) => {
        const response = await callApi.current<SubscriptionPlainModel[]>(`/Subscription/v1/Plain/${providerId}`);

        return response;
    };

    const addSubscription = async (values: AddSubscriptionInputModel) => {
        const formData = new FormData();
        appendObject(values, formData);

        if (values.tags !== undefined) {
            appendGuidArray(values.tags, formData, 'tags');
        }
        if (values.products !== undefined) {
            appendGuidArray(values.products, formData, 'products');
        }

        if (values.startDate !== undefined) {
            formData.append('startDate', values.startDate.toUTCString());
        }

        if (values.banner !== undefined) {
            formData.append('banner', values.banner);
        }

        const response = await callApi.current('/Subscription/v1', {
            method: 'POST',
            body: formData
        });

        return response;
    };

    const editSubscription = async (values: EditSubscriptionInputModel) => {
        const formData = new FormData();
        appendObject(values, formData);

        if (values.tags !== undefined) {
            appendGuidArray(values.tags, formData, 'tags');
        }
        if (values.products !== undefined) {
            appendGuidArray(values.products, formData, 'products');
        }


        if (values.startDate !== undefined) {
            formData.append('startDate', values.startDate.toUTCString());
        }

        if (values.banner !== undefined) {
            formData.append('banner', values.banner);
        }

        const response = await callApi.current('/Subscription/v1', {
            method: 'PUT',
            body: formData
        });
        return response;
    };

    const getSubscription = async (id: string) => {
        const response = await callApi.current<SubscriptionProviderDetailModel>(`/Subscription/v1/detailsProvider/${id}`);
        return response;
    };

    const getSubscriptions = async (model: GetSubscriptionsInputModel) => {
        const response = await callApi.current<SubscriptionProviderDetailModel[]>('/Subscription/v1/detailsProvider/all', {
            method: 'POST',
            body: JSON.stringify(model)
        });
        return response;
    };

    const removeSubscription = async (id: string) => {
        const response = await callApi.current(`/Subscription/v1/DeleteSubscription/${id}`, {
            method: 'DELETE'
        });
        return response;
    };

    return {
        adminSubscriptions,
        allSubscriptions,
        addSubscription,
        editSubscription,
        getSubscription,
        getSubscriptions,
        removeSubscription
    };
};
