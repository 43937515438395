import React, { ReactNode, useState } from 'react';
import {
    Row,
} from 'react-table';
import { RowProps } from '../..';
import { Td, Tr } from '../../styles';
import Icon from 'components/atoms/Icon';

export interface RowExtendableProps {
    extendable: boolean,
    child?: ReactNode,
    onExtendableClick: (id: string) => void
}

export interface TableRowProps<T extends object> {
    row: Row<T>,
    rowProps: RowProps<T> | undefined,
    showFooterWithSingularPage: boolean,
    rowExtendableProps?: RowExtendableProps
}

const TableRow = <T extends object>({ row, rowProps, showFooterWithSingularPage, rowExtendableProps }: TableRowProps<T>) => {

    const [expanded, setExpanded] = useState(false);

    const onClick = () => {
        if (rowProps?.onClick) {
            rowProps.onClick(row.original);
        }
        if (rowExtendableProps) {

            if (rowExtendableProps.extendable) {
                setExpanded(!expanded);
            }
            if (rowExtendableProps.onExtendableClick) {
                rowExtendableProps.onExtendableClick('');
            }
        }
    };

    return (
        <>
            <Tr {...row.getRowProps()} onClick={onClick}>
                {rowExtendableProps?.extendable ?
                    <Icon name={expanded ? 'chevron-up' : 'chevron-down'} size={2.5} /> : null
                }
                {row.cells.map((cell) => {
                    return (
                        <>
                            <Td
                                isClick={!!rowProps?.onClick}
                                {...cell.getCellProps()}
                                hideFooter={!showFooterWithSingularPage}
                            >
                                {cell.render('Cell')}
                            </Td>
                        </>
                    );
                })}
            </Tr>
            <Tr>
                {rowExtendableProps && expanded && rowExtendableProps.child ? rowExtendableProps.child : null}
            </Tr>
        </>
    );
};

export default TableRow;
