import React, { FC } from 'react';
import { Navigation as Wrapper, Header, Toggle, Logo } from './styles';
import entreoLogo from 'static/images/entreo-logo.svg';
import Icon from 'components/atoms/Icon';
import { NavigationMenu, NavigationMenuProps } from 'components/molecules/NavigationMenu';

type AppNavigationMenuProps = NavigationMenuProps & {
    toggle: () => void;
};

export const AppNavigationMenu: FC<AppNavigationMenuProps> = ({ toggle, isOpen, items }) => (
    <Wrapper open={isOpen}>
        {toggle && (
            <Toggle onClick={toggle}>
                <Icon name={isOpen ? 'chevron-left' : 'chevron-right'} size={1.25} />
            </Toggle>
        )}
        <Header open={isOpen}>
            <Logo src={entreoLogo} alt="Entreo" />
        </Header>
        <NavigationMenu items={items} isOpen={isOpen} />
    </Wrapper>
);
