// primarily entreo ring/challenge colors. Be cautious to add colors here
export interface Form {
    height: number;
    padding: {
        horizontal: number;
    };
    fontSize: number;
    lineHeight: number;
}

const form: Form = {
    height: 3.25,
    padding: {
        horizontal: 16
    },
    fontSize: 0.875,
    lineHeight: 1.125
};

export default form;
