import React, { useCallback, useState } from 'react';
import useModal from '../modal';
import Modal from 'components/organisms/Modal';
import { useTranslation } from 'react-i18next';
import Loading from 'components/atoms/Loading';
import { toast } from 'react-toastify';
import { Form, Formik, FormikHelpers } from 'formik';
import { toFormikErrors } from 'utils/errorhelper';
import Panel from 'components/organisms/Panel';
import { useCategory } from 'hooks/entreoCategory';
import { AddCategoryInputModel, EditCategoryInputModel, CategoryInputModel, CategoryModel } from 'hooks/entreoCategory/types';
import { FormGroup, FormikFormControl, FormikFormSelect } from 'components/atoms/form';
import { interfaceSelector } from 'utils/objectAppender';
import { DropdownItemProps } from 'components/atoms/item/DropdownItem';
import { icons } from 'static/theme/icons';
import Group from 'components/molecules/Group';
import { RoundButton } from 'components/atoms/button';

const useCategoryModal = (onRefresh: () => void): [(categoryId?: string) => void, () => void, boolean] => {

    const { t } = useTranslation('entreoProductCategory');
    const [iconOptions, setIconOptions] = useState<any[]>([]);
    const { addCategory, getCategory, editCategory } = useCategory();

    // State.
    const [category, setCategory] = useState<CategoryModel | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getIcons = () => {
        let allIcons: DropdownItemProps[] = interfaceSelector(icons);
        allIcons = [{ value: '', title: t('modal.form.pickIcon') }, ...allIcons];
        setIconOptions(allIcons);
    };

    const fetchCategorys = async (categoryId: string) => {
        setIsLoading(true);
        const response = await getCategory(categoryId ?? '');
        if (response && response.data) {
            const categoryData = response.data;
            setCategory(categoryData);
        }
        setIsLoading(false);
    };

    // Methods.
    const handleOnSubmit = async (values: CategoryInputModel, helpers: FormikHelpers<CategoryInputModel>) => {
        if (isSubmitting) {
            return;
        }
        if (!!category && ('id' in values)) {
            handleEdit(values, helpers as FormikHelpers<EditCategoryInputModel>);
        } else {
            handleAdd(values, helpers);
        }
    };

    const handleAdd = async (values: AddCategoryInputModel, helpers: FormikHelpers<AddCategoryInputModel>) => {
        setIsSubmitting(true);

        const response = await addCategory(values);

        if (response.ok) {
            onRefresh();
            toast.success(t('common:successAdd'));
            hide();
        } else if (response.errors) {
            const errors = toFormikErrors(response.errors);
            helpers.setErrors(errors);
        } else {
            toast.warning(t('common:general_error'));
            //Show errors in the steps?
        }
        setIsSubmitting(false);
    };

    const handleEdit = async (values: EditCategoryInputModel, helpers: FormikHelpers<EditCategoryInputModel>) => {
        setIsSubmitting(true);
        const response = await editCategory(values);

        if (response.ok) {
            onRefresh();
            toast.success(t('common:successEdit'));
            hide();
        } else if (response.errors) {
            const errors = toFormikErrors(response.errors);
            helpers.setErrors(errors);
        } else {
            toast.warning(t('common:general_error'));
            //Show errors in the steps?
        }
        setIsSubmitting(false);
    };

    const setInitialValues = (): CategoryInputModel => {
        return category ? {
            id: category.id,
            title: category.title,
            code: category.code,
            icon: category.icon,
            order: category.order,
        } : {
            title: '',
            code: '',
            icon: iconOptions[0]?.value ?? '',
            order: 0
        };
    };

    // Render.
    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => {
            if (isLoading) {
                return (
                    <Modal onHide={onHideModal} inProp={inProp} onExited={onExited} title={t('title')} size="large">
                        <Loading />
                    </Modal>
                );
            }
            const initialValues = setInitialValues();

            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            return (
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleOnSubmit}
                    isSubmitting={isSubmitting}
                    validateOnMount
                    validateOnChange
                >
                    {({ values, submitForm }) => (
                        <Panel
                            onHide={onHideModal}
                            inProp={inProp}
                            onExited={onExited}
                            title={t(`modal.form.title.${category === undefined ? 'add' : 'edit'}`)}
                            onCloseSide
                            buttons={[{ title: t('common:button.confirm'), onClick: submitForm }]}
                        >
                            <Form>
                                <FormGroup label={t('modal.form.name')} required>
                                    <FormikFormControl name="title" />
                                </FormGroup>
                                <FormGroup label={t('modal.form.code')} required>
                                    <FormikFormControl name="code" />
                                </FormGroup>
                                <FormGroup label={t('modal.form.order')} required>
                                    <FormikFormControl name="order" type="number" />
                                </FormGroup>
                                <FormGroup label={t('modal.form.icon')} required>
                                    <Group>
                                        <RoundButton disabled size="lg" brand="pink" icon={values?.icon ?? ''} />
                                        <FormikFormSelect<string> name="icon" options={iconOptions} />
                                    </Group>
                                </FormGroup>
                            </Form>
                        </Panel>
                    )}
                </Formik>
            );
        },
        [isLoading, category, iconOptions, isSubmitting]
    );

    const showModal = useCallback(
        async (categoryId?: string) => {
            if (categoryId) {
                fetchCategorys(categoryId);
            }
            getIcons();
            show();
        },
        [show]
    );

    const onHideModal = () => {
        setCategory(undefined);
        hide();
    };

    return [showModal, hide, isShowing];
};

export default useCategoryModal;

