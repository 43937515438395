import styled from '@emotion/styled';

const PictureBankImages = styled.div`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    margin-bottom: 2rem;
`;

export default PictureBankImages;
