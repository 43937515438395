import { useApi } from 'hooks/api';
import { AddTagInputModel, EditTagInputModel, TagModel } from './types';


export const useTag = () => {
    const { callApi } = useApi();

    const allTags = async () => {
        const response = await callApi.current<TagModel[]>('/Tag/v1/Plain');
        return response;
    };

    const addTag = async (values: AddTagInputModel) => {
        const response = await callApi.current('/Tag/v1', {
            method: 'POST',
            body: JSON.stringify(values)
        });

        return response;
    };

    const editTag = async (values: EditTagInputModel) => {
        const response = await callApi.current('/Tag/v1', {
            method: 'PUT',
            body: JSON.stringify(values)
        });
        return response;
    };

    const getTag = async (id: string) => {
        const response = await callApi.current<TagModel>(`/Tag/v1/${id}`);
        return response;
    };

    const removeTag = async (id: string) => {
        const response = await callApi.current(`/Tag/v1/${id}`, {
            method: 'DELETE'
        });
        return response;
    };

    return {
        allTags,
        addTag,
        editTag,
        getTag,
        removeTag
    };
};
