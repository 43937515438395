import React, { FC } from 'react';
import theme from 'static/theme';
import Icon from '../Icon';
import { Step as Wrapper, Number, Content, Title, Subtitle } from './styles';

export interface RoundStepProps {
    step: number;
    active?: boolean;
    optional?: boolean;
    title: string;
    onClick?: () => void;
    disabled?: boolean;
    highlight?: boolean;
    completed?: boolean;
}

const RoundStep: FC<RoundStepProps> = ({ step, optional, active, title, onClick, disabled = false, highlight, completed }) => {
    // Methods.
    const handleOnClick = () => {
        onClick && !disabled && onClick();
    };

    // Render.
    return (
        <Wrapper onClick={handleOnClick} active={active} disabled={disabled} hightlight={highlight}>
            <Number active={active} hightlight={highlight} disabled={disabled}>
                {completed ? <Icon color={theme.colors.white} name="check" size={0.6} /> : step}
            </Number>

            <Content>
                <Title active={active} hightlight={highlight}>
                    {title}
                </Title>
                {optional && <Subtitle>Optioneel</Subtitle>}
            </Content>
        </Wrapper>
    );
};

export default RoundStep;

