// primarily entreo ring/challenge colors. Be cautious to add colors here
export interface Brand {
    blue: string;
    pink: string;
    yellow: string;
    babyBlue: string;
    background: string;
}

const brand: Brand = {
    blue: '#5972D9',
    pink: '#ED6594',
    yellow: '#F6AE2D',
    babyBlue: '#5DCDFD',
    background: '#E5EAFC'
};

export default brand;
