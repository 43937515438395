import { useApi } from 'hooks/api';
import { ChallengeTemplatePlainModel, ChallengeTemplateStatusType } from 'hooks/challengeTemplate';
import { appendObject } from 'utils/objectAppender';
import { TimeFilter } from 'utils/timeFilterHelper/timeFilterHelper';
import { buildUriWithParams } from 'utils/uriHelper';

export enum MeasurableType {
    Steps = 1,
    GymVisits = 2,
    ExternalDistance = 3
}

export type ChallengesQueryOptions =
    | {
        locationId?: string;
    }
    | {
        employerId?: string;
    }
    | {
        entreo?: boolean;
    }
    | {
        associationId?: string;
    };

export interface EnvironmentProps {
    employerId?: string;
    associationId?: string;
    locationId?: string;
    entreo?: boolean;
}

export interface ChallengePlainModel {
    id: string;
    name: string;
    description: string;
    theme: ChallengeThemeType;
    difficulty: ChallengeRatingType;
    challengeTemplateId: string;
    employerId?: string;
    locationId?: string;
    associationId?: string;
    startDate?: Date;
    endDate?: Date;
    fromDate?: Date;
    fromEndDate?: Date;
    entreoPointsReward: number;
    isActive: boolean;
    sendNotifications: boolean;
    isAvailableToUsers: boolean;
}

export interface AddChallengeInputModel {
    name?: string;
    challengeTemplateId: string;
    employerId?: string;
    locationId?: string;
    associationId?: string;
    startDate?: Date;
    endDate?: Date;
    fromDate?: Date;
    fromEndDate?: Date;
    entreoPointsReward: number;
    isActive: boolean;
    sendNotifications: boolean;
    createdByEntreo: boolean;
    status?: ChallengeTemplateStatusType;
}

export enum ChallengeRatingType {
    Beginner = 0,
    Advanced = 1,
    Expert = 2
}

export enum ChallengeGroupType {
    Single = 0,
    Group = 1
}

export enum ChallengeMeasurementUnitType {
    Steps = 0,
    HeartRhythm = 1,
    Minutes = 2,
    Hour = 3,
    Day = 4,
    Week = 5,
    Milligram = 6,
    Gram = 7,
    Pound = 8,
    Kilogram = 9,
    Milliliter = 10,
    Centiliter = 11,
    Deciliter = 12,
    Liter = 13,
    Meter = 14,
    Kilometer = 15,
    Piece = 16
}

export enum ChallengeMeasurementType {
    Amount = 0,
    Distance = 1,
    Piece = 2,
    Weight = 3,
    Volume = 4,
    Duration = 5
}

export enum ChallengeThemeType {
    Movement = 0,
    Nutrition = 1,
    Relaxation = 2
}

export enum ChallengeCompletionPeriodType {
    PerDay = 0,
    PerWeek = 1,
    PerMonth = 2
}

export enum ChallengeAvailabilityType {
    Internal = 1,
    Public = 2
}

export interface EditChallengeInputModel {
    id: string;
    name?: string;
    challengeTemplateId: string;
    employerId?: string;
    locationId?: string;
    associationId?: string;
    startDate?: Date;
    endDate?: Date;
    fromDate?: Date;
    fromEndDate?: Date;
    isActive: boolean;
    entreoPointsReward: number;
    sendNotifications: boolean;
    status?: ChallengeTemplateStatusType;
}

export interface ChallengeModel {
    id: string;
    challengeTemplateId: string;
    challengeTemplatePlainModel: ChallengeTemplatePlainModel;
    employerId?: string;
    locationId?: string;
    associationId?: string;
    startDate?: Date;
    endDate?: Date;
    fromDate?: Date;
    fromEndDate?: Date;
    entreoPointsReward: number;
    isActive: boolean;
    sendNotifications: boolean;
    status?: ChallengeTemplateStatusType;
    name: string;
}

export interface PopularChallengesModel {
    measurableType: MeasurableType;
    percentageOfPeopleJoined: number;
    amountOfChallenges: number;
    amountDone: number;
}

export interface PopularChallengesInputModel {
    timeFilter: TimeFilter;
    employerId?: string;
}

export const getFormattedChallengeInput = <TValues extends Pick<ChallengeModel, 'startDate' | 'endDate' | 'fromDate' | 'fromEndDate'>>(
    values: TValues
) => {
    const formData = new FormData();

    appendObject(values, formData);

    if (values.startDate !== undefined) {
        formData.append('startDate', values.startDate.toUTCString());
    }
    if (values.endDate !== undefined) {
        formData.append('endDate', values.endDate.toUTCString());
    }
    if (values.fromDate !== undefined) {
        formData.append('fromDate', values.fromDate.toUTCString());
    }
    if (values.fromEndDate !== undefined) {
        formData.append('fromEndDate', values.fromEndDate.toUTCString());
    }

    return formData;
};

export const useChallenges = () => {
    const { callApi } = useApi();

    const allChallenges = async (queryParams?: ChallengesQueryOptions) => {
        const uri = buildUriWithParams('/challenge/v1', queryParams);

        const response = await callApi.current<ChallengePlainModel[]>(uri);

        return response;
    };

    const addChallenge = async (values: AddChallengeInputModel) => {
        const formData = getFormattedChallengeInput(values);

        const response = await callApi.current('/challenge/v1', {
            method: 'POST',
            body: formData
        });

        return response;
    };

    const editChallenge = async (values: EditChallengeInputModel) => {
        const formData = getFormattedChallengeInput(values);

        const response = await callApi.current('/challenge/v1', {
            method: 'PUT',
            body: formData
        });
        return response;
    };

    const setIsChallengeActive = async (values: ChallengePlainModel) => {
        const response = await callApi.current(`/challenge/v1/${values.isActive}`, {
            method: 'PUT'
        });
        return response;
    };

    const getChallenge = async (challengeId: string) => {
        const response = await callApi.current<ChallengeModel>(`/challenge/${challengeId}`);
        return response;
    };

    const removeChallenge = async (id: string) => {
        const response = await callApi.current(`/challenge/v1/${id}`, {
            method: 'DELETE'
        });
        return response;
    };

    const getPopularChallenges = async (inputmodel: PopularChallengesInputModel) => {
        const uri = buildUriWithParams('/challenge/v1/popular', inputmodel);

        const response = await callApi.current<PopularChallengesModel[]>(uri);

        return response;
    };

    return {
        allChallenges,
        addChallenge,
        editChallenge,
        getChallenge,
        removeChallenge,
        setIsChallengeActive,
        getPopularChallenges
    };
};
