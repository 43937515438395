import React, { FC, InputHTMLAttributes } from 'react';
import { Radio, HiddenRadio, Label, Wrapper } from './styles';

export interface FormRadioProps {
    label?: string | React.ReactNode;
}

const FormRadio: FC<InputHTMLAttributes<HTMLInputElement> & FormRadioProps> = ({ label, checked, ...props }) => {
    return (
        <Wrapper>
            <HiddenRadio type="radio" checked={checked} {...props} />
            <Radio checked={checked} />
            {label && <Label>{label}</Label>}
        </Wrapper>
    );
};

export default FormRadio;
