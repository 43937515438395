import styled from '@emotion/styled';

export const Input = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
`;

export const Label = styled.label`
    display: flex;
`;

export const Select = styled.span<{ whiteBackground?: boolean }>`
    background-color: ${({ theme, whiteBackground }) => (whiteBackground ? theme.colors.white : theme.colors.light)};
    border-radius: ${({ theme }) => `${theme.borderRadius.small}px`};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 2.5rem;
    display: inline-flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1rem;
    padding: 0 16px;
`;

export const Button = styled.div`
    height: 2.5rem;
    padding: 0 16px;
    border-radius: ${({ theme }) => `${theme.borderRadius.small}px`};
    font-size: 1rem;
    font-weight: 600;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

export const File = styled.div<{ isFocused: boolean }>`
    display: flex;
`;
