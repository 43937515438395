import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div<{ focus?: boolean }>`
    position: relative;
    border-radius: 24px;
    
    ${({ focus, theme }) => focus &&
        css` 
            outline: ${theme.colors.primary} solid 1px;
        `
    }
`;

export const DateIcon = styled.div`
    position: absolute;
    background-color: ${({ theme }) => theme.colors.background};
    width: 2.5rem;
    height: 2.5rem;
    right: 6px;
    top: 50%;
    pointer-events: none;
    transform: translateY(-50%);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme }) => theme.borderRadius.xl}px;
`;
