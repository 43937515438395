import React, { FC } from 'react';
import Icon from '../Icon';
import { Wrapper, Image, Label, Actions, FileName, FileSize, ActionButton } from './styles';

interface FileSingleProps {
    image?: string;
    name: string;
    size?: number;
    type: 'img' | 'doc';
    onDeleteHandler?: () => void;
}

const FileSingle: FC<FileSingleProps> = ({ image, name, size, type, onDeleteHandler }) => {
    return (
        <Wrapper>
            {image &&
                <Image src={image} />
            }
            <Label>
                <FileName>{name}</FileName>
                {size &&
                    <FileSize>{(size / 1024).toFixed(2)} kb</FileSize>
                }
            </Label>
            {onDeleteHandler &&
                <Actions>
                    <ActionButton onClick={onDeleteHandler}>
                        <Icon name="delete" />
                    </ActionButton>
                </Actions>
            }
        </Wrapper>
    );
};

export default FileSingle;
