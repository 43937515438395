import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Buttons = styled.div<{ stretch?: boolean; }>`
    display: flex;
    border: 1px solid ${({ theme }) => theme.colors.light};
    border-radius: ${({ theme }) => theme.borderRadius.normal + 4}px;
    padding: 2px;
    
    ${({ stretch }) =>
    stretch &&
    css`
        > button {
            flex: 1;
        }
    `}
`;
