import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FormTextarea = styled.textarea<{ darkBorder?: boolean }>`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.greys[50]};
    border-radius: ${({ theme }) => theme.form.height / 2}rem;
    padding: 1rem ${({ theme }) => theme.form.padding.horizontal}px;
    font-size: ${({ theme }) => theme.form.fontSize}rem;
    min-height: ${({ theme }) => theme.form.height}rem;
    line-height: ${({ theme }) => theme.form.lineHeight}rem;
    resize: vertical;

    &:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.greys[50]};
    }

    &:hover {
        border-color: ${({ theme }) => theme.greys[100]};
    }

    ::placeholder {
        color: ${({ theme }) => theme.greys[300]};
    }

    ${({ darkBorder, theme }) =>
        darkBorder &&
        css`
            border: 1px solid ${theme.greys[100]};

            &:hover {
                border-color: ${theme.greys[300]};
            }
        `}
`;
