import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Card } from 'components/atoms/card';
import { transparentize } from 'polished';

export const Wrapper = styled(Card)`
    position: relative;
`;

export const TableWrapper = styled.div`
    border-radius: ${({ theme }) => theme.borderRadius.large}px;
    display: block;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

export const THead = styled.thead`
    background-color: ${({ theme }) => transparentize('0.8', theme.colors.primary)};
`;

export const Th = styled.th`
    text-align: left;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 0.75rem;
    height: 62px;
    display: flex;
    align-items: center;

    &:first-of-type {
        padding-left: 16px;
        border-top-left-radius: ${({ theme }) => theme.borderRadius.normal}px;
    }

    &:last-of-type {
        padding-right: 16px;
        border-top-right-radius: ${({ theme }) => theme.borderRadius.normal}px;
    }
`;

export const Td = styled.td<{ isClick?: boolean, hideFooter?: boolean }>`
    color: ${({ theme }) => theme.colors.text};
    font-size: 0.875rem;
    line-height: 0.875rem;
    padding: 0 8px;
    // If hidefooter then disable border bottom
    ${({ hideFooter, theme }) => !hideFooter && `
        border-bottom: 1px solid ${theme.colors.border};
    `}
    display: flex;
    align-items: center;
    height: 68px;
    cursor: ${({ isClick }) => isClick ? 'pointer' : 'inherit'};

    &:first-of-type {
        padding-left: 16px;
    }

    &:last-of-type {
        padding-right: 16px;
    }
`;

export const Tr = styled.tr<{ oneven?: boolean, onclickAvailable?: boolean }>`
    margin: 0 24px;
    ${({ onclickAvailable }) =>
        onclickAvailable &&
        css`
            &:hover {
                cursor: pointer;
            }
        `
    }
`;

export const EmptyList = styled.tr`
    & td {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 680px;
    }
    
`;
