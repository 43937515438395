import { useField } from 'formik';
import React, { FC } from 'react';
import FormCheck, { FormCheckProps } from '..';

interface FormCheckFormikProps extends FormCheckProps {
    name: string;
}

const FormCheckFormik: FC<FormCheckFormikProps> = (props) => {
    const [field, meta] = useField(props);

    const error = !!(meta.touched && meta.error) ? meta.error : undefined;
    return <FormCheck {...field} {...props} error={error} checked={field.value} />;
};

export default FormCheckFormik;
