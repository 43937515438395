import Notification from 'components/atoms/Notification';
import React, { FC } from 'react';
import { Tab as TabItem, Tabs as Wrapper } from './styles';

export interface TabsProps {
    tabs: Tab[];
    current?: string | number;
    setCurrent: (val: string | number) => void;
}

export interface Tab {
    label: string;
    value: string | number;
    amount?: number;
    onClick?: (val: any) => void;
}

const Tabs: FC<TabsProps> = ({ tabs, current, setCurrent }) => {
    const onClickHandler = (val: string | number) => {
        setCurrent(val);
    };

    return (
        <Wrapper>
            {tabs.map((tab) =>
                <TabItem key={tab.value} onClick={() => { tab.onClick?.(tab.value); onClickHandler(tab.value); }} active={current === tab.value}>
                    {tab.label}
                    {tab.amount !== undefined &&
                        <Notification disabled={current !== tab.value}>{tab.amount}</Notification>
                    }
                </TabItem>
            )}
        </Wrapper>
    );
};

export default Tabs;
