import styled from '@emotion/styled';

export const Image = styled.img<{ isActive?: boolean }>`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: ${({ theme }) => theme.borderRadius.normal}px;
    border: ${({ theme, isActive }) => (isActive ? `3px solid${theme.colors.pink}` : 'none')};
    margin-bottom: 1rem;

    &:hover {
        cursor: pointer;
    }
`;

export const ImageWrapper = styled.div<{ isActive?: boolean }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.span`
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: 600;
`;

