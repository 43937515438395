import React, { ChangeEvent, FC, InputHTMLAttributes, useState } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import { Input, Label, Button, Select, File } from './styles';

export interface FormFileProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
    error?: string;
    value?: File;
    onChange?: (value: File) => void;
    whiteBackground?: boolean;
    className?: string;
    hasFile?: boolean;
}

const FormFile: FC<FormFileProps> = ({ error, value, onChange, whiteBackground, className, hasFile }) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const text = value ? value.name : hasFile ? 'Open huidig bestand' : 'Geen bestand gekozen';

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];

        if (file && onChange) {
            onChange(file);
        }
    };

    return (
        <>
            <Label className={className}>
                {/* multiple is false. If you want to you a multi upload, use <FormMultiForm/> (TODO) */}
                <>
                    <Input
                        type="file"
                        multiple={false}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        onChange={handleOnChange}
                    />
                    <File isFocused={isFocused}>
                        <Select whiteBackground={whiteBackground}>{text}</Select>
                        <Button>Bestand kiezen</Button>
                    </File>
                </>

            </Label>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormFile;
