import React, { FC, useState, useEffect } from 'react';
import Dropdown from '../Dropdown';
import { DropdownItemProps } from 'components/atoms/item/DropdownItem';
import { useGyms } from 'hooks/gyms';
import { useUser } from 'hooks/user';
import { ActiveEnvironmentEnum, useSession } from 'contexts/sessionContext';
import { defaultAreaRoute } from 'utils/areaHelper';
import Icon from 'components/atoms/Icon';
import Margin from 'components/atoms/Margin';
import { Selected } from '../AssociationDropdown/styles';
import { useNavigate } from 'react-router-dom';

export interface RoleDropdownProps { }

const GymDropdown: FC<RoleDropdownProps> = () => {
    const navigate = useNavigate();
    const [gyms, setGyms] = useState<DropdownItemProps[]>([]);
    const { session } = useSession();
    const { switchActiveGym, switchActiveEnvironment } = useUser();
    const { allPlainGyms } = useGyms();

    // Life cycle
    useEffect(() => {
        getGyms();
    }, []);

    // Methods
    const handleOnSubItemClick = async (value: string) => {
        const response = await switchActiveGym(value);

        if (response.ok && session?.role && session.activeEnvironment) {
            if (session?.activeEnvironment === ActiveEnvironmentEnum.Location) {
                switchActiveEnvironment(ActiveEnvironmentEnum.Gym);
            }

            const route = defaultAreaRoute(session.role, ActiveEnvironmentEnum.Gym);
            navigate(route);
        } else {
            // TODO: set prev value or something.
        }
    };

    const getGyms = async () => {
        const response = await allPlainGyms();

        if (response.ok && response.data) {
            const gyms: DropdownItemProps[] = response.data.map((gym) => ({
                title: gym.name,
                value: gym.id
            }))
                .sort((a, b) => a.title.localeCompare(b.title));

            setGyms(gyms);
        }
    };

    // Render.
    const activeGymId = session?.activeGymId;
    const selected = gyms.find((item) => item.value === activeGymId);

    return (
        <Dropdown items={gyms} onSubItemClick={(value) => handleOnSubItemClick(value as string)}>
            <Margin right={1} alignItems="center">
                <Icon name="company" />
            </Margin>
            <Selected>{selected?.title}</Selected>
        </Dropdown>
    );
};

export default GymDropdown;
