import { useTheme } from '@emotion/react';
import React, { FC } from 'react';
import { Icons } from 'static/theme/icons';
import Icon from '../Icon';
import { Wrapper, Letter } from './styles';

export interface FaceProps {
    image?: string;
    placeholderIcon?: keyof Icons;
    placeholderLetter?: string;
    placeholderColor?: string;
    round?: boolean;
    big?: boolean;
    small?: boolean;
    borderWidth?: number;
    borderColor?: string;
    className?: string;
}

const Face: FC<FaceProps> = ({ placeholderIcon, placeholderLetter, ...props }) => {
    const icon = placeholderIcon || 'person';
    const letter = placeholderLetter?.charAt(0);
    const theme = useTheme();

    return (
        <Wrapper {...props}>
            {!props.image && letter ? (
                <Letter>{letter}</Letter>
            ) : (
                !props.image && <Icon name={icon} color={theme.greys[300]} size={props.small ? 1 : 1.5} />
            )}
        </Wrapper>
    );
};

export default Face;
