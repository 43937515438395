import styled from '@emotion/styled';

export const Stepper = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    margin-bottom: 1.5rem;
`;

export const StepWrapper = styled.div`
    flex: 1;
`;
