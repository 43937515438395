import React, { FC, PropsWithChildren } from 'react';
import { Content, Row, Title } from './styles';

export interface FormRowProps {
    title: string;
}

const FormRow: FC<PropsWithChildren<FormRowProps>> = ({ title, children }) => {
    return (
        <Row>
            <Title>{title}</Title>
            <Content>{children}</Content>
        </Row>
    );
};

export default FormRow;
