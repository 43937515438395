import styled from '@emotion/styled';
import { Button } from 'components/atoms/button';

export const FormFileWrapper = styled.div`
    margin-bottom: 1rem;
    display: flex;
`;

export const FormFileRemove = styled(Button)`
    margin-left: 8px;
`;

export const FormFileAdd = styled(Button)`
    margin-left: 8px;
`;
