import styled from '@emotion/styled';
import Icon from 'components/atoms/Icon';

export const Wrapper = styled.div<{
    color?: string;
    icon?: boolean;
}>`
    border: none;
    height: 2.5rem;
    padding: 0 16px;
    border-radius: '1.25rem';
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    white-space: nowrap;
`;

export const StartIcon = styled(Icon)<{ noMargin?: boolean }>`
    margin-right: ${({ noMargin }) => (noMargin ? 0 : '16px')};
`;