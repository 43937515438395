import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Navigation = styled.div<{ open: boolean }>`
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: ${({ theme, open }) => (open ? theme.navigation.width : 100)}px;
    z-index: 101;
    transition: width 0.3s ease-in-out;
`;

export const Logo = styled.img`
    width: 100%;
    height: auto;
    max-width: 125px;
`;

export const Header = styled.div<{ open: boolean }>`
    height: ${({ theme }) => theme.header.height}rem;
    width: ${({ theme, open }) => (open ? theme.navigation.width : 26)}px;
    margin: 0 32px 1rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: width 0.3s ease-in-out;

    & ${Logo} {
        height: 30px;
        width: auto;
        margin-left: 0;
        transition: margin-left 0.3s ease-in-out;

        ${({ open }) =>
            !open &&
            css`
                margin-left: -98px;
            `}
    }
`;

export const Toggle = styled.button`
    position: absolute;
    top: 36px;
    right: -12px;
    width: 24px;
    height: 24px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 2px -1px 6px 0px ${({ theme }) => theme.colors.border};
    border: none;
    outline: none;
`;
