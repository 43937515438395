import Icon from 'components/atoms/Icon';
import styled from '@emotion/styled';

export const Dropdown = styled.div`
    display: flex;
    position: relative;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
`;

export const Toggle = styled(Icon)`
    margin-left: 8px;

    svg {
        fill: ${({ theme }) => theme.greys[100]};
    }

    &:hover {
        cursor: pointer;
        svg {
            fill: ${({ theme }) => theme.greys[300]};
        }
    }
`;

export const Selected = styled.span`
    font-weight: 500;
`;
