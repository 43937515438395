import React, { FC } from 'react';
import { NavItemHeader, HeaderItem } from './NavItemHeader';
import { NavItemProps } from './types';

export const NavItem: FC<NavItemProps> = (props) => {
    const { items = [] } = props;

    if (items.length > 1) {
        return <NavItemHeader item={props} />;
    }

    return (
        <HeaderItem
            customPadding={0.25}
            icon={props.icon}
            showIconOnly={!props.isMenuOpen}
            sub={false}
            title={props.title}
            to={props.to}
        />
    );
};
