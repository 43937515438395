import { useApi } from 'hooks/api';
import { AddTagGroupInputModel, EditTagGroupInputModel, TagGroupModel, TagGroupPlainModel } from './types';


export const useTagGroup = () => {
    const { callApi } = useApi();

    const allTagGroups = async () => {
        const response = await callApi.current<TagGroupPlainModel[]>('/TagGroup/v1/All');

        return response;
    };

    const addTagGroup = async (values: AddTagGroupInputModel) => {
        const response = await callApi.current('/TagGroup/v1', {
            method: 'POST',
            body: JSON.stringify(values)
        });

        return response;
    };

    const editTagGroup = async (values: EditTagGroupInputModel) => {
        const response = await callApi.current('/TagGroup/v1', {
            method: 'PUT',
            body: JSON.stringify(values)
        });
        return response;
    };

    const getTagGroup = async (id: string) => {
        const response = await callApi.current<TagGroupModel>(`/TagGroup/v1/${id}`);
        return response;
    };

    const removeTagGroup = async (id: string) => {
        const response = await callApi.current(`/TagGroup/v1/${id}`, {
            method: 'DELETE'
        });
        return response;
    };

    return {
        allTagGroups,
        addTagGroup,
        editTagGroup,
        getTagGroup,
        removeTagGroup
    };
};
