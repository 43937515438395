import Icon from 'components/atoms/Icon';
import React, { FC, DetailedHTMLProps } from 'react';
import theme from 'static/theme';
import { Icons } from 'static/theme/icons';
import * as styles from './styles';

type DefaultButtonProps = DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export type IconButtonProps = DefaultButtonProps & {
    color?: string;
    icon: keyof Icons;
}

const IconButton: FC<DefaultButtonProps & IconButtonProps> = (props) => {
    const {
        color = theme.colors.primary,
        icon,
        ...buttonProps
    } = props;

    return (
        <button
            css={styles.button}
            {...buttonProps}
        >
            {
                <Icon name={icon} color={color} size={1.5} />
            }
        </button>
    );
};

export default IconButton;
