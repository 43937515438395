import React, { FC, useState, useEffect } from 'react';
import { Header, Content } from './styles';
import Dropdown from 'components/molecules/dropdown';
import User from 'components/molecules/User';
import Margin from 'components/atoms/Margin';
import { UserProfileModel, useUser } from 'hooks/user';
import Group from 'components/molecules/Group';
import { useTranslation } from 'react-i18next';

export const AppHeader: FC = () => {
    const [profileInfo, setProfileInfo] = useState<UserProfileModel>();
    const { t, i18n } = useTranslation('common');
    const { currentProfileInfo } = useUser();
    const { logOut } = useUser();

    const fetchProfileInfo = async () => {
        const response = await currentProfileInfo();

        if (response.ok && response.data) {
            setProfileInfo(response.data);
        } else {
            // TODO
        }
    };

    const handleLogOut = async () => {
        const response = await logOut();
        if (response.ok) {
            window.location.reload();
        }
    };

    useEffect(() => {
        fetchProfileInfo();
    }, []);

    const profileName = profileInfo?.insertion
        ? `${profileInfo.firstName} ${profileInfo.insertion} ${profileInfo.lastName}`
        : `${profileInfo?.firstName} ${profileInfo?.lastName}`;

    const name = profileInfo ? profileName : undefined;

    return (
        <Header>
            <Content>
                <Group>
                    <Margin left={1}>
                        <Dropdown
                            items={[
                                {
                                    title: t('changeLanguage'),
                                    items: [
                                        {
                                            title: t('languageOptions.dutch'),
                                            onClick: () => i18n.changeLanguage('nl')
                                        },
                                        {
                                            title: t('languageOptions.english'),
                                            onClick: () => i18n.changeLanguage('en')
                                        }
                                    ]
                                },
                                {
                                    title: t('logOut'),
                                    onClick: () => handleLogOut()
                                }
                            ]}
                        >
                            <User name={name} />
                        </Dropdown>
                    </Margin>
                </Group>
            </Content>
        </Header>
    );
};

