import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div<{ background?: string; }>`
    cursor: pointer;
    height: 8rem;
    width: 100%;
    border: 2px dashed ${({ theme }) => theme.colors.border};
    border-radius: ${({ theme }) => theme.borderRadius.large}px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1rem;
    
  ${({ background }) =>
        background &&
        css`
            background-image: url('${background}');
            background-size: cover;
            background-position: center center;
        `}
    input {
        opacity: 0;
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    p {
        margin-bottom: 0;
        font-weight: 600;
        margin-top: .75rem;
        font-size: .9rem;
    }
`;

export const Text = styled.p<{ required?: boolean }>`

${({ required, theme }) =>
        required &&
        css`
            &:after {
                content: ' *';
                color: ${theme.colors.secondary};
                font-weight: bold;
            }
        `};
`;
