import { useApi } from 'hooks/api';
import { CommissionDeterminedProductModel } from 'hooks/commissionDeterminedProducts';
import { appendObject, appendObjectWithImagesArray } from 'utils/objectAppender';

export interface FitnessProductModel {
    id: string;
    name: string;
    description: string;
    banner?: string;
    purchasePrice: number;
    numberType?: string;
    productDuration: ProductDuration;
    productSalesUnit: ProductSalesUnitType;
    supplierId: string;
    btwPercentage: BtwPercentageType;
    images?: FitnessProductFileModel[];
}

export interface EditImageFitnessProductModel {
    id?: string;
    images?: FitnessProductFileModel[];
}

export interface FitnessProductPlainModel {
    id: string;
    banner?: string;
    name: string;
    description: string;
    numberType: string;
    price: number;
    unit: ProductSalesUnitType;
    duration: ProductDuration;
    newPurchaseCostSupplier?: number;
    commissionDeterminedProductModel?: CommissionDeterminedProductModel;
}

export enum BtwPercentageType {
    Percent6 = 0,
    Percent9 = 1,
    Percent21 = 2
}

export interface AddFitnessProductModel {
    name: string;
    description: string;
    images?: FitnessProductFileModel[];
    purchasePrice: number | string;
    numberType?: string;
    productDuration: ProductDuration;
    productSalesUnit: ProductSalesUnitType;
    supplierId: string;
    btwPercentage: BtwPercentageType;
}

export interface EditFitnessProductModel {
    id?: string;
    name: string;
    images?: FitnessProductFileModel[];
    description: string;
    supplierId: string;
    purchasePrice: number | string;
    numberType?: string;
    productDuration: ProductDuration;
    productSalesUnit: ProductSalesUnitType;
    btwPercentage: BtwPercentageType;
}

export interface FitnessProductFileModel {
    id?: string;
    image?: File;
    imageHash?: string;
    order: number;
    fileName?: string;
}

export interface SupplierFitnessProductModel {
    supplierId: string,
    supplierName: string,
    productId: string,
    productName: string,
    productPrice: number
}

export interface FitnessProductCommissionModel {
    supplierName: string;
    fitnessProductId: string;
    fitnessProductName: string;
    btwPercentage: number;
    numberType: string;
    purchaseCost: number;
    unit: ProductSalesUnitType;
    duration: ProductDuration;
    newPurchaseCostSupplier?: number;
    commissionDeterminedProductModel?: CommissionDeterminedProductModel;
}

export interface EditPriceFitnessProductModel {
    id?: string;
    newCost?: number;
}

export enum ProductSalesUnitType {
    Pieces = 0,
    Monthly = 1
}

export enum ProductDuration {
    NotNeeded = 0,
    Months12 = 1,
    Months24 = 2,
    Months36 = 3
}

export const useFitnessProduct = () => {
    const { callApi } = useApi();

    const getAllFitnessProductsEntreo = async () => {
        const response = await callApi.current<FitnessProductCommissionModel[]>('/FitnessProduct/v1/Commissions/All');
        return response;
    };

    const acceptFitnessProductPrice = async (id: string) => {
        const response = await callApi.current(`/FitnessProduct/v1/updatePrice/${id}`);
        return response;
    };

    const rejectFitnessProductPrice = async (id: string) => {
        const response = await callApi.current(`/FitnessProduct/v1/updatePrice/reject/${id}`);
        return response;
    };

    const getAllFitnessProductsSupplier = async (supplierId: string) => {
        const response = await callApi.current<FitnessProductPlainModel[]>(`/FitnessProduct/v1/Plain/All/${supplierId}`);
        return response;
    };

    const allProductsWithSupplier = async () => {
        const response = await callApi.current<SupplierFitnessProductModel[]>('/FitnessProduct/v1/AllWithProducts');
        return response;
    };

    const getFitnessProduct = async (fitnessProductId: string) => {
        const response = await callApi.current<FitnessProductModel>(`/FitnessProduct/v1/${fitnessProductId}`);
        return response;
    };

    const getEditableFitnessProduct = async (id: string) => {
        const response = await callApi.current<FitnessProductModel>(`/FitnessProduct/v1/Edit/${id}`);
        return response;
    };

    const addFitnessProduct = async (model: AddFitnessProductModel) => {
        const formData = new FormData();
        appendObject(model, formData);
        if (model.images !== undefined) {
            appendObjectWithImagesArray(model.images, formData, 'images');
        }

        const response = await callApi.current<AddFitnessProductModel>('/FitnessProduct/v1', {
            method: 'POST',
            body: formData
        });
        return response;
    };

    const editFitnessProduct = async (model: EditFitnessProductModel) => {
        const response = await callApi.current<AddFitnessProductModel>('/FitnessProduct/v1', {
            method: 'PUT',
            body: JSON.stringify(model)
        });
        return response;
    };

    const editImageFitnessProduct = async (model: EditFitnessProductModel) => {
        const formData = new FormData();
        const initialValues: EditImageFitnessProductModel = {
            id: model.id, 
            images: model.images
        };
        appendObject(initialValues, formData);
        if (model.images !== undefined) {
            appendObjectWithImagesArray(model.images, formData, 'images');
        }

        const response = await callApi.current<AddFitnessProductModel>('/FitnessProduct/v1/image', {
            method: 'PUT',
            body: formData
        });
        return response;
    };

    const deleteFitnessProduct = async (id: string) => {
        const response = await callApi.current(`/FitnessProduct/v1/${id}`, {
            method: 'DELETE'
        });
        return response;
    };

    return {
        getFitnessProduct,
        allProductsWithSupplier,
        getEditableFitnessProduct,
        addFitnessProduct,
        editFitnessProduct,
        editImageFitnessProduct,
        deleteFitnessProduct,
        getAllFitnessProductsSupplier,
        getAllFitnessProductsEntreo,
        acceptFitnessProductPrice,
        rejectFitnessProductPrice
    };
}; 