import { UserAuthorization, useUserAuthorizationContext } from 'contexts/userAuthorization';
import { useApi } from 'hooks/api';
import { Role as UserRole } from 'hooks/roles';
import { Claim, Role } from 'utils/constants';

const hasRole = (roleList: UserRole[], targetRole: Role) => !![...(roleList ?? [])].find(({ name }) => name === targetRole);

export const useUserAuthorization = () => {
    const { userAuthorization, setUserAuthorization } = useUserAuthorizationContext();
    const { callApi, refreshSession } = useApi();

    const getUserAuthorizationData = async () => {
        const sessionResponse = await refreshSession();

        if (sessionResponse?.ok) {
            const userId = sessionResponse?.data?.claims?.find((claim) => claim.type === Claim.UserId)?.values?.[0] ?? '';

            const authorizationResponse = await callApi.current<UserAuthorization | undefined>(`/Role/v1/${userId}`, {
                method: 'GET'
            });

            if (authorizationResponse?.ok) {
                const { permissions = [], roles = [] } = authorizationResponse?.data ?? {};

                setUserAuthorization((initial: UserAuthorization) => ({
                    ...initial,
                    permissions,
                    roles,
                    userId
                }));
            }

            return authorizationResponse;
        }
    };

    const isAdmin = hasRole(userAuthorization.roles, Role.Admin);
    const isProvider = hasRole(userAuthorization.roles, Role.Provider);

    return { isAdmin, isProvider, userAuthorization, getUserAuthorizationData };
};
