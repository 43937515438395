
export enum TagType {
    Recipe = 0,
    Workout = 1,
    Blog = 2,
    Product = 3,
    Subscription = 4
}

export interface AddTagGroupInputModel {
    description: string;
    type?: TagType;
    mandatory: boolean;
}

export interface EditTagGroupInputModel extends AddTagGroupInputModel {
    id?: string;
}

export interface TagGroupPlainModel extends Omit<EditTagGroupInputModel, 'mandatory'> {

}
export interface TagGroupModel extends EditTagGroupInputModel {

}
