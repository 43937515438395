import React, { useState } from 'react';
import useModal from '../modal';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormGroup, FormikFormControl } from 'components/atoms/form';
import { useTranslation } from 'react-i18next';
import Panel from 'components/organisms/Panel';
import Loading from 'components/atoms/Loading';

interface EmailInputProps {
    initialValues: EmailInputValues;
    onSubmit: (value: EmailInputValues, helpers: FormikHelpers<EmailInputValues>) => void;
    onRefresh?: () => void;
}

export interface EmailInputValues {
    email: string;
    recordId: string;
    phoneNumber?: string;
}

const useEmailInputModal = (emailInputProps: EmailInputProps): [() => void, () => void, boolean] => {
    const { t } = useTranslation('emailModal');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const initialValues: EmailInputValues = { ...emailInputProps.initialValues };

    const handleSubmit = async (values: EmailInputValues, helpers: FormikHelpers<EmailInputValues>) => {
        setIsLoading(true);
        emailInputProps.onSubmit(values, helpers);
        setIsLoading(false);
        hide();
    };

    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => {
            if (isLoading) {
                return (
                    <Panel onHide={hide} inProp={inProp} onExited={onExited} title={'invitations.button'}>
                        <Loading />
                    </Panel>
                );
            }

            return (
                <Formik<EmailInputValues> initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                    {({ handleSubmit, isSubmitting, touched, isValid }) => (
                        <Panel
                            onHide={hide}
                            inProp={inProp}
                            onExited={onExited}
                            title={t('title')}
                            onTouched={touched}
                            buttons={[
                                { title: t('common:button.cancel'), onClick: () => hide(), isOutline: true },
                                {
                                    title: t('common:button.add'),
                                    onClick: () => handleSubmit(),
                                    type: 'submit',
                                    disabled: isSubmitting || !isValid
                                }
                            ]}
                        >
                            <Form>
                                <FormGroup>
                                    <FormGroup label={t('email')} required />
                                    <FormikFormControl name="email" placeholder={t('email')} />
                                </FormGroup>
                                {'phoneNumber' in initialValues && (
                                    <FormGroup>
                                        <FormGroup label={t('phoneNumber')} required />
                                        <FormikFormControl name="phoneNumber" placeholder={t('phoneNumber')} />
                                    </FormGroup>
                                )}
                            </Form>
                        </Panel>
                    )}
                </Formik>
            );
        },
        [isLoading, initialValues]
    );

    return [show, hide, isShowing];
};
export default useEmailInputModal;
