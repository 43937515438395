import styled from '@emotion/styled';

export const Medal = styled.div`
    width: 3rem;
    height: 3rem;
    position: relative;
`;

export const Type = styled.div`
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: .25rem;
    left: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

