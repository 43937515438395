
export interface SubscriptionPlainModel {
    id?: string;
    name: string;
    description?: string;
    order?: number;
    bannerCdnHash?: string;
}

export interface SubscriptionAdminModel {
    id?: string;
    name: string;
    description?: string;
    order?: number;
    providerName: string;
    monthlyCosts: number;
    hasImage: boolean;
}

export interface GetSubscriptionsInputModel {
    providerId: string;
    tag?: SubscriptionTagConstants;
}

export enum SubscriptionTagConstants {
    IFit = 0,
    PersonalTraining = 1
}

export interface AddSubscriptionInputModel extends SubscriptionPlainModel {
    providerId: string;
    invoicePeriod?: InvoicePeriodType;
    monthlyCosts?: number | string;
    fourWeekCosts?: number | string;
    registrationCosts?: number | string;
    frequency?: string;
    durationInMonths?: number;
    inactive?: Date;
    startDate?: Date;
    subscriptionType: SubscriptionType;
    banner?: File
    bannerCdnHash?: string;
    tags: string[];
    products: string[];
}

export enum InvoicePeriodType {
    Monthly = 0,
    FourWeeks = 1
}
export enum SubscriptionType {
    Standard = 0,
    Information = 1,
    External = 2
}

export interface SubscriptionProviderDetailModel extends AddSubscriptionInputModel {
}

export type SubscriptionInputModel = AddSubscriptionInputModel | EditSubscriptionInputModel;

export interface EditSubscriptionInputModel extends SubscriptionProviderDetailModel { }
