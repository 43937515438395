export function appendObject(object: any, formData?: FormData, prefix?: string) {
    prefix = prefix ?? '';
    formData = formData ?? new FormData();
    for (const key in object) {
        if (object[key] !== undefined) {
            const keyString = prefix + key;
            switch (typeof (object[key])) {
                case 'number':
                    formData.append(keyString, object[key].toString());
                    break;
                case 'string':
                    formData.append(keyString, object[key]);
                    break;
                case 'boolean':
                    formData.append(keyString, object[key]);
                    break;
                case 'object':
                    break;
                default:
                    formData.append(keyString, object[key]);
                    break;
            }
        }
    }
    return formData;
}

export function appendObjectArray(objectsArray: any[], formData?: FormData, prefix?: string) {
    objectsArray.forEach((item, index) => {
        appendObject(item, formData, `${prefix}[${index}].`);
    });
}

export function appendGuidArray(guidArray: string[], formData?: FormData, prefix?: string) {
    formData = formData ?? new FormData();
    guidArray.forEach((item, index) => {
        formData?.append(`${prefix}[${index}]`, item);
    });
}

export function appendObjectWithImagesArray(objectsArray: any[], formData: FormData, prefix?: string) {
    objectsArray.forEach((item, index) => {
        appendObject(item, formData, `${prefix}[${index}].`);
        formData.append(`${prefix}[${index}].image`, item.image);
    });
}

export function enumSelector(definition: any) {
    return Object.keys(definition).slice(Object.keys(definition).length / 2, Object.keys(definition).length).map(key => ({ value: definition[key], title: key }));
}
export function interfaceSelector(definition: any) {
    return Object.keys(definition).map(i => ({ title: definition[i], value: i }));
}

export const appendToFormData = (data: any, formData: FormData, parentKey = '') => {
    switch (typeof data) {
        case 'number':
            formData.append(parentKey, data.toString());
            break;
        case 'boolean':
            formData.append(parentKey, data.toString());
            break;
        case 'string':
            formData.append(parentKey, data);
            break;
        case 'object':
            if (data == null) {
                // Do not add a null value
            } else if (data instanceof Date) {
                formData.append(parentKey, data.toISOString());
            } else if (data instanceof File) {
                formData.append(parentKey, data, data.name);
            } else if (Array.isArray(data)) {
                data.forEach((item, index) => {
                    appendToFormData(formData, item, `${parentKey}[${index}]`);
                });
            } else {
                Object.keys(data).forEach((key) => {
                    const newKey = parentKey === ''
                        ? key
                        : `${parentKey}.${key}`;
                    appendToFormData(formData, data[key], newKey);
                });
            }
            break;
    }

    return formData;
};
