import { useField } from 'formik';
import React, { FC, useCallback, useMemo } from 'react';
import FormSelect, { FormSelectProps, IOption } from '..';
import { Options } from 'react-select';

interface FormikFormSelectProps extends FormSelectProps {
    name: string;
    customOnChange?: (value: string | number | (string | number)[] | undefined) => void;
}

const FormikFormSelect: FC<FormikFormSelectProps> = ({ name, customOnChange, options, ...props }) => {
    const [field, meta, helpers] = useField(name);

    // FormikFormSelect's methods.
    const handleOnChange = useCallback((option: IOption | Options<IOption> | null) => {
        const value = option instanceof Array ? option.map((o) => o.value) : option?.value;
        helpers.setValue(value);
        customOnChange?.(value);

    }, [name, customOnChange]);


    // Render.
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    const value = useMemo(() => props.isMulti ? options?.filter((option) => field.value?.indexOf(option.value) >= 0) : options?.find((option) => option.value === field.value), [field.value, options, props.isMulti]);
    return (
        <FormSelect
            {...field}
            {...props}
            value={value}
            onChange={handleOnChange}
            onMenuOpen={() => helpers.setTouched(false)}
            onMenuClose={() => helpers.setTouched(true)}
            onBlur={() => helpers.setTouched(true)}
            error={error}
            options={options}
        />
    );
};

export default FormikFormSelect;
