import styled from '@emotion/styled';
import { rgba } from 'polished';

export const Stepper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    height: 3.5rem;
    border: 1px solid ${({ theme }) => rgba(theme.colors.primary, 0.1)};
    border-radius: 1.75rem;
    overflow: hidden;
`;

export const StepWrapper = styled.div`
    flex: 1;
`;

