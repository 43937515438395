import styled from '@emotion/styled';
import { rgba } from 'polished';

export const Header = styled.div`
    position: fixed;
    z-index: 2;
    width: 100%;
    top: 0;
    right: 0;
    display: flex;
    z-index: 100;
    justify-content: flex-end;

    align-items: center;
`;

export const Left = styled.div`
    display: inline-flex;
    img {
        width: 100%;
        height: auto;
        max-width: 125px;
    }
`;

export const Content = styled.div`
    display: inline-flex;
    height: ${({ theme }) => theme.header.height}rem;
    background-color: ${({ theme }) => rgba(theme.colors.white, 0.5)};
    padding: 1rem ${({ theme }) => theme.header.padding.horizontal}px;
    border-bottom-left-radius: 40px;
    backdrop-filter: blur(35px);
    align-items: center;
    
    & > *:not(:last-of-type) {
        height: 1.5rem;
        padding-right: 1.5rem;
        border-right: 1px solid ${({ theme }) => theme.colors.border};

        & h4 {
            display: flex;
            justify-content: center;
            gap: 5px;
        }
    }
`;

