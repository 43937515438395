import styled from '@emotion/styled';

interface CardBodyProps {}

const CardBody = styled.div<CardBodyProps>`
    padding: ${({ theme }) => theme.card.padding.horizontal}px;
    flex: 1;
    position: relative;
    display: inline-flex;
    flex-direction: column;
`;

export default CardBody;
