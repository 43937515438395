import React, { FC } from 'react';
import { Loading as Wrapper, Text, Balls, Ball } from './styles';

interface LoadingProps {
    isFull?: boolean;
}

const Loading: FC<LoadingProps> = ({ isFull }) => {
    return (
        <Wrapper isFull={isFull}>
            <Balls>
                <Ball />
                <Ball />
                <Ball isLast />
            </Balls>
            <Text>Een moment geduld</Text>
        </Wrapper>
    );
};

export default Loading;
