import { darken, lighten } from 'polished';
import brand from './brand';

export interface Colors {
    background: string;
    white: string;
    black: string;
    border: string;
    light: string;
    primary: string;
    disabled: string;
    medium: string;
    text: string;
    green: string;
    orange: string;
    red: string;
    grey: string;

    // Old or needs to be checked:
    secondary: string;
    primary_hover: string;
    secondary_hover: string;
    yellow: string;
    lightYellow: string;
    blue: string;
    darkBlue: string;
    cyan: string;
    darkCyan: string;
    darkerCyan: string;
    mint: string;
    dark: string;
    lightRed: string;
    turquoise: string;
    lightGreen: string;
    placeholder: string;
    footer: string;
    pink: string;
}

const colors: Colors = {
    primary: brand.blue,
    background: '#E5EAFC',
    white: '#FFFFFF',
    black: '#000000',
    border: '#E0E0E1',
    light: '#E8EAF1',
    dark: '#1C1C1C',
    text: '#131523',
    medium: '#C4CCEA',
    green: '#72C73C',
    disabled: '#B5B5B5',
    orange: '#F6AE2F',
    red: '#F65B2D',
    grey: '#919191',
    pink: '#ED6594',
    placeholder: '#B2B2B2',

    // Old or needs to be checked:
    secondary: brand.yellow,
    primary_hover: lighten(0.075, brand.blue), // TODO: def remove this one. use util .. for darkening colors.
    secondary_hover: lighten(0.075, brand.yellow), // TODO: def remove this one. use util .. for darkening colors.
    yellow: brand.yellow,
    lightYellow: '#FEEBC9',
    blue: brand.blue,
    darkBlue: '#2D286F',
    cyan: '#E6F3F5',
    darkCyan: '#D6E4E8',
    darkerCyan: darken(0.025, '#D6E4E8'), // TODO: def remove this one. use util .. for darkening colors.
    mint: '#4FAD76',
    lightRed: '#FBC9C9',
    turquoise: '#70A9A1',
    lightGreen: '#CFF6DF',
    footer: '#D6E4E8'
};

export default colors;
