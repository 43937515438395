import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';

export const Card = styled.div`
    padding: ${({ theme }) => theme.card.padding.horizontal}px;
`;

export const Row = styled(Skeleton)`
    margin-bottom: 16px;
`;
