import moment from 'moment';
import 'moment/locale/nl';

moment.locale('nl');


export interface TimeSpan {
    hours: number;
    minutes: number;
}

function fixedSizeNumber(
    value?: number
) {
    if (!value) {
        return '00';
    }
    if (value > 9) {
        return `${value}`;
    }
    return `0${value}`;
}

export function formatShortDate(
    value: moment.MomentInput,
    parseFormat?: moment.MomentFormatSpecification,
    format = 'DD-MM-YYYY'
) {
    return moment(value, parseFormat).format(format);
}

export function formatLongDate(
    value: moment.MomentInput,
    parseFormat?: moment.MomentFormatSpecification,
    format = 'DD MMMM YYYY'
) {
    return moment(value, parseFormat).format(format);
}

export function formatTimeSpan(
    value?: number
) {
    if (!value) {
        return '00:00';
    }

    const hours = fixedSizeNumber(Math.floor(value));
    const minutes = fixedSizeNumber(Math.round((value % 1) * 60));
    return `${hours}:${minutes}`;
}

export function formatDateAndTime(
    value: moment.MomentInput,
    parseFormat?: moment.MomentFormatSpecification,
    format = 'DD MMMM YYYY - HH:mm:ss'
) {
    return moment(value, parseFormat).format(format);
}

export function formatDate(value: moment.MomentInput, format: string) {
    return moment(value).format(format);
}

export function dateSortValue(
    value?: string
) {
    if (!value) {
        return 0;
    }
    return moment(value).unix();
}

export const timeStringToMoment = (time: string) => {
    const timeRegex = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$');
    const isTime = time.match(timeRegex);
    if (!isTime) {
        return;
    }
    const now = moment();

    const [hours, minutes] = time.split(':');
    now.set('hours', Number(hours));
    now.set('minutes', Number(minutes));
    return now;
};

export const timeStringToDate = (time: string) => {
    const now = timeStringToMoment(time);

    if (now == null) {
        return;
    }

    return now.toDate();
};

export const timeStringToDateTimeFormat = (time: string) => {
    const now = timeStringToMoment(time);

    if (now == null) {
        return;
    }

    return now.format('YYYY-MM-DDTHH:mm');
};

export const timeSpanToTimeFormat = (time?: TimeSpan) => {
    if (time == null) {
        return undefined;
    }
    const timeString = `${time.hours}:${time.minutes}`;
    const now = timeStringToMoment(timeString);
    if (now == null) {
        return;
    }
    return now.format('HH:mm');
};

export const timeStringToSecondsFormat = (time: string) => {
    const now = timeStringToMoment(time);
    if (now == null) {
        return;
    }
    const hours = now.format('HH');
    const minutes = now.format('mm');
    const seconds = Number(hours) * 3600 + Number(minutes) * 60;
    return seconds;
};

export const daysToWeeksOrMonthsFormat = (days: number) => {
    if (days % 30 === 0) {
        const duration = days / 30;
        return `${duration} ${duration > 1 ? 'maanden' : 'maand'}`;
    } else if (days % 7 === 0) {
        const duration = days / 7;
        return `${duration} ${duration > 1 ? 'weken' : 'week'}`;
    } else {
        return `${days} ${days > 1 ? 'dagen' : 'dag'}`;
    }
};

export const formatDateTimeString = (date: Date | string) => moment(date).format('YYYY-MM-DDTHH:mm');
