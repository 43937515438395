export interface BorderRadius {
    small: number;
    normal: number;
    large: number;
    xl: number;
}

const borderRadius: BorderRadius = {
    small: 4,
    normal: 8,
    large: 12,
    xl: 24
};

export default borderRadius;
