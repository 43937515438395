import { useUserAuthorizationContext } from 'contexts/userAuthorization';
import { UserPermissions } from 'permissions';

export const useSubscriptionsPermissions = () => {
    const { hasPermissions } = useUserAuthorizationContext();

    const [hasEditPermission, hasDeletePermission] = hasPermissions([
        UserPermissions.ConfigurationSubscriptionUpdate,
        UserPermissions.ConfigurationSubscriptionDelete
    ]);

    return {
        hasEditPermission,
        hasDeletePermission
    };
};
