import { useField } from 'formik';
import React, { FC } from 'react';
import FormControl, { FormStatisticProps } from '..';

interface FormikFormStatisticProps extends FormStatisticProps {
    name: string;
    label: string;
}

const FormikFormStatistic: FC<FormikFormStatisticProps> = (props) => {
    const [field, meta, helpers] = useField(props.name);

    // Methods.
    const handleOnChange = (value: string) => {
        helpers.setValue(value);
    };

    // Render.
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    return <FormControl {...field} {...props} label={props.label} onChange={handleOnChange} error={error} />;
};

export default FormikFormStatistic;
