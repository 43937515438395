import styled from '@emotion/styled';

export const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: ${({ theme }) => theme.form.height}rem;
    border: 1px solid ${({ theme }) => theme.greys[50]};
    border-radius: ${({ theme }) => theme.form.height / 2}rem;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0 ${({ theme }) => theme.form.padding.horizontal}px;
    overflow: hidden;

    &:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.greys[50]};
    }

    &:hover {
        border-color: ${({ theme }) => theme.greys[100]};
    }

    & .icon-wrapper {
        padding-right: 10px;
    }
`;

export const FormControl = styled.input`
    flex: 1 1 auto;
    font-size: ${({ theme }) => theme.form.fontSize}rem;
    border: none;
    height: 100%;

    ::placeholder {
        color: ${({ theme }) => theme.greys[300]};
    }

    &:focus-visible {
        outline: none;
    }
`;

