import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Step = styled.div<{ active?: boolean; disabled?: boolean }>`
    display: flex;
    align-items: center;
    margin-right: 1rem;
    padding: 1.35rem;
    position: relative;

    ${({ disabled }) =>
        !disabled &&
        css`
            &:hover {
                cursor: pointer;
            }
        `}
    ${({ active, theme }) =>
        active &&
        css`
            &:after {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                bottom: -1.5px;
                height: 3px;
                display: block;
                background-color: ${theme.colors.primary};
            }

        `}
`;

export const Number = styled.div<{ active?: boolean; disabled?: boolean }>`
    width: 1.75rem;
    height: 1.75rem;
    flex-shrink: 0;
    background-color: ${({ theme, active, disabled }) => (active ? theme.colors.primary : disabled ? theme.colors.disabled : theme.colors.primary)};
    transition: background 0.4s ease;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    font-size: 0.875rem;
    font-weight: 600;
    margin-right: .5rem;
`;

export const Content = styled.div`
    display: inline-flex;
    flex-direction: column;
`;

export const Title = styled.span<{ active?: boolean }>`
    font-size: 0.875rem;
    line-height: 1;
    font-weight: 600;
    color: ${({ theme, active }) => (active ? theme.colors.primary : theme.colors.disabled)};
`;

export const Subtitle = styled.span`
    color: ${({ theme }) => theme.colors.disabled};
    font-size: 0.875rem;
    margin-top: 0.25rem;
    line-height: 0.875rem;
`;
