import React, { forwardRef, PropsWithChildren } from 'react';
import { DropdownIcon, DropdownItem as Wrapper, Title, NestedIcon } from './styles';
import { Icons } from 'static/theme/icons';
import { useNavigate } from 'react-router-dom';
import greys from 'static/theme/greys';

export interface DropdownItemProps {
    value?: string | number | boolean;
    title: string;
    onClick?: () => void;
    to?: string;
    icon?: keyof Icons;
    items?: DropdownItemProps[];
    onSubItemClick?: (value: string | number | boolean) => void;
    access?: boolean;
    nested?: boolean;
    close?: () => void;
}

const DropdownItem = forwardRef<HTMLLIElement, PropsWithChildren<DropdownItemProps>>(({ value, title, onClick, close, to, icon, children, onSubItemClick, nested, ...props }, ref) => {
    const navigate = useNavigate();

    // DropdownItem's methods.
    const handleOnClick = () => {
        if (to) {
            navigate(to);
        } else if (onClick) {
            onClick();
        }

        if (value != null && onSubItemClick) {
            onSubItemClick(value);
        }
        if (close) {
            close();
        }
    };

    // Render.
    return (
        <Wrapper ref={ref} onClick={handleOnClick} {...props}>
            {icon && <DropdownIcon name={icon} color={greys[300]} />}
            <Title>{title}</Title>
            {nested && <NestedIcon name="chevron-right" color={greys[100]} />}
            {children}
        </Wrapper>
    );
});

export default DropdownItem;
