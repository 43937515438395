import styled from '@emotion/styled';

export const Footer = styled.div`
    height: 90px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Text = styled.span`
    color: ${({ theme }) => theme.colors.grey};
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
`;
