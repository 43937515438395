import React, { FC } from 'react';
import { useUserAuthorization } from 'hooks/authorization/useUserAuthorization';
import { UseSelectedSupplierReturn } from './useSelectedSupplier';
import { CustomSelect, CustomSelectOption, CustomSelectSingleValue, CustomSelectValue } from 'components/atoms/Select';

export type SupplierDropdownProps = UseSelectedSupplierReturn & {
    isAdmin: boolean;
};

const Dropdown: FC<SupplierDropdownProps> = ({ suppliers = [], selectedSupplier, handleSupplierChange }: SupplierDropdownProps) => (
    <CustomSelect
        isLight
        options={suppliers.map((supplier) => ({
            label: supplier.fullName,
            value: supplier.id
        }))}
        onChange={(selected: CustomSelectValue) => handleSupplierChange((selected as CustomSelectSingleValue)?.value as string, suppliers)}
        value={{ value: selectedSupplier?.id, label: selectedSupplier?.fullName } as CustomSelectOption}
    />
);

export const SupplierDropdown: FC<Omit<SupplierDropdownProps, 'isAdmin'>> = (props) => {
    const { isAdmin } = useUserAuthorization();

    return isAdmin ? <Dropdown {...props} isAdmin={isAdmin} /> : null;
};
