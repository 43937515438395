import { useUserAuthorizationContext } from 'contexts/userAuthorization';
import { UserPermissions } from 'permissions';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getAuthorizedMenuItems, getMenuPaths } from './helpers';
import { NavItemProps } from 'components/atoms/NavigationMenuItem/types';

type GetMenuItemsProps = {
    translate: (text: string) => string;
    hasPermissions: (permissions: UserPermissions[]) => boolean[];
};

const getDashboardItems = ({ translate, hasPermissions }: GetMenuItemsProps): NavItemProps[] => {
    const [hasDashboardEntreoRead, hasDashboardEmployersRead, hasDashboardProvidersRead] = hasPermissions([
        UserPermissions.DashboardEntreoRead,
        UserPermissions.DashboardEmployersRead,
        UserPermissions.DashboardProvidersRead
    ]);

    return [
        {
            title: translate('dashboard'),
            icon: 'entreo_dashboard',
            to: '/dashboard/entreo',
            hasPermission: hasDashboardEntreoRead || hasDashboardEmployersRead || hasDashboardProvidersRead,
            items: [/*
                {
                    title: translate('viewForEntreo'),
                    icon: 'view_for_entreo',
                    to: '/dashboard/entreo',
                    hasPermission: hasDashboardEntreoRead,
                },*/
                {
                    title: translate('viewForEmployers'),
                    icon: 'view_for_employers',
                    to: '/dashboard/employers',
                    hasPermission: hasDashboardEmployersRead
                }/*,
                {
                    title: translate('viewForProviders'),
                    icon: 'view_for_providers',
                    to: '/dashboard/providers',
                    hasPermission: hasDashboardProvidersRead
                }*/
            ]
        }
    ];
};

const getAuthItems = ({ translate, hasPermissions }: GetMenuItemsProps): NavItemProps[] => {
    const [hasAuthorizationUsersRead, hasAuthorizationRolesRead, hasAuthorizationInvitationsRead, hasAuthorizationAdminRead] =
        hasPermissions([
            UserPermissions.AuthorizationUsersRead,
            UserPermissions.AuthorizationRolesRead,
            UserPermissions.AuthorizationInvitationsRead,
            UserPermissions.AuthorizationAdminRead
        ]);

    return [
        {
            title: translate('authorization'),
            icon: 'authorization',
            to: '/authorization/users',
            hasPermission:
                hasAuthorizationUsersRead || hasAuthorizationRolesRead || hasAuthorizationInvitationsRead || hasAuthorizationAdminRead,
            items: [
                {
                    title: translate('users'),
                    icon: 'users',
                    to: '/authorization/users',
                    hasPermission: hasAuthorizationUsersRead
                },
                {
                    title: translate('permissions'),
                    icon: 'roles_permissions',
                    to: '/authorization/permissions',
                    hasPermission: hasAuthorizationRolesRead
                },
                {
                    title: translate('sendInvitations'),
                    icon: 'send_email',
                    to: '/authorization/invitations',
                    hasPermission: hasAuthorizationInvitationsRead
                },
                {
                    title: translate('admins'),
                    icon: 'admin',
                    to: '/authorization/admins',
                    hasPermission: hasAuthorizationAdminRead
                },
                {
                    title: translate('adminControls'),
                    icon: 'admin',
                    to: '/authorization/adminControls',
                    hasPermission: hasAuthorizationAdminRead
                }
            ]
        }
    ];
};

const getFinanceItems = ({ translate, hasPermissions }: GetMenuItemsProps): NavItemProps[] => {
    const [hasFinancePaymentsRead, hasFinanceDeclarationsRead, hasFinanceEmployerLicenseRead, hasFinanceLicenseRead, hasFinanceBfaRead] =
        hasPermissions([
            UserPermissions.FinancePaymentsRead,
            UserPermissions.FinanceDeclarationsRead,
            UserPermissions.FinanceEmployerLicenseRead,
            UserPermissions.FinanceLicenseRead,
            UserPermissions.FinanceBfaRead
        ]);

    return [
        {
            title: translate('finance'),
            icon: 'finance',
            to: '/finance/payments',
            hasPermission:
                hasFinancePaymentsRead ||
                hasFinanceDeclarationsRead ||
                hasFinanceEmployerLicenseRead ||
                hasFinanceLicenseRead ||
                hasFinanceBfaRead,
            items: [
                /*{
                    title: translate('payments'),
                    icon: 'manage_payments',
                    to: '/finance/payments',
                    hasPermission: hasFinancePaymentsRead
                },*/
                {
                    title: translate('declarations'),
                    icon: 'declarations',
                    to: '/finance/declarations',
                    hasPermission: hasFinanceDeclarationsRead
                }, /*,
                {
                    title: translate('employerLicenseFee'),
                    icon: 'employer_license_fee',
                    to: '/finance/manage-employer-license-fee',
                    hasPermission: hasFinanceEmployerLicenseRead
                },*//*
                {
                    title: translate('linceseFees'),
                    icon: 'license_fee',
                    to: '/finance/manage-license-fee',
                    hasPermission: hasFinanceLicenseRead
                },*//*
                {
                    title: translate('viewBFAInvoices'),
                    icon: 'bfa',
                    to: '/finance/invoices',
                    hasPermission: hasFinanceBfaRead
                }*/
                /*{
                    title: translate('transactions'),
                    icon: 'view_for_employers',
                    to: '/finance/transactionsOverview',
                    hasPermission: hasFinanceDeclarationsRead
                },
                {
                    title: translate('transactions2'),
                    icon: 'view_for_employers',
                    to: '/finance/transactionsOverviewTwo',
                    hasPermission: hasFinanceDeclarationsRead
                }*/
            ]
        }
    ];
};

const getEmployerItems = ({ translate, hasPermissions }: GetMenuItemsProps): NavItemProps[] => {
    const [hasEmployersUsersRead, hasEmployersChallengesRead, hasEmployersEmployersRead] = hasPermissions([
        UserPermissions.EmployersUsersRead,
        UserPermissions.EmployersChallengesRead,
        UserPermissions.EmployersEmployersRead
    ]);

    return [
        {
            title: translate('employers'),
            icon: 'employers',
            to: '/employers/manage-users',
            hasPermission: hasEmployersUsersRead || hasEmployersChallengesRead || hasEmployersEmployersRead,
            items: [
                {
                    title: translate('employers'),
                    icon: 'employers',
                    to: '/employers/employers',
                    hasPermission: hasEmployersEmployersRead
                },
                {
                    title: translate('employees'),
                    icon: 'manage_users',
                    to: '/employers/manage-users',
                    hasPermission: hasEmployersUsersRead
                },
                {
                    title: translate('challenges'),
                    icon: 'employers_challengess',
                    to: '/employers/manage-challenges',
                    hasPermission: hasEmployersChallengesRead
                },
                {
                    title: translate('settings'),
                    icon: 'settings',
                    to: '/employers/settings',
                    hasPermission: hasEmployersChallengesRead
                }
            ]
        }
    ];
};

const getProvidersItems = ({ translate, hasPermissions }: GetMenuItemsProps): NavItemProps[] => {
    const [
        hasProvidersEmployeesRead,
        hasProvidersRead,
        hasProvidersChallengesRead,
        hasProvidersSubscriptionsRead,
        hasProvidersEmployersRead,
        hasProvidersEmployersChallengesRead,
        hasProvidersOrdersRead
    ] = hasPermissions([
        UserPermissions.ProvidersEmployeesRead,
        UserPermissions.ProvidersRead,
        UserPermissions.ProvidersChallengesRead,
        UserPermissions.ProvidersSubscriptionsRead,
        UserPermissions.ProvidersEmployersRead,
        UserPermissions.ProvidersEmployersChallengesRead,
        UserPermissions.ProvidersOrdersRead
    ]);

    return [
        {
            title: translate('vitality'),
            icon: 'providers',
            to: '/providers/manage-employees',
            hasPermission:
                hasProvidersEmployeesRead ||
                hasProvidersRead ||
                hasProvidersChallengesRead ||
                hasProvidersSubscriptionsRead ||
                hasProvidersEmployersRead ||
                hasProvidersEmployersChallengesRead,
            items: [
                {
                    title: translate('customers'),
                    icon: 'manage_employers',
                    to: '/providers/manage-employers',
                    hasPermission: hasProvidersEmployersRead
                },
                {
                    title: translate('employees'),
                    icon: 'manage_providers_employees',
                    to: '/providers/manage-employees',
                    hasPermission: hasProvidersEmployeesRead
                },
                {
                    title: translate('providers'),
                    icon: 'manage_providers',
                    to: '/providers/manage-providers',
                    hasPermission: hasProvidersRead
                }/*
                {
                    title: translate('providersChallenges'),
                    icon: 'manage_providers_challenges',
                    to: '/providers/manage-challenges',
                    hasPermission: hasProvidersChallengesRead
                },*/,
                {
                    title: translate('subscriptions'),
                    icon: 'manage_subscriptions',
                    to: '/providers/manage-subscriptions',
                    hasPermission: hasProvidersSubscriptionsRead
                },
                {
                    title: translate('employersChallenges'),
                    icon: 'manage_employers_challenges',
                    to: '/providers/manage-employers-challenges',
                    hasPermission: hasProvidersEmployersChallengesRead
                },
                {
                    title: translate('orders'),
                    icon: 'manage_orders',
                    to: '/providers/manage-orders',
                    hasPermission: hasProvidersOrdersRead
                }
            ]
        }
    ];
};

const getChallengesItems = ({ translate, hasPermissions }: GetMenuItemsProps): NavItemProps[] => {
    const [hasChallengesTemplatesRead, hasChallengesApproveRead] = hasPermissions([
        UserPermissions.ChallengesTemplatesRead,
        UserPermissions.ChallengesApproveRead
    ]);

    return [
        {
            title: translate('challenges'),
            icon: 'challenges',
            to: '/challenges/manage-challenge-templates',
            hasPermission: hasChallengesTemplatesRead || hasChallengesApproveRead,
            items: [
                {
                    title: translate('challengeTemplates'),
                    icon: 'manage_challenges_template',
                    to: '/challenges/manage-challenge-templates',
                    hasPermission: hasChallengesTemplatesRead
                }/*,
                {
                    title: translate('approveChallenges'),
                    icon: 'approve_challenges',
                    to: '/challenges/approve-challenges',
                    hasPermission: hasChallengesApproveRead
                }*/
            ]
        }
    ];
};

const getProductItems = ({ translate, hasPermissions }: GetMenuItemsProps): NavItemProps[] => {
    const [hasProductsRead, hasProductsSuppliersRead, hasProductsSupplierProductsRead] = hasPermissions([
        UserPermissions.ProductsRead,
        UserPermissions.ProductsSuppliersRead,
        UserPermissions.ProductsSupplierProductsRead
    ]);

    return [
        {
            title: translate('products'),
            icon: 'products',
            to: '/products/create-and-manage-products',
            hasPermission: hasProductsRead || hasProductsSuppliersRead || hasProductsSupplierProductsRead,
            items: [
                {
                    title: translate('products'),
                    icon: 'supplier_products',
                    to: '/products/overview-supplier-products',
                    hasPermission: hasProductsSupplierProductsRead
                },
                {
                    title: translate('suppliers'),
                    icon: 'manage_suppliers',
                    to: '/products/manage-suppliers',
                    hasPermission: hasProductsSuppliersRead
                },
                {
                    title: translate('marges'),
                    icon: 'product_services',
                    to: '/products/create-and-manage-products',
                    hasPermission: hasProductsRead
                }

            ]
        }
    ];
};

const getContentItems = ({ translate, hasPermissions }: GetMenuItemsProps): NavItemProps[] => {
    const [hasContentBlogRead, hasContentRecipesRead, hasContentImagesRead, hasContentContentCreatorsRead] = hasPermissions([
        UserPermissions.ContentBlogRead,
        UserPermissions.ContentRecipesRead,
        UserPermissions.ContentImagesRead,
        UserPermissions.ContentContentCreatorsRead
    ]);

    return [
        {
            title: translate('content'),
            icon: 'content',
            to: '/content/blog',
            hasPermission: hasContentBlogRead || hasContentRecipesRead || hasContentImagesRead || hasContentContentCreatorsRead,
            items: [
                {
                    title: translate('blog'),
                    icon: 'blog',
                    to: '/content/blog',
                    hasPermission: hasContentBlogRead
                },
                {
                    title: translate('recipes'),
                    icon: 'recipes',
                    to: '/content/recipes',
                    hasPermission: hasContentRecipesRead
                },
                {
                    title: translate('images'),
                    icon: 'images',
                    to: '/content/images',
                    hasPermission: hasContentImagesRead
                },
                {
                    title: translate('contentCreators'),
                    icon: 'content',
                    to: '/content/content-creators',
                    hasPermission: hasContentContentCreatorsRead
                }
            ]
        }
    ];
};

const getStoreItems = ({ translate, hasPermissions }: GetMenuItemsProps): NavItemProps[] => {
    const [hasStoreProductsRead] = hasPermissions([UserPermissions.StoreProductsRead]);

    return [
        {
            title: translate('store'),
            icon: 'products_services',
            to: '/store/products-and-services',
            hasPermission: hasStoreProductsRead,
            items: [
                {
                    title: translate('store'),
                    icon: 'products_services',
                    to: '/store/products-and-services',
                    hasPermission: hasStoreProductsRead
                }
            ]
        }
    ];
};

const getConfigurationItems = ({ translate, hasPermissions }: GetMenuItemsProps): NavItemProps[] => {
    const [hasConfigurationTagsRead, hasConfigurationCategoriesAndProductsRead, hasConfigurationSubscriptionRead] = hasPermissions([
        UserPermissions.ConfigurationTagsRead,
        UserPermissions.ConfigurationCategoriesAndProductsRead,
        UserPermissions.ConfigurationSubscriptionRead
    ]);

    return [
        {
            title: translate('configuration'),
            icon: 'configuration',
            to: '/configuration/tags-and-tag-groups',
            hasPermission: hasConfigurationTagsRead || hasConfigurationCategoriesAndProductsRead || hasConfigurationSubscriptionRead,
            items: [
                {
                    title: translate('tagsAndTagGroups'),
                    icon: 'tags',
                    to: '/configuration/tags-and-tag-groups',
                    hasPermission: hasConfigurationTagsRead
                },
                {
                    title: translate('categoriesAndProducts'),
                    icon: 'categories_products',
                    to: '/configuration/categories-and-products',
                    hasPermission: hasConfigurationCategoriesAndProductsRead
                },
                {
                    title: translate('subscriptionTasks'),
                    icon: 'subscription_task',
                    to: '/configuration/subscription-tasks',
                    hasPermission: hasConfigurationSubscriptionRead
                }
            ]
        }
    ];
};

export const useNavigationMenu = () => {
    const { t: translate } = useTranslation('navigationMenu');
    const { hasPermissions } = useUserAuthorizationContext();

    return useMemo(() => {
        const menuItemProps: GetMenuItemsProps = { hasPermissions, translate };

        const menuItems = [
            ...getDashboardItems(menuItemProps),
            ...getAuthItems(menuItemProps),
            ...getFinanceItems(menuItemProps),
            ...getEmployerItems(menuItemProps),
            ...getProvidersItems(menuItemProps),
            ...getChallengesItems(menuItemProps),
            ...getProductItems(menuItemProps),
            ...getContentItems(menuItemProps),
            ...getStoreItems(menuItemProps),
            ...getConfigurationItems(menuItemProps)
        ];

        return { menuItems: getAuthorizedMenuItems(menuItems), menuPaths: getMenuPaths(menuItems) };
    }, [hasPermissions, translate]);
};
