import React, { useCallback, useState } from 'react';
import useModal from '../modal';
import Modal from 'components/organisms/Modal';
import { useTranslation } from 'react-i18next';
import Loading from 'components/atoms/Loading';
import { toast } from 'react-toastify';
import { Form, Formik, FormikHelpers } from 'formik';
import { toFormikErrors } from 'utils/errorhelper';
import Panel from 'components/organisms/Panel';
import { useProviderProduct } from 'hooks/entreoProduct';
import { AddProviderProductInputModel, EditProviderProductInputModel } from 'hooks/entreoProduct/types';
import { FormGroup, FormikFormControl, FormikFormDropFile, FormikFormNewSelect, FormikFormTextarea } from 'components/atoms/form';
import { EditCategoryInputModel } from 'hooks/entreoCategory/types';
import { useCategory } from 'hooks/entreoCategory';
import { IOption } from 'components/atoms/form/FormNewSelect';
import { cdnFileUrl } from 'utils/cdnHelper';

const useProviderProductModal = (onRefresh: () => void): [(productId?: string) => void, () => void, boolean] => {
    const { t } = useTranslation('entreoProduct');
    const [categories, setCategories] = useState<EditCategoryInputModel[]>([]);
    const { addProduct, getProduct, editProduct } = useProviderProduct();
    const { allCategories } = useCategory();

    const categoryOptions: IOption[] = categories.map((category: EditCategoryInputModel) => { return { label: category.title, value: category.id ?? '' }; });

    // State.
    const [product, setProduct] = useState<EditProviderProductInputModel | undefined>();
    const [isProductLoading, setIsProductLoading] = useState(false);
    const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const isLoading = isProductLoading || isCategoriesLoading;

    const getCategories = async () => {
        setIsCategoriesLoading(true);
        const response = await allCategories();
        if (response.ok && response.data) {
            setCategories(response.data);
        }

        setIsCategoriesLoading(false);
    };

    const fetchProduct = async (productId: string) => {
        setIsProductLoading(true);
        const response = await getProduct(productId ?? '');
        if (response && response.data) {
            const productData = response.data;
            setProduct(productData);
        }
        setIsProductLoading(false);
    };

    // Methods.
    const handleOnSubmit = async (values: EditProviderProductInputModel, helpers: FormikHelpers<EditProviderProductInputModel>) => {
        if (isSubmitting) {
            return;
        }
        if (!!product && ('id' in values)) {
            handleEdit(values, helpers as FormikHelpers<EditProviderProductInputModel>);
        } else {
            handleAdd(values, helpers as FormikHelpers<AddProviderProductInputModel>);
        }
    };

    const handleAdd = async (values: AddProviderProductInputModel, helpers: FormikHelpers<AddProviderProductInputModel>) => {
        setIsSubmitting(true);

        const response = await addProduct(values);

        if (response.ok) {
            onRefresh();
            toast.success(t('common:successAdd'));
            hide();
        } else if (response.errors) {
            const errors = toFormikErrors(response.errors);
            helpers.setErrors(errors);
        } else {
            toast.warning(t('common:general_error'));
            //Show errors in the steps?
        }
        setIsSubmitting(false);
    };

    const handleEdit = async (values: EditProviderProductInputModel, helpers: FormikHelpers<EditProviderProductInputModel>) => {
        setIsSubmitting(true);
        const response = await editProduct(values);

        if (response.ok) {
            onRefresh();
            toast.success(t('common:successEdit'));
            hide();
        } else if (response.errors) {
            const errors = toFormikErrors(response.errors);
            helpers.setErrors(errors);
        } else {
            toast.warning(t('common:general_error'));
            //Show errors in the steps?
        }
        setIsSubmitting(false);
    };

    const setInitialValues = (): EditProviderProductInputModel => {
        return product ? {
            id: product.id,
            name: product.name,
            longDescription: product.longDescription,
            shortDescription: product.shortDescription,
            order: product.order,
            categoryIds: categoryOptions.filter((item) => product.categoryIds.includes(item.value as string)).map(cat => cat.value) as string[],
            picture: product.picture,
            pictureHash: product.pictureHash
        } : {
            name: '',
            longDescription: '',
            shortDescription: '',
            categoryIds: []
        };
    };

    // Render.
    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => {
            if (isLoading) {
                return (
                    <Modal onHide={onHideModal} inProp={inProp} onExited={onExited} title={t('title')} size="large">
                        <Loading />
                    </Modal>
                );
            }
            const initialValues = setInitialValues();

            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            return (
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleOnSubmit}
                    isSubmitting={isSubmitting}
                    validateOnMount
                    validateOnChange
                >
                    {({ submitForm, values, setFieldValue }) => (
                        <Panel
                            onHide={onHideModal}
                            inProp={inProp}
                            onExited={onExited}
                            isLoading={isLoading}
                            title={t(`modal.form.title.${product === undefined ? 'add' : 'edit'}`)}
                            onCloseSide
                            buttons={[{ title: t('common:button.confirm'), onClick: submitForm }]}
                        >
                            <Form>
                                <FormGroup label={t('modal.form.image')}>
                                    <FormikFormDropFile
                                        name="picture"
                                        label={t('modal.form.imagePlaceholder')}
                                        image={values.picture
                                            ? URL.createObjectURL(values.picture)
                                            : (values.pictureHash
                                                ? cdnFileUrl(values.pictureHash, null)
                                                : undefined)}
                                        onAfterChange={(file: File) => { setFieldValue('filename', file.name.toString()); }}
                                    />
                                </FormGroup>
                                <FormGroup label={t('modal.form.name')} required>
                                    <FormikFormControl name="name" />
                                </FormGroup>
                                <FormGroup label={t('modal.form.shortDescription')} required>
                                    <FormikFormTextarea name="shortDescription" />
                                </FormGroup>
                                <FormGroup label={t('modal.form.longDescription')} required>
                                    <FormikFormTextarea name="longDescription" />
                                </FormGroup>
                                <FormGroup label={t('modal.form.category')} required>
                                    <FormikFormNewSelect options={categoryOptions} placeholder={t('modal.form.categoryPlaceholder')} isMulti name="categoryIds" />
                                </FormGroup>
                                <FormGroup label={t('modal.form.order')} required>
                                    <FormikFormControl name="order" type="number" />
                                </FormGroup>
                            </Form>
                        </Panel>
                    )}
                </Formik>
            );
        },
        [isLoading, product, isSubmitting]
    );

    const showModal = useCallback(
        async (productId?: string) => {
            if (productId) {
                fetchProduct(productId);
            }
            getCategories();
            show();
        },
        [show]
    );

    const onHideModal = () => {
        setProduct(undefined);
        hide();
    };

    return [showModal, hide, isShowing];
};

export default useProviderProductModal;

