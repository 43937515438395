import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Text = styled.span<{ underline?: boolean }>`
    font-size: 0.875rem;
    line-height: 1rem;

    ${({ underline }) =>
        underline &&
        css`
            text-decoration: underline;

            &:hover {
                cursor: pointer;
            }
        `}
`;

export const Content = styled.div`
    flex: 1;
`;

