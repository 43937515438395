import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FormCheckProps } from './';
import { lighten } from 'polished';

export const Checkbox = styled.div<{
    checked?: boolean;
}>`
  width: 1rem;
  height: 1rem;
  border: 1px solid ${({ theme }) => theme.greys[80]};
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
  display: flex;
  flex: 0 0 1rem;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: ${({ theme }) => theme.greys[100]};
  }

  ${({ theme, checked }) =>
        checked &&
        css`
      background-color: ${theme.colors.primary};
      border: none;

      &:hover {
        background-color: ${lighten(0.1, theme.colors.primary)};
      }
    `}
`;

export const HiddenCheckbox = styled.input<FormCheckProps>`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:focus + div {
    outline: none;
    box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.greys[50]};
  }
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1rem;
  line-height: 1rem;
`;

export const Wrapper = styled.label<{
    label?: string;
}>`
  display: ${({ label }) => (label ? 'flex' : 'inline-flex')};
  align-items: center;
  user-select: none;
  gap: 16px

  &:hover {
    cursor: pointer;
  }
`;
