import { useApi } from 'hooks/api';
import { appendObject } from 'utils/objectAppender';

export interface AssociationPlainModel {
    id: string;
    name: string;
}

export interface AddAssociationModel {
    name: string;
    banner?: File;
}

export interface EditAssociationModel {
    id: string;
    name: string;
    banner?: File;
}

export interface AssociationLocationModel {
    locationId: string;
    name: string;
}

export interface AddLocationAssociationModel {
    associationId: string;
    locationId: string;
}

export interface RemoveLocationAssociationModel {
    associationId: string;
    locationId: string;
}

export const useAssociations = () => {
    const { callApi } = useApi();

    const allPlainAssociations = async () => {
        const response = await callApi.current<AssociationPlainModel[]>(
            '/association/v1',
        );
        return response;
    };

    const addAssociation = async (addAssociationModel: AddAssociationModel) => {
        const formData = new FormData();
        appendObject(addAssociationModel, formData);
        if (addAssociationModel.banner !== undefined) {
            formData.append('banner', addAssociationModel.banner);
        }

        const response = await callApi.current(
            '/association/v1',
            {
                method: 'POST',
                body: formData
            }
        );
        return response;
    };

    const editAssociation = async (editAssociationModel: EditAssociationModel) => {
        const formData = new FormData();
        appendObject(editAssociationModel, formData);
        if (editAssociationModel.banner !== undefined) {
            formData.append('banner', editAssociationModel.banner);
        }

        const response = await callApi.current(
            '/association/v1',
            {
                method: 'PUT',
                body: formData
            }
        );
        return response;
    };

    const deleteAssociation = async (associationId: string) => {
        const response = await callApi.current(
            `/association/v1/${associationId}`,
            {
                method: 'DELETE'
            }
        );
        return response;
    };

    const addLocationAssociation = async (addLocationAssociationModel: AddLocationAssociationModel) => {
        const response = await callApi.current(
            '/association/v1/addLocation',
            {
                method: 'PUT',
                body: JSON.stringify(addLocationAssociationModel)
            });
        return response;
    };

    const removeLocationAssociation = async (removeLocationAssocationModel: RemoveLocationAssociationModel) => {
        const response = await callApi.current(
            '/association/v1/removeLocation',
            {
                method: 'PUT',
                body: JSON.stringify(removeLocationAssocationModel)
            }
        );
        return response;
    };

    const allLocationsAssociation = async (associationId: string) => {
        const response = await callApi.current<AssociationLocationModel[]>(
            `/association/v1/locations/${associationId}`
        );
        return response;
    };

    const allNonAssociationLocations = async () => {
        const response = await callApi.current<AssociationLocationModel[]>(
            '/association/v1/noAssociationLocations'
        );
        return response;
    };


    return {
        allPlainAssociations,
        addAssociation,
        editAssociation,
        deleteAssociation,
        addLocationAssociation,
        removeLocationAssociation,
        allLocationsAssociation,
        allNonAssociationLocations
    };
};