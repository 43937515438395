import { NavItemProps } from 'components/atoms/NavigationMenuItem/types';

type MenuPaths = Record<string, boolean>;

export const getAuthorizedMenuItems = (menuItems: NavItemProps[] = []): NavItemProps[] =>
    menuItems.reduce((menuItemsAcc: NavItemProps[], item: NavItemProps) => {
        if (!item.hasPermission) {
            return menuItemsAcc;
        }

        if (item?.items) {
            const subitemsWithPermission = item.items.filter(({ hasPermission }) => hasPermission);

            //TODO kopjes zichtbaar
            /*if (subitemsWithPermission.length === 1) {
                const { to, icon, title } = subitemsWithPermission[0];

                return [...menuItemsAcc, { ...item, icon, to, title, items: undefined }];
            }*/

            return [...menuItemsAcc, { ...item, to: subitemsWithPermission?.[0].to ?? item.to, items: getAuthorizedMenuItems(item.items) }];
        }

        return [...menuItemsAcc, item];
    }, []);

export const getMenuPaths = (navItems: NavItemProps[] = [], initialPathsAcc: MenuPaths = { '/': true }): MenuPaths =>
    navItems.reduce((pathsAcc: MenuPaths, item: NavItemProps) => {
        pathsAcc = { ...pathsAcc, [item.to]: item.hasPermission };

        if (item.items) {
            return { ...pathsAcc, ...getMenuPaths(item.items, pathsAcc) };
        }

        return pathsAcc;
    }, initialPathsAcc);
