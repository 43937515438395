import { useField } from 'formik';
import React, { FC } from 'react';
import FormDropFile, { FormDragDropFileProps } from '..';

export interface FormikFormDragDropFileProps extends FormDragDropFileProps {
    name: string;
    image?: string;
    showName?: boolean;
}

const FormikFormDragDropFile: FC<FormikFormDragDropFileProps> = ({ name, image, showName, ...props }) => {
    const [field, meta, helpers] = useField<File>(name);
    // Render.
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;
    const showNameCheck = showName === undefined ?? true;

    return <FormDropFile {...props} value={field.value} onChange={helpers.setValue} error={error} image={image} showTextInImage={showNameCheck} />;
};

export default FormikFormDragDropFile;
