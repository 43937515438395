import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div<{ big?: boolean; }>`
  display: flex;
  align-items: center;
  font-size: .9rem;
  font-weight: 500;
  ${({ big }) =>
    big &&
    css`
    font-size: 1rem;
  `};
`;

export const Image = styled.div<{ big?: boolean; }>`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.35rem;
  font-weight: 700;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: .65rem;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.placeholder};
  background-color: ${({ theme }) => theme.colors.light};
  border: 1px solid ${({ theme }) => theme.colors.border};
  img {
    width: 100%;
    height: auto;
  }
  ${({ big }) =>
    big &&
    css`
  margin-right: 1rem;
  width: 5rem;
  height: 5rem;
  `};
`;

export const Body = styled.div`
  flex-shrink: 0;
  font-size: 1em;
  p {
    margin-bottom: 0;
  }
`;


export const ProductName = styled.p`
  font-size: 1em;
  font-weight: 600;
`;

export const ProductNumber = styled.p`
  color: ${({ theme }) => theme.colors.grey};
  font-size: .75em;
`;

export const Specs = styled.div`
  margin-top: .5rem;
  font-size: .85em;
  span {
    display: block;
  }
`;