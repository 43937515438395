import React from 'react';
import Select, { ControlProps, Props, PropsValue, SingleValue, components } from 'react-select';
import { LabelWrapper } from './styles';

export type CustomSelectOption = {
    value: string | number;
    label: string;
};
export type CustomSelectProps = Props<CustomSelectOption> & { label?: string };
export type CustomSelectValue = PropsValue<CustomSelectOption>;
export type CustomSelectSingleValue = SingleValue<CustomSelectOption>;

const Control = ({ children, ...props }: ControlProps<CustomSelectOption, boolean> & { label?: string }) => (
    <>
        {props?.label && <Label text={props.label} />}
        <components.Control {...props}>{children}</components.Control>
    </>
);

const Label = ({ text }: { text: string }) => (
    <LabelWrapper>
        <label>{text}</label>
    </LabelWrapper>
);

export const CustomSelect = ({ options, value, label, onChange, ...rest }: CustomSelectProps) => (
    <Select
        options={options}
        onChange={onChange}
        components={{ Control: (props) => <Control {...props} label={label} /> }}
        value={value}
        styles={{
            control: (provided) => ({
                ...provided,
                borderRadius: '2rem',
                padding: '0.25rem'
            }),
            placeholder: (provided) => ({
                ...provided,
                fontSize: '0.9rem'
            }),
            container: (provided) => ({
                ...provided,
                margin: '1rem 0'
            })
        }}
        {...rest}
    />
);
