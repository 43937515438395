import React, { FC } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import Icon from 'components/atoms/Icon';
import Dropzone from 'react-dropzone';
import { Wrapper } from '../FormDropFile/styles';

export interface FormDragDropFileProps {
    error?: string;
    value?: File;
    onChange?: (value: File) => void;
    label?: string;
    image?: string;
    showTextInImage?: boolean;
}

const FormDragDropFile: FC<FormDragDropFileProps> = ({ error, label, onChange, value, image, showTextInImage }) => {
    const handleOnDrop = (files: File[]) => {
        if (onChange && files.length > 0) {
            onChange(files[0]);
        }
    };

    return (
        <>
            <Dropzone onDrop={handleOnDrop} maxFiles={1}>
                {({ getRootProps, getInputProps }) => (
                    <Wrapper {...getRootProps()} background={image}>
                        <input {...getInputProps()} />
                        {
                            value ? (
                                <>
                                    <Icon name="edit" size={2} />
                                    { 
                                        showTextInImage && 
                                        <p>{value.name}</p>   
                                    }
                                </>
                            ) : (
                                <>
                                    <Icon name="file-upload" size={2} />
                                    {
                                        showTextInImage &&
                                        <p>{label}</p>
                                    }
                                </>
                            )
                        }
                    </Wrapper>
                )}
            </Dropzone>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormDragDropFile;
