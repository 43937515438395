import { useTheme } from '@emotion/react';
import { AnimatePresence } from 'framer-motion';
import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { Arrow, useHover, useLayer, UseLayerOptions } from 'react-laag';
import Icon from '../Icon';
import { Tooltip as Wrapper, Trigger } from './styles';

export interface TooltipProps {
    trigger?: ReactElement; // TODO: custom trigger
    layerOptions?: Partial<UseLayerOptions>;
}

const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({ children, layerOptions, trigger }) => {
    const theme = useTheme();
    const [isOpen, hoverProps] = useHover({ delayEnter: 100, delayLeave: 300 });
    const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
        isOpen,
        placement: 'bottom-end',
        triggerOffset: 8,
        ...layerOptions,
    });

    return (
        <>
            <Trigger {...triggerProps} {...hoverProps}>
                <Icon name="info" color={theme.greys[500]} size={1.25} />
            </Trigger>
            {isOpen && (
                renderLayer(
                    <AnimatePresence>
                        <Wrapper {...layerProps}>
                            {children}
                            <Arrow {...arrowProps} size={6} />
                        </Wrapper>
                    </AnimatePresence>
                )
            )}
        </>
    );
};

export default Tooltip;
