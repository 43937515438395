import Icon from 'components/atoms/Icon';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Colors } from 'static/theme/colors';

export const Dropdown = styled.div<{ isFull?: boolean, isButton?: boolean }>`
    display: flex;
    position: relative;
    z-index: 5;
    height: 100%;
    padding: 0 ${({ theme }) => theme.form.padding.horizontal}px;

    ${({ isButton }) =>
        isButton &&
        css`
            padding: 0px;
        `}
    
    ${({ isFull }) =>
        isFull &&
        css`
            flex: 1;
        `}
`;

export const Header = styled.div<{ isFull?: boolean, isButton?: boolean, isBig?: boolean, isFill?: boolean, color?: keyof Colors }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    gap: 12px;

    ${({ isFull }) =>
        isFull &&
        css`
            flex: 1;
        `}

    ${({ color, theme }) =>
        color &&
        css`
            color: ${theme.colors[color]};
`}

    ${({ isFill, theme }) =>
        isFill &&
        css`
            background-color: ${theme.colors.pink};
        `}


    ${({ isButton, theme, isBig }) =>
        isButton &&
        css`
            border: 1px solid ${theme.colors.pink};
            border-radius: 20px;
            padding: ${isBig ? '16px 24px' : '10px'};
            white-space: nowrap;
        `}
`;

export const Toggle = styled(Icon) <{ isFull?: boolean }>`
    margin-left: 8px;

    &:hover {
        cursor: pointer;
    }

    ${({ isFull }) =>
        isFull &&
        css`
            margin-left: auto;
        `}
`;

