const cdnUrl = 'https://cdn.bluenotion.nl';

export const cdnFileUrl = (filename: string, params: any) => {
    const args = params
        ? Object.keys(params)
            .map((k: any) => {
                return `${k}=${params[k]}`;
            })
            .reduce((a, b) => {
                if (a) {
                    return `${a}&${b}`;
                }

                return b;
            }, '')
        : '';

    let result = `${cdnUrl}/${filename}`;
    if (args.length > 0) {
        result += `?${args}`;
    }
    return result;
};

export const resolvedCdn = (imageHash: string, width = 64, height = 64, crop = true) => {
    return cdnFileUrl(imageHash, {
        thumb: true,
        resize: `${width}x${height}^`,
        gravity: 'center',
        ...(crop && { crop: `${width}x${height}+0+${height / 2}` }),
        ...(crop && { extent: `${width}x${height}` }),
        optimize: true,
        progressive: true,
        quality: 90
    });
};

export const cdnUri = (imageHash: string, width = 64, height = 64) => {
    return { uri: resolvedCdn(imageHash, width, height) };
};

export const cdnString = (imageHash: string, width = 64, height = 64) => {
    return resolvedCdn(imageHash, width, height);
};
