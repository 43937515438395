import React, { FC, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { StyledIconButton, MenuItemWrapper, Title, TitleWrapper, StyledNavLink } from './styles';
import { useTheme } from '@emotion/react';
import { useNavHeader } from './useNavHeader';
import { NavItemOptions, NavItemProps } from './types';

export const Header: React.FC<
    Partial<NavItemProps> &
        NavItemOptions & {
            item: NavItemProps;
        }
> = ({ customPadding, expanded, item: { icon, to, title, items }, isActive, showIconOnly, onMenuItemClick, onExpandButtonClick }) => {
    const theme = useTheme();
    const color = isActive ? theme.colors.white : theme.colors.black;
    const expandButtonIcon = isActive && expanded ? 'chevron-up' : 'chevron-down';

    return (
        <MenuItemWrapper active={isActive} customPadding={customPadding} title={title} sub={false}>
            <StyledNavLink to={items?.[0]?.to ?? to} end onClick={onMenuItemClick}>
                <TitleWrapper>
                    {icon && <StyledIconButton icon={icon} color={color} />}
                    {!showIconOnly && <Title active={isActive}>{title}</Title>}
                </TitleWrapper>
            </StyledNavLink>
            {!showIconOnly && <StyledIconButton icon={expandButtonIcon} color={color} onClick={onExpandButtonClick} />}
        </MenuItemWrapper>
    );
};

export const HeaderItem: React.FC<Pick<NavItemProps, 'icon' | 'title' | 'to'> & NavItemOptions> = ({
    customPadding,
    icon,
    showIconOnly,
    sub,
    title,
    to,
    onMenuItemClick
}) => {
    const theme = useTheme();

    return (
        <NavLink to={to} end>
            {({ isActive }) => (
                <MenuItemWrapper active={isActive} customPadding={customPadding} title={title} sub={sub} onClick={onMenuItemClick}>
                    {icon && <StyledIconButton icon={icon} color={isActive ? theme.colors.white : theme.colors.black} />}
                    {!showIconOnly && (
                        <TitleWrapper>
                            <Title active={isActive}>{title}</Title>
                        </TitleWrapper>
                    )}
                </MenuItemWrapper>
            )}
        </NavLink>
    );
};

export const NavItemHeader: FC<{ item: NavItemProps }> = ({ item }) => {
    const { isMenuOpen, items } = item;
    const { isActive, expanded, handleHeaderItemClick, handleExpandButtonClick } = useNavHeader({ item });

    const headerItems = useMemo(
        () => items?.map((item) => <HeaderItem icon={item.icon} key={item.title} sub={true} title={item.title} to={item.to} />),
        [items]
    );

    return (
        <>
            <Header
                expanded={expanded}
                isActive={isActive}
                item={item}
                showIconOnly={!isMenuOpen}
                onExpandButtonClick={handleExpandButtonClick}
                onMenuItemClick={handleHeaderItemClick}
            />
            {expanded && isMenuOpen && <>{headerItems}</>}
        </>
    );
};

