import React, { FC } from 'react';
import { FormGroup, FormRadio } from 'components/atoms/form';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { H3 } from 'components/atoms/text';
import Medal from 'components/atoms/Medal';
import { MeasurableType } from 'hooks/challenge';
import { ChallengeRatingType, ChallengeTemplateStatusType, ChallengeThemeType, ChallengeThirdPartyType } from 'hooks/challengeTemplate';
import { ChallengeTemplateValues } from '..';
import { formatLongDate } from 'utils/momentFormatter';
import Margin from 'components/atoms/Margin';
import EditRow from 'components/atoms/EditRow';

interface Step2Props {
    mode: 'add' | 'edit'
}

const Step4: FC<Step2Props> = ({ mode }) => {
    const { t } = useTranslation('challengeTemplate');
    const { values, setFieldValue } = useFormikContext<ChallengeTemplateValues>();

    // methods
    const updateOrderStatus = async (newStatus: ChallengeTemplateStatusType) => {
        setFieldValue('status', newStatus);
    };

    // render
    const visiblePeriod = values.hasDate ? `${formatLongDate(values.startDate)} - ${formatLongDate(values.endDate)}` : t('visiblePeriod.alwaysVisible');
    const fromPeriod = values.hasFromDate ? `${formatLongDate(values.fromDate)} - ${formatLongDate(values.fromEndDate)}` : t('visiblePeriod.alwaysVisible');
    return (
        <>
            <>
                <Margin bottom={2}>
                    <EditRow title={t('status')}>
                        <FormRadio
                            checked={values.status === ChallengeTemplateStatusType.Published}
                            onChange={() => updateOrderStatus(ChallengeTemplateStatusType.Published)}
                            label={t('tabs.published')}
                        />
                        <FormRadio
                            checked={values.status === ChallengeTemplateStatusType.Draft}
                            onChange={() => updateOrderStatus(ChallengeTemplateStatusType.Draft)}
                            label={t('tabs.draft')}
                        />
                        <FormRadio
                            checked={values.status === ChallengeTemplateStatusType.Disabled}
                            onChange={() => updateOrderStatus(ChallengeTemplateStatusType.Disabled)}
                            label={t('tabs.disabled')}
                        />
                    </EditRow>
                </Margin>
            </>
            <FormGroup horizontal isLarge isLight label={t('step4.name')}>
                <H3>{values.name}</H3>
            </FormGroup>
            <FormGroup horizontal isLarge isLight label={t('step4.theme')}>
                <H3>{t('theme.' + ChallengeThemeType[values.theme])}</H3>
            </FormGroup>
            <FormGroup horizontal isLarge isLight label={t('step4.uniqueFeature')}>
                <H3>{t(`uniqueType.${ChallengeThirdPartyType[values.thirdParty as number]}`)}</H3>
            </FormGroup>
            <FormGroup horizontal isLarge isLight white-space={false} label={t('step4.description')}>
                <H3 wrap>{values.description}</H3>
            </FormGroup>
            {values.hasDate && (
                <Row>
                    {values.startDate && values.endDate && (
                        <Col>
                            <FormGroup horizontal isLarge isLight label={t('step4.visiblePeriod')}>
                                <H3>{visiblePeriod}</H3>
                            </FormGroup>
                        </Col>
                    )}
                </Row>
            )}
            <Row>
                {values.fromDate && values.fromEndDate && (
                    <Col>
                        <FormGroup horizontal isLarge isLight label={t('step4.fromPeriod')}>
                            <H3>{fromPeriod}</H3>
                        </FormGroup>
                    </Col>
                )}
            </Row>
            <FormGroup horizontal isLarge isLight label={t('step4.challengeReward')}>
                <H3>{t('step4.reward', { value: values.entreoPointsReward })}</H3>
            </FormGroup>
            <FormGroup horizontal isLarge isLight label={t('step4.difficulty')}>
                <H3>{t('step3.ratingType.' + ChallengeRatingType[values.difficulty])}</H3>
            </FormGroup>
            {values.medal && (
                <FormGroup horizontal isLarge isLight label={t('step4.medal')}>
                    <Medal type={MeasurableType.Steps} />
                </FormGroup>
            )}
        </>
    );
};

export default Step4;
