import React, { FC, PropsWithChildren } from 'react';
import { Colors } from 'static/theme/colors';
import { Card as Wrapper, Inner } from './styles';

export interface CardProps {
    noMargin?: boolean;
    variant?: keyof Colors;
    full?: boolean;
    overflow?: 'visible' | 'hidden' | 'scroll' | 'auto';
}
const Card: FC<PropsWithChildren<CardProps>> = ({ children, ...props }) => {
    return (
        <Wrapper {...props}>
            <Inner {...props}>{children}</Inner>
        </Wrapper>
    );
};

export default Card;
