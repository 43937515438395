import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FormGroup = styled.div<{
    noMargin?: boolean;
    horizontal?: boolean;
    border?: boolean;
    center?: boolean;
}>`
    margin-bottom: ${({ noMargin }) => (noMargin ? 0 : '1.5rem')};

    ${({ horizontal }) =>
        horizontal &&
        css`
            display: flex;
            justify-content: space-between;

            > * {
                flex: 1;
            }
        `};

    ${({ border, theme }) =>
        border &&
        css`
            border-bottom: 1px solid ${theme.colors.light};
            padding-bottom: 1.5rem;
        `};

    ${({ center }) =>
        center &&
        css`
            align-items: center;
        `};

`;

export const LabelNoBlock = styled.label<{
    required?: boolean;
    isLarge?: boolean;
    isLight?: boolean;
}>`
    font-size: ${({ theme }) => theme.form.fontSize}rem;
    line-height: ${({ theme }) => theme.form.fontSize}rem;
    font-weight: ${({ isLight }) => (isLight ? 400 : 500)};
    color: ${({ theme }) => theme.colors.dark};
`;

export const Label = styled.label<{
    required?: boolean;
    isLarge?: boolean;
    isLight?: boolean;
}>`
    display: block;
    font-size: ${({ theme }) => theme.form.fontSize}rem;
    line-height: ${({ theme }) => theme.form.fontSize}rem;
    font-weight: ${({ isLight }) => (isLight ? 400 : 500)};
    color: ${({ theme }) => theme.colors.dark};
    white-space: nowrap;

    ${({ isLarge }) =>
        isLarge &&
        css`
            font-size: 1rem;
            line-height: 1rem;
        `}

    ${({ required, theme }) =>
        required &&
        css`
            &:after {
                content: ' *';
                color: ${theme.colors.secondary};
                font-weight: bold;
            }
        `};
`;

export const Header = styled.div<{ horizontal?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;

    ${({ horizontal }) =>
        horizontal &&
        css`
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 0;

            > * {
                margin-left: 0 !important;
            }

            label {
                margin-bottom: 0.25rem;
            }
        `};
`;

export const Text = styled.span`
    &,
    a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 8px;
        color: ${({ theme }) => theme.greys[300]};
        font-size: 0.875rem;
        line-height: 1rem;
        flex: 1;
    }
`;

