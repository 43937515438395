import Face from 'components/atoms/Face';
import React, { forwardRef } from 'react';
import { Visible } from 'react-grid-system';
import { Name, User as Wrapper, Email } from './styles';

interface UserProps {
    big?: boolean;
    image?: string;
    name?: string;
    emailAddress?: string;
}

const User = forwardRef<HTMLDivElement, UserProps>(({ big, name, emailAddress, image }, ref) => {
    return (
        <Wrapper ref={ref}>
            <Face image={image} placeholderLetter={name} big={big} round />
            <Visible lg xl xxl>
                <div>
                    {
                        name &&
                        <Name>{name}</Name>
                    }
                    {
                        emailAddress &&
                        <Email>{emailAddress}</Email>
                    }
                </div>
            </Visible>
        </Wrapper>
    );
});

export default User;
