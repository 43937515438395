import Button, { ButtonProps } from 'components/atoms/button/Button';
import { H3, H4 } from 'components/atoms/text';
import React, { FC, PropsWithChildren } from 'react';
import { Icons } from 'static/theme/icons';
import { Header, Content, Right, UpperRow, LowerRow, Subtitle, ImageRow } from './styles';

export interface CardHeaderImage {
    src: string;
    alt: string;
    height: number;
    width: number;
}

export interface CardHeaderProps {
    title: string;
    subtitle?: string;
    cta?: string;
    ctaAction?: () => void;
    button?: ButtonProps;
    notifications?: number;
    icon?: keyof Icons;
    noBorderLine?: boolean;
    image?: CardHeaderImage;
}

const CardHeader: FC<PropsWithChildren<CardHeaderProps>> = ({ title, cta, button, notifications, ctaAction, children, icon, subtitle, noBorderLine, image }) => {
    return (
        <Header noBorderLine={noBorderLine}>
            {image && 
                <ImageRow>
                    <img src={image.src} alt={image.alt} height={image.height} width={image.width} />
                </ImageRow>
            }
            <UpperRow>
                <H3 icon={icon} notifications={notifications}>
                    {title}
                </H3>
                <Right>
                    {cta && <H4 onClick={ctaAction}>{cta}</H4>}
                    {button && <Button {...button} />}
                    {children && <Content>{children}</Content>}
                </Right>
            </UpperRow>
            {subtitle &&
                <LowerRow>
                    {subtitle && <Subtitle>{subtitle}</Subtitle>}
                </LowerRow>}
        </Header>
    );
};

export default CardHeader;
