
import React, { FC, useState, useEffect } from 'react';
import Dropdown from '../Dropdown';
import { ContentEnvironmentPlainModel, useContentEnvironment } from 'hooks/contentEnvironment';
import { useSession } from 'contexts/sessionContext';
import { useUser } from 'hooks/user';
import { defaultAreaRoute } from 'utils/areaHelper';
import Margin from 'components/atoms/Margin';
import Icon from 'components/atoms/Icon';
import { Selected } from '../AssociationDropdown/styles';
import { useNavigate } from 'react-router-dom';

export interface ContentDropdownProps { }

const ContentEnvironmentDropdown: FC<ContentDropdownProps> = () => {
    const { getAllContentEnvironments } = useContentEnvironment();
    const { switchActiveContentEnvironment } = useUser();
    const [contentEnvironments, setContentEnvironments] = useState<ContentEnvironmentPlainModel[]>([]);
    const { session } = useSession();
    const navigate = useNavigate();

    // Life cycle
    useEffect(() => {
        fetchContentEnvironments();
    }, []);

    // Methods
    const handleOnSubItemClick = async (value: string) => {
        const response = await switchActiveContentEnvironment(value);
        if (response.ok && session?.role && session.activeEnvironment) {
            const route = defaultAreaRoute(session.role, session.activeEnvironment);
            navigate(route);
        } else {
            //TODO: set prev value or something.
        }
    };

    const fetchContentEnvironments = async () => {
        const response = await getAllContentEnvironments();
        if (response.ok && response.data) {
            setContentEnvironments(response.data);
        }
    };

    // Render.
    const activeContentenvironmentId = session?.activeContentEnvironmentId;
    const dropdown = contentEnvironments.map((contentEnvironment) => ({
        title: contentEnvironment.name,
        value: contentEnvironment.id
    }));
    const selected = dropdown.find((item) => item.value === activeContentenvironmentId);

    return (
        <>
            {contentEnvironments !== undefined && (
                <Dropdown
                    items={dropdown}
                    onSubItemClick={(value) => handleOnSubItemClick(value as string)}
                >
                    <Margin right={0.5} alignItems="center">
                        <Icon name="content" />
                    </Margin>
                    <Selected>{selected?.title}</Selected>
                </Dropdown>
            )}
        </>
    );
};

export default ContentEnvironmentDropdown;
