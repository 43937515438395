import styled from '@emotion/styled';

export const Wrapper = styled.div`
    position: relative;
    height: 5.125rem;
    border-radius: ${({ theme }) => theme.borderRadius.large}px;
    background-color: ${({ theme }) => theme.colors.background};
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 11px 20px;
    margin-bottom: 0.5rem;
`;

export const Image = styled.div<{ src: string }>`
    flex: 0 0 3.75rem;
    background-image: url(${({ src }) => src});
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0.5rem;
`;

export const Label = styled.div`
    flex: 1 1 auto;
    height: 100%;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
    }
`;

export const FileName = styled.p`
    font-size: 0.875rem;
    font-weight: 600;
`;

export const FileSize = styled.p`
    font-size: 0.75rem;
`;

export const Actions = styled.div`
    flex: 0 0 auto;
`;

export const ActionButton = styled.button`
`;

