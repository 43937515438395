import { SessionModel } from 'api';
import { useSession } from 'contexts/sessionContext';
import { getLanguage } from 'i18n';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export interface CustomError {
    key: string;
    message: string;
}

export interface CustomResponse<T> extends Response {
    data?: T;
    errors?: CustomError[];
}

type CallApiFn = <T>(url: string, options?: RequestInit | undefined) => Promise<CustomResponse<T>>;

let refreshing: Promise<CustomResponse<SessionModel>> | undefined;

export const useApi = () => {
    const navigate = useNavigate();
    const { setSession } = useSession();

    const redirectToLogin = () => {
        navigate('/account/login');
    };

    const getFullUrl = (url: string) => {
        return `/api/portal${url}`;
    };

    const getHeaders = (options: RequestInit) => {
        const headers = options.headers ? new Headers(options.headers) : new Headers();
        headers.set('Accept', 'application/json');
        headers.set('Accept-Language', getLanguage());

        // If body is not form data, then it should be json
        if (!(options.body instanceof FormData)) {
            headers.set('Content-Type', 'application/json');
        }

        return headers;
    };

    const baseCallApi = async <T>(url: string, options?: RequestInit) => {
        const finalUrl = getFullUrl(url);
        const finalOptions = {
            headers: getHeaders(options || {}),
            ...options
        };

        const response: CustomResponse<T> = await fetch(finalUrl, finalOptions);
        const contentType = response.headers.get('Content-Type') || '';
        if (contentType.includes('application/json')) {
            const data: T & { errors: CustomError[] } = await response.json();
            if (response.ok) {
                response.data = data as T;
            } else {
                response.errors = data.errors.map((error) => ({
                    ...error,
                    key: !!error.key && error.key.length > 0 ? error.key[0].toLowerCase() + error.key.slice(1) : error.key
                }));
            }
        }

        return response;
    };

    const refreshSession = React.useCallback(async () => {
        const response = await baseCallApi<SessionModel>('/account/v1/cookierefresh', {
            method: 'POST'
        });

        if (response.ok && response.data) {
            const { claims } = response.data;
            setSession(claims);
        } else {
            redirectToLogin();
        }

        return response;
    }, [setSession]);

    const callApiFn: CallApiFn = React.useCallback(
        async <T>(url: string, options?: RequestInit) => {
            // If we are already refreshing, wait for that to finish before making the api call
            if (!refreshing) {
                const response: CustomResponse<T> = await baseCallApi(url, options);
                if (response.status === 401 && !!response.headers.get('Token-Expired')) {
                    // If we are already refreshing, no need to refresh again
                    if (!refreshing) {
                        refreshing = refreshSession();
                    }
                } else {
                    return response;
                }
            }

            const refreshResponse = await refreshing;
            refreshing = undefined;

            if (refreshResponse.ok) {
                return callApiFn<T>(url, options);
            } else {
                redirectToLogin();
            }

            const dummyResponse: CustomResponse<T> = new Response(null, { status: 400, statusText: 'Refresh failed' });
            return dummyResponse;
        },
        [refreshSession]
    );

    const callApi = React.useRef<CallApiFn>(callApiFn);
    callApi.current = callApiFn;

    const apiGet = React.useCallback(
        async <T>(url: string) => {
            const response = await callApi.current<T>(url);

            return response;
        },
        [callApi]
    );

    const apiPost = React.useCallback(
        async <T>(url: string, data?: any) => {
            const body = data !== undefined ? (data instanceof FormData ? data : JSON.stringify(data)) : undefined;

            const response = await callApi.current<T>(url, {
                method: 'POST',
                body
            });

            return response;
        },
        [callApi]
    );

    const apiPut = React.useCallback(
        async <T>(url: string, data?: any) => {
            const body = data !== undefined ? (data instanceof FormData ? data : JSON.stringify(data)) : undefined;

            const response = await callApi.current<T>(url, {
                method: 'PUT',
                body
            });

            return response;
        },
        [callApi]
    );

    const apiDelete = React.useCallback(
        async <T>(url: string) => {
            const response = await callApi.current<T>(url, {
                method: 'DELETE'
            });

            return response;
        },
        [callApi]
    );

    return React.useMemo(
        () => ({
            callApi,
            apiGet,
            apiPost,
            apiPut,
            apiDelete,
            refreshSession
        }),
        [callApi, refreshSession]
    );
};
