import { useField } from 'formik';
import React, { FC } from 'react';
import { FormSwitchProps } from '..';
import { FormSwitch } from '../..';

export interface FormikFormSwitchProps extends FormSwitchProps {
    name: string;
}

const FormikFormSwitch: FC<FormikFormSwitchProps> = ({ name, ...props }) => {
    const [field, , helpers] = useField<boolean>(name);

    // Methods.
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        helpers.setValue(checked);
    };

    return <FormSwitch {...props} checked={field.value} onChange={handleOnChange} />;
};

export default FormikFormSwitch;
