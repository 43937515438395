import { css, Global, ThemeProvider } from '@emotion/react';
import { SessionProvider } from 'contexts/sessionContext';
import 'i18n';
import React, { ComponentType } from 'react';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import theme from 'static/theme';
import { SkeletonTheme } from 'react-loading-skeleton';
import { lighten } from 'polished';
import { ModalProvider } from 'react-modal-hook';
import { TransitionGroup } from 'react-transition-group';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppRoutes } from 'screens/App/AppRoutes';
import { UserAuthorizationProvider } from 'contexts/userAuthorization';

const queryClient = new QueryClient();
// TODO: Update below.
const globalStyle = css({
    '*, *:before, *:after': {
        boxSizing: 'border-box',
        fontFamily: 'Gilroy, Helvetica, sans-serif'
    },
    '::selection': {
        color: theme.colors.white,
        backgroundColor: theme.colors.primary
    },
    body: {
        position: 'relative',
        margin: 0,
        padding: 0,
        overflowX: 'hidden',
        color: theme.colors.text,
        backgroundColor: theme.colors.light
    },
    '#root': {
        minHeight: '100vh'
    },
    code: {
        fontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace`
    },
    hr: {
        marginTop: theme.constants.spacer,
        marginBottom: theme.constants.spacer,
        border: 0,
        width: '100%',
        borderBottom: '1px solid ' + theme.colors.border,
        boxSizing: 'border-box'
    },
    'h1, h2, h3, h4, h5, h6, .h0, .h1, .h2, .h3, .h4, .h5, .h6': {
        color: theme.colors.text,
        marginTop: 0,
        marginBottom: '1rem',
        lineHeight: '1.2',
        fontWeight: 700
    },
    'h1, .h1': {
        fontSize: theme.fontSizes.h1,
        marginBottom: '2rem'
    },
    'h2, .h2': {
        fontSize: theme.fontSizes.h2
    },
    'h3, .h3': {
        fontSize: theme.fontSizes.h3
    },
    'h4, .h4': {
        fontSize: theme.fontSizes.h4
    },
    'h5, .h5, h6, .h6': {
        fontWeight: 600,
        fontSize: theme.fontSizes.bigger
    },
    'h5, .h5, h6, .h6, .bigger': {
        fontSize: theme.fontSizes.bigger
    },
    '.smaller': {
        fontSize: theme.fontSizes.smaller
    },
    '.dark': {
        color: theme.colors.dark
    },
    '.primary': {
        color: theme.colors.primary
    },
    p: {
        marginTop: 0,
        marginBottom: '1rem'
    },
    'li, p': {
        lineHeight: 1.5
    },
    ul: {
        marginTop: 0
    },
    'b, strong': {
        fontWeight: 600
    },
    a: {
        textDecoration: 'none',
        color: 'inherit',
        cursor: 'pointer'
    },
    img: {
        verticalAlign: 'middle'
    },
    '.react-datepicker-wrapper, .react-datepicker__input-container': {
        display: 'block !important'
    },
    button: {
        verticalAlign: 'middle',
        outline: 0,
        border: 0,
        cursor: 'pointer',
        fontSize: 'inherit',
        padding: 0,
        background: 'none',
        fontFamily: 'inherit',
        appearance: 'none',
        textShadow: 'none'
    },
    input: {
        fontFamily: 'inherit',
        appearance: 'none'
    },
    table: {
        borderCollapse: 'collapse',
        maxWidth: '100%'
    },
    '.ReactCollapse--collapse': {
        transition: 'height .5s'
    },
    '.icon-wrapper': {
        pointerEvents: 'none',
        span: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    '.swiper-pagination-bullet': {
        backgroundColor: theme.colors.blue + ' !important',
        opacity: 0.1 + ' !important',
        margin: '0 2.5px !important'
    },
    '.swiper-pagination-bullet-active': {
        opacity: 1 + ' !important'
    },
    '.react-datepicker-popper': {
        left: '10px !important',
        zIndex: 103
    },
    '#layers': {
        zIndex: 102
    },
    '.react-datepicker__triangle': {
        display: 'none'
    },
    '.react-datepicker__current-month': {
        marginLeft: '20px !important'
    },
    '.react-datepicker__input-container input': {
        width: '100%',
        height: `${theme.form.height}rem`,
        border: `1px solid ${theme.greys[50]}`,
        borderRadius: `${theme.borderRadius.xl}px`,
        padding: `0 ${theme.form.padding.horizontal}px`,
        fontSize: `${theme.form.fontSize}rem`,
        paddingRight: '3rem',

        ':focus': {
            outline: 'none',
            boxShadow: `0 0 0 0.2rem ${theme.greys[50]}`
        },

        ':hover': {
            borderColor: `${theme.greys[100]}`
        },

        '::placeholder': {
            color: `${theme.greys[300]}`
        }
    }
});

const App = () => {
    return (
        <>
            <Global styles={globalStyle} />
            <ThemeProvider theme={theme}>
                <SkeletonTheme baseColor={lighten(0.03, theme.colors.background)} highlightColor={theme.colors.background}>
                    <QueryClientProvider client={queryClient}>
                        <SessionProvider>
                            <UserAuthorizationProvider>
                                <BrowserRouter>
                                    <ModalProvider rootComponent={TransitionGroup as ComponentType}>
                                        <ToastContainer transition={Bounce} autoClose={2500} />
                                        <AppRoutes />
                                    </ModalProvider>
                                </BrowserRouter>
                            </UserAuthorizationProvider>
                        </SessionProvider>
                    </QueryClientProvider>
                </SkeletonTheme>
            </ThemeProvider>
        </>
    );
};

export default App;
