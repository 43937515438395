import { useApi } from 'hooks/api';
import { appendGuidArray, appendObject } from 'utils/objectAppender';
import { AddProviderProductInputModel, ProviderProductInputModel, EditProviderProductInputModel } from './types';


export const useProviderProduct = () => {
    const { callApi } = useApi();

    const allProducts = async () => {
        const response = await callApi.current<ProviderProductInputModel[]>('/Product/v1/Plain');

        return response;
    };

    const addProduct = async (values: AddProviderProductInputModel) => {
        const formData = new FormData();
        appendObject(values, formData);

        if (values.categoryIds !== undefined) {
            appendGuidArray(values.categoryIds, formData, 'categoryIds');
        }

        if (values.picture !== undefined) {
            formData.append('picture', values.picture);
        }

        const response = await callApi.current('/Product/v1', {
            method: 'POST',
            body: formData
        });

        return response;
    };

    const editProduct = async (values: EditProviderProductInputModel) => {
        const formData = new FormData();
        appendObject(values, formData);

        if (values.categoryIds !== undefined) {
            appendGuidArray(values.categoryIds, formData, 'categoryIds');
        }

        if (values.picture !== undefined) {
            formData.append('picture', values.picture);
        }
        const response = await callApi.current('/Product/v1', {
            method: 'PUT',
            body: formData
        });
        return response;
    };

    const getProduct = async (id: string) => {
        const response = await callApi.current<ProviderProductInputModel>(`/Product/v1/${id}`);
        return response;
    };

    const removeProduct = async (id: string) => {
        const response = await callApi.current(`/Product/v1/${id}`, {
            method: 'DELETE'
        });
        return response;
    };

    return {
        allProducts,
        addProduct,
        editProduct,
        getProduct,
        removeProduct
    };
};
