import Button from 'components/atoms/button/Button';
import { Wrapper } from 'components/atoms/form/FormDropFile/styles';
import Icon from 'components/atoms/Icon';
import React, { FC } from 'react';

export interface ImageButtonProps {
    image?: string;
    onClick: () => void
}

const ImageButton: FC<ImageButtonProps> = ({ image, onClick, ...props }) => {
    return (
        <>
            <Wrapper background={image}>
                {
                    <Button onClick={onClick} type="button" alignSelf="center" white>
                        <Icon name="edit" size={2} />
                        <p>{'Kies een andere afbeelding'}</p>
                    </Button>
                }
            </Wrapper>
        </>
    );
};

export default ImageButton;