import React, { FC } from 'react';
import { Paragraph, H2 } from 'components/atoms/text';
import { Check, Wrapper } from './styles';

interface ConfirmProps {
    title: string;
    description: string;
    type?: 'success' | 'warning';
}

const Alert: FC<ConfirmProps> = ({ title, description, type = 'success' }) => {
    return (
        <Wrapper>
            <Check name={type === 'success' ? 'check-circle' : 'box-important'} size={2} color={type === 'success' ? '#31DF38' : '#F6AE2D'} />
            <H2>{title}</H2>
            <Paragraph>{description}</Paragraph>
        </Wrapper>
    );
};

export default Alert;

