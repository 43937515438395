export interface FontSizes {
    normal: number;
    h1: number;
    h2: number;
    h3: number;
    h4: number;
    bigger: number;
    smaller: number;
    tiny: number;
}

const fontSizes: FontSizes = {
    // TODO: To rem
    normal: 16,
    h1: 38,
    h2: 33,
    h3: 24,
    h4: 19,
    bigger: 18,
    smaller: 14,
    tiny: 13
};

export default fontSizes;
