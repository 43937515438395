export interface Card {
    padding: {
        horizontal: number;
    };
}

const card: Card = {
    padding: {
        horizontal: 24
    }
};

export default card;
