import { Interpolation } from '@emotion/react';
import { Theme } from 'static/theme';

export const button: Interpolation<Theme> = {
    color: 'initial',
    backgroundColor: 'initial',
    border: 'none',
    cursor: 'pointer',
    '&:focus': {
        outline: 'none'
    }
};
