import React, { FC } from 'react';
import Dropdown from '../Dropdown';
import { DropdownItemProps } from 'components/atoms/item/DropdownItem';
import { ActiveEnvironmentEnum, useSession } from 'contexts/sessionContext';
import { Role } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useUser } from 'hooks/user';
import Icon from 'components/atoms/Icon';
import Margin from 'components/atoms/Margin';
import { Selected } from '../AssociationDropdown/styles';

export interface EnvironmentDropdownProps { }

const EnvironmentDropdown: FC<EnvironmentDropdownProps> = () => {
    const { switchActiveEnvironment } = useUser();
    const { session } = useSession();
    const { t } = useTranslation('environmentSelect');

    // Render.
    // ~ Rights.
    const showAdminOption = session?.role === Role.Admin;
    const showAssociationOption = showAdminOption;
    const showGymOption = showAdminOption || (session?.gymRights != null && session.gymRights.length > 0);
    const showEmployerOption = showAdminOption;
    const showContentOption = showAdminOption;
    const showSupplierOption = showAdminOption;
    const showProviderOption = showAdminOption;

    const items: ((close?: () => void) => DropdownItemProps[]) | DropdownItemProps[] = [
        {
            access: showAdminOption,
            value: ActiveEnvironmentEnum.Admin,
            title: t('adminEnvironment'),
            onClick: () => switchActiveEnvironment(ActiveEnvironmentEnum.Admin)
        },
        {
            access: showAssociationOption,
            value: ActiveEnvironmentEnum.Association,
            title: t('associationEnvironment'),
            onClick: () => switchActiveEnvironment(ActiveEnvironmentEnum.Association)
        },
        {
            access: showGymOption,
            value: ActiveEnvironmentEnum.Gym,
            title: t('gymEnvironment'),
            onClick: () => switchActiveEnvironment(ActiveEnvironmentEnum.Gym)
        },
        {
            access: showEmployerOption,
            value: ActiveEnvironmentEnum.Employer,
            title: t('employerEnvironment'),
            onClick: () => switchActiveEnvironment(ActiveEnvironmentEnum.Employer)
        },
        {
            access: showContentOption,
            value: ActiveEnvironmentEnum.Content,
            title: t('contentEnvironment'),
            onClick: () => switchActiveEnvironment(ActiveEnvironmentEnum.Content)
        },
        {
            access: showSupplierOption,
            value: ActiveEnvironmentEnum.Supplier,
            title: t('supplierEnvironment'),
            onClick: () => switchActiveEnvironment(ActiveEnvironmentEnum.Supplier)
        },
        {
            access: showProviderOption,
            value: ActiveEnvironmentEnum.Provider,
            title: t('providerEnvironment'),
            onClick: () => switchActiveEnvironment(ActiveEnvironmentEnum.Provider)
        }
    ];

    let selected = items.find((option) => option.value === session?.activeEnvironment) || items[0];
    if (ActiveEnvironmentEnum.Location === session?.activeEnvironment) {
        selected = items[2];
    }

    return (
        <>
            {showAdminOption && !!selected &&
                <Dropdown items={items}>
                    <Margin right={0.5} alignItems="center">
                        <Icon name="settings" />
                    </Margin>
                    <Selected>{selected?.title}</Selected>
                </Dropdown>
            }
        </>
    );
};

export default EnvironmentDropdown;
