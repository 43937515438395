import styled from '@emotion/styled';
import Icon from 'components/atoms/Icon';

export const DropdownItem = styled.li`
    list-style-type: none;
    display: inline-flex;
    height: 2.5rem;
    width: 100%;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid ${({ theme }) => theme.greys[50]};
    font-size: 0.875rem;
    font-weight: 500;

    &:hover {
        background-color: ${({ theme }) => theme.greys[50]};
        cursor: pointer;
    }

    &:last-of-type {
        border-bottom: none;
    }
`;

export const DropdownIcon = styled(Icon)`
    margin-right: 8px;
`;

export const NestedIcon = styled(Icon)`
    margin-left: 8px;
`;

export const Title = styled.span`
    flex: 1;
`;