import { Button } from 'components/atoms/button';
import { StepProps } from 'components/atoms/Step';
import Group from 'components/molecules/Group';
import RoundStepper from 'components/molecules/RoundStepper';
import { Form, Formik, FormikHelpers, FormikTouched, FormikValues } from 'formik';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseSchema } from 'yup';

interface Step<T> extends StepProps {
    child: ReactNode;
    onSubmit?: (values: T, helpers: FormikHelpers<T>) => Promise<boolean>;
    validationSchema?: BaseSchema;
    bypassErrors?: boolean;
}

export interface WizardProps<T> {
    steps: Step<T>[];
    hideStepper?: boolean;
    initialValues: T;
    initialStep?: number;
    onSubmit: (values: T, helpers: FormikHelpers<T>) => void;
    onSubmitLabel?: string;
    handleOnNextStep?: (stepNumber: number) => void;
    scrollToTop?: boolean;
}

export function RoundWizard<T extends FormikValues>({
    steps: initialSteps,
    hideStepper,
    initialValues,
    onSubmit,
    onSubmitLabel,
    initialStep = 0,
    scrollToTop = false,
    handleOnNextStep
}: WizardProps<T>) {
    const { t } = useTranslation('wizard');
    const [number, setNumber] = useState<number>(initialStep);
    const [snapshot, setSnapshot] = useState<T>(initialValues);
    const ref = React.createRef<HTMLFormElement>();

    // Wizard's methods.
    const next = (values: T, setTouched: (touched: FormikTouched<T>, shouldValidate?: boolean) => void) => {
        handleStepChange(values, Math.min(number + 1, totalSteps - 1), setTouched);
        if (scrollToTop) {
            ref.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const previous = (values: T, setTouched: (touched: FormikTouched<T>, shouldValidate?: boolean) => void) => {
        handleStepChange(values, Math.max(number - 1, 0), setTouched);
        if (scrollToTop) {
            ref.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleStepChange = (values: T, number: number, setTouched: (touched: FormikTouched<T>, shouldValidate?: boolean) => void) => {
        setTouched({}, false);
        setNumber(number);
        setSnapshot(values);
        if (handleOnNextStep !== undefined) {
            handleOnNextStep(number);
        }
    };

    const getPreviousStep = (number: number): Step<T> => {
        return initialSteps[Math.max(number - 1, 0)];
    };

    const handleSubmit = async (values: T, helpers: FormikHelpers<T>) => {
        if (step.onSubmit) {
            const result = await step.onSubmit(values, helpers);

            if (!result) {
                return;
            }
        }

        if (lastStep) {
            return onSubmit(values, helpers);
        } else {
            next(values, helpers.setTouched);
        }
    };

    // Render.
    const step = initialSteps[number];
    const totalSteps = initialSteps.length;
    const firstStep = number === 0;
    const lastStep = number === totalSteps - 1;

    return (
        <>
            <Formik<T> initialValues={snapshot} onSubmit={handleSubmit} validationSchema={step.validationSchema} enableReinitialize>
                {({ values, setTouched, isValid, isSubmitting }) => {
                    const steps = initialSteps.map((step, index) => ({
                        ...step,
                        highlight: number > index,
                        active: index === number,
                        disabled: !getPreviousStep(index).validationSchema?.isValidSync(values),
                        onClick: () => handleStepChange(values, index, setTouched),
                        completed: step.validationSchema?.isValidSync(values) && number !== index ? true : false,
                    }));

                    return (
                        <Form ref={ref}>
                            {!hideStepper && <RoundStepper steps={steps} />}
                            {step.child}
                            <Group size="lg">
                                {!firstStep && (
                                    <Button
                                        type="button"
                                        onClick={() => previous(values, setTouched)}
                                        isOutline
                                        rounded
                                        brand="pink"
                                        size="lg"
                                    >
                                        {t('back')}
                                    </Button>
                                )}
                                <Button
                                    type="submit"
                                    disabled={isSubmitting || (!step.bypassErrors && !isValid) || (step.validationSchema !== undefined && !step.validationSchema.isValidSync(values))}
                                    loading={isSubmitting}
                                    rounded
                                    brand="pink"
                                    size="lg"
                                >
                                    {lastStep ? onSubmitLabel || t('confirm') : t('next')}
                                </Button>
                            </Group>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default RoundWizard;

