import React, { FC } from 'react';
import { useUserAuthorization } from 'hooks/authorization/useUserAuthorization';
import { UseSelectedEmployerReturn } from './useSelectedEmployer';
import { CustomSelect, CustomSelectOption, CustomSelectProps, CustomSelectValue } from 'components/atoms/Select';

export type EmployerDropdownProps = Pick<UseSelectedEmployerReturn, 'employers' | 'selectedEmployer' | 'handleEmployerChange'> &
    Partial<CustomSelectProps>;

export const EmployerDropdown = ({ employers = [], selectedEmployer, handleEmployerChange, ...rest }: EmployerDropdownProps) => (
    <CustomSelect
        isLight
        options={employers}
        onChange={(selected: CustomSelectValue) => handleEmployerChange((selected as CustomSelectOption)?.value as string, employers)}
        value={selectedEmployer}
        {...rest}
    />
);

export const EmployerDropdownWithAuth: FC<EmployerDropdownProps> = (props : EmployerDropdownProps) => {
    const { isAdmin } = useUserAuthorization();
    const hasMultipleEmployers = props.employers.length > 1;

    return isAdmin || hasMultipleEmployers ? <EmployerDropdown {...props} /> : null;
};
