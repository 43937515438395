import React, { FC, InputHTMLAttributes } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import { FormControl as Input, Wrapper } from './styles';

export interface FormStatisticProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    error?: string;
    onChange?: (value: string) => void;
    label: string;
}

const FormStatistic: FC<FormStatisticProps> = ({ error, onChange, ...props }) => {
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event.target.value);
        }
    };

    return (
        <Wrapper>
            <p>{props.label}</p>
            <Input {...props} onChange={handleOnChange} />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </Wrapper>
    );
};

export default FormStatistic;
