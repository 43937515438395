
import React, { FC, useState, useEffect } from 'react';
import Dropdown from '../Dropdown';
import { useUser } from 'hooks/user';
import { useSession } from 'contexts/sessionContext';
import { defaultAreaRoute } from 'utils/areaHelper';
import { AssociationPlainModel, useAssociations } from 'hooks/associations';
import Margin from 'components/atoms/Margin';
import Icon from 'components/atoms/Icon';
import { Selected } from './styles';
import { useNavigate } from 'react-router-dom';

export interface AssociationDropdownProps { }

const AssociationDropdown: FC<AssociationDropdownProps> = () => {
    const [associations, setAssociations] = useState<AssociationPlainModel[]>([]);
    const { session } = useSession();
    const { switchActiveAssociation } = useUser();
    const { allPlainAssociations } = useAssociations();
    const navigate = useNavigate();

    // Life cycle
    useEffect(() => {
        getAssociations();
    }, []);

    // Methods
    const handleOnSubItemClick = async (value: string) => {
        const response = await switchActiveAssociation(value);
        if (response.ok && session?.role && session.activeEnvironment) {
            const route = defaultAreaRoute(session.role, session.activeEnvironment);
            navigate(route);
        } else {
            // TODO: set prev value or something.
        }
    };

    const getAssociations = async () => {
        const response = await allPlainAssociations();
        if (response.ok && response.data) {
            setAssociations(response.data);
        }
    };

    // Render.
    const activeAssociationId = session?.activeAssociationId;
    const dropdown = associations.map((association) => ({
        title: association.name,
        value: association.id
    }));
    const selected = associations.find((item) => item.id === activeAssociationId);

    return (
        <Dropdown
            items={dropdown}
            onSubItemClick={(value) => handleOnSubItemClick(value as string)}
        >
            <Margin right={0.5} alignItems="center">
                <Icon name="company" />
            </Margin>
            <Selected>{selected?.name}</Selected>
        </Dropdown>
    );
};

export default AssociationDropdown;
