import React, { FC, PropsWithChildren, useMemo } from 'react';
import { H3 as Text, Wrapper, H3Icon as Icon, NotificationsWrapper } from './styles';
import { useTheme } from '@emotion/react';
import { Theme } from 'static/theme';
import { Colors } from 'static/theme/colors';
import Notification from 'components/atoms/Notification';
import { Icons } from 'static/theme/icons';

export interface H3Props {
    variant?: keyof Colors;
    grey?: number;
    center?: boolean;
    className?: string;
    icon?: keyof Icons;
    notifications?: number | string;
    notificationsPosition?: 'left' | 'right';
    wrap?: boolean;
}

const H3: FC<PropsWithChildren<H3Props>> = ({ children, variant, grey, center, className, icon, notifications, notificationsPosition = 'left', wrap }) => {
    const theme = useTheme() as Theme;
    const color = useMemo(() => (variant ? theme.colors[variant] : grey ? theme.greys[grey] : theme.colors.black), [variant, grey]);

    return (
        <Wrapper className={className}>
            {notifications != null && (
                <NotificationsWrapper position={notificationsPosition}>
                    <Notification>{notifications}</Notification>
                </NotificationsWrapper>
            )}
            {icon && <Icon name={icon} color={color} />}
            <Text color={color} center={center} wrap={wrap}>
                {children}
            </Text>
        </Wrapper>
    );
};

export default H3;
