import React from 'react';
import { CellProps, Hooks } from 'react-table';
import { FormCheck } from 'components/atoms/form';

const useRowSelection = <T extends object>(isRowSelect?: boolean) => {
    return (hooks: Hooks<T>) => {
        if (!isRowSelect) {
            return;
        }

        hooks.visibleColumns.push((columns) => [
            {
                id: 'selection',
                Header: ({ getToggleAllPageRowsSelectedProps }) => <FormCheck {...getToggleAllPageRowsSelectedProps()} />,
                Cell: ({ row }: CellProps<T>) => <FormCheck {...row.getToggleRowSelectedProps()} />,
                width: 40
            },
            ...columns
        ]);
    };
};

export default useRowSelection;
