import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormikFormControl, FormGroup, FormikFormTextarea, FormikFormControlSelect, FormikFormDate, FormikFormSelect } from 'components/atoms/form';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Col, Row } from 'react-grid-system';
import { getMeasurementByTheme, getMeasurementUnitByMeasurement, getDefaultMeasurementUnitByMeasurement, getActivityByTheme, getDaysOfChallenge } from 'utils/challengeHelper';
import { ChallengeTemplateValues } from '..';
import { ChallengeMeasurementUnitType, ChallengeCompletionPeriodType, ActivityAllType, ChallengeRatingType, ChallengeMeasurementType, ChallengeThirdPartyType, ActivityType, ChallengeThemeType } from 'hooks/challengeTemplate';
import { differenceInDays } from 'date-fns';
import Group from 'components/molecules/Group';
import { Button } from 'components/atoms/button';
import FormCheckFormik from 'components/atoms/form/FormCheck/formik';

interface Step1Props {
    mode: 'edit' | 'add';
}

const Step3: FC<Step1Props> = ({ mode }) => {
    const { t } = useTranslation('challengeTemplate');
    const { values, setFieldValue } = useFormikContext<ChallengeTemplateValues>();
    const [loaded, setLoaded] = useState<boolean>(false);

    function getFrequencyPeriod(): string {
        let frequency = '';
        switch (values.frequencyPeriod) {
            case ChallengeCompletionPeriodType.PerDay:
                frequency = values.frequencyAmount > 1 ?
                    t('description.frequencyPeriodType.PerDay.multi', { value: values.frequencyAmount, freq: values.frequencyPeriodAmount })
                    : t('description.frequencyPeriodType.PerDay.single', { freq: values.frequencyPeriodAmount });
                break;
            case ChallengeCompletionPeriodType.PerWeek:
                frequency = values.frequencyAmount > 1 ?
                    t('description.frequencyPeriodType.PerWeek.multi', { value: values.frequencyAmount, freq: values.frequencyPeriodAmount })
                    : t('description.frequencyPeriodType.PerWeek.single', { freq: values.frequencyPeriodAmount });
                break;
            // case ChallengeCompletionPeriodType.PerMonth:
            //     frequency = values.frequencyAmount > 1 ?
            //         t('description.frequencyPeriodType.PerMonth.multi', { value: values.frequencyAmount, freq: values.frequencyPeriodAmount })
            //         : t('description.frequencyPeriodType.PerMonth.single', { freq: values.frequencyPeriodAmount });
            //     break;
        }
        return frequency;
    }

    function getActivity(): string {
        return t('description.activityOptions.' + ActivityAllType[values.activity]);
    }

    function getMeasurement(): string {
        return `${values.measurementAmount} ${t('step3.measurementUnitType.' + ChallengeMeasurementUnitType[values.measurementUnit]).toLowerCase()}`;
    }

    const generateActivityDescription = () => {
        const activity = getActivity();
        const frequency = getFrequencyPeriod();
        const measurement = getMeasurement();

        switch (values.activity) {
            case ActivityType.GymVisits:
            case ActivityType.FoodAndDrinks:
            case ActivityType.Walk:
            case ActivityType.Meditation:
                return `${activity} ${frequency} ${measurement}`;
            case ActivityType.Ride:
            case ActivityType.Swim:
            case ActivityType.Run:
            default:
                return t('description.activity', { value: `${frequency} ${measurement} ${activity}` });
        }
    };

    useEffect(() => {
        if (loaded) {
            const defaultThemeValues = getDefaultMeasurementUnitByMeasurement(values.measurement);
            setFieldValue('measurement', values.measurement);
            setFieldValue('measurementUnit', defaultThemeValues);
        }
    }, [values.measurement]);

    useEffect(() => {
        if (loaded && values.thirdParty !== undefined) {
            if (values.thirdParty === ChallengeThirdPartyType.StepCounter) {
                setFieldValue('measurement', ChallengeMeasurementType.Amount);
                setFieldValue('measurementUnit', ChallengeMeasurementUnitType.Steps);
            }
        }
    }, [values.thirdParty]);

    useEffect(() => {
        if (values.hasDate && values.startDate !== undefined && values.endDate !== undefined) {
            setFieldValue('startDate', values.startDate);
            setFieldValue('endDate', values.endDate);
        }
        if (values.hasFromDate) {
            setFieldValue('fromDate', values.fromDate);
            setFieldValue('fromEndDate', values.fromEndDate);
        }
    }, [values.completionAmount, values.completionPeriod]);

    const firstUpdate = useRef(true);
    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            setLoaded(!firstUpdate.current);
        }
    });

    const validateFromDate = (date: Date, name: string) => {
        if (values.startDate && differenceInDays(values.startDate, date) < 0) {
            return t('step3.visibilityError');
        }
        if (values.hasFromDate && values.fromDate !== undefined && values.fromEndDate !== undefined) {
            const differenceDays = differenceInDays(values.fromEndDate, date);
            return generateValidateDateError(differenceDays);
        }
    };

    const validateFromEndDate = (date: Date, name: string) => {
        if (values.endDate && differenceInDays(values.endDate, date) > 0) {
            return t('step3.visibilityError');
        }
        if (values.hasFromDate && values.fromDate !== undefined && values.fromEndDate !== undefined) {
            const differenceDays = differenceInDays(date, values.fromDate);
            return generateValidateDateError(differenceDays);
        }
    };

    // check if maybe can be cleaned up with using setFormikState instead
    const validateStartDate = (date: Date, name: string) => {
        if (values.fromDate && differenceInDays(values.fromDate, date) > 0) {
            return t('step3.visibilityError');
        }
        if (values.hasDate && values.startDate !== undefined && values.endDate !== undefined) {
            const differenceDays = differenceInDays(values.endDate, date);
            return generateValidateDateError(differenceDays);
        }
    };

    const validateEndDate = (date: Date, name: string) => {
        if (values.fromEndDate && differenceInDays(values.fromEndDate, date) < 0) {
            return t('step3.visibilityError');
        }
        if (values.startDate !== undefined && values.endDate !== undefined) {
            const differenceDays = differenceInDays(date, values.startDate);
            return generateValidateDateError(differenceDays);
        }
    };

    const generateValidateDateError = (differenceDays: number) => {
        const minimumDurationInDays = getDaysOfChallenge(values.completionPeriod, values.completionAmount);
        if (differenceDays < minimumDurationInDays) {
            return (t('step3.validateDate', { minimumDurationInDays: minimumDurationInDays }));
        }
    };

    const validatePeriod = (e: any) => {
        let localCompletionAmount = values.completionAmount;
        let localValue = parseInt(e.currentTarget.value);
        if (values.completionPeriod === ChallengeCompletionPeriodType.PerWeek) {
            localCompletionAmount = localCompletionAmount * 7;
        }
        if (values.frequencyPeriod === ChallengeCompletionPeriodType.PerWeek) {
            localValue = localValue * 7;
        }
        let newCompletionAmount: number = localCompletionAmount;
        const rest = localCompletionAmount % localValue;
        if (rest > localValue / 2) {
            newCompletionAmount = +newCompletionAmount + (+localValue - +rest);
        } else {
            newCompletionAmount = newCompletionAmount - rest;
        }
        if (newCompletionAmount === 0) {
            newCompletionAmount = localValue;
        }
        if (newCompletionAmount % 7 > 0 || newCompletionAmount < 7) {
            setFieldValue('completionPeriod', ChallengeCompletionPeriodType.PerDay);
        } else {
            setFieldValue('completionPeriod', ChallengeCompletionPeriodType.PerWeek);
            newCompletionAmount = Math.ceil(newCompletionAmount / 7);
        }
        setFieldValue('completionAmount', newCompletionAmount);
    };

    const completionPeriodTypes = Object.values(ChallengeCompletionPeriodType).filter((v) => typeof v === 'number')
        .map((v) => ({ title: t('step3.completionPeriodType.' + ChallengeCompletionPeriodType[v as number]), value: v as ChallengeCompletionPeriodType }));

    const frequencyPeriodTypes = Object.values(ChallengeCompletionPeriodType).filter((v) => typeof v === 'number')
        .map((v) => ({ title: t('step3.frequencyPeriodType.' + ChallengeCompletionPeriodType[v as number]), value: v as ChallengeCompletionPeriodType }));

    const ratingTypes = Object.values(ChallengeRatingType).filter((v) => typeof v === 'number')
        .map((v) => ({ title: t('step3.ratingType.' + ChallengeRatingType[v as number]), value: v as ChallengeRatingType }));

    const measurementTypes = getMeasurementByTheme(values.theme, values.thirdParty).filter((v) => typeof v === 'number')
        .map((v) => ({ title: t('step3.measurementType.' + ChallengeMeasurementType[v as number]), value: v as ChallengeMeasurementType }));

    const measurementUnitTypes = getMeasurementUnitByMeasurement(values.measurement, values.thirdParty).filter((v) => typeof v === 'number')
        .map((v) => ({ title: t('step3.measurementUnitType.' + ChallengeMeasurementUnitType[v as number]), value: v as ChallengeMeasurementUnitType }));

    const baseOption = { title: t('common:none'), value: undefined };
    const thirdPartyTypes = [baseOption, ...Object.values(ChallengeThirdPartyType).filter((v) => typeof v === 'number')
        .map((v) => ({ title: t('uniqueType.' + ChallengeThirdPartyType[v as number]), value: v as ChallengeThirdPartyType }))];

    const yesNoOptions = [{ title: t('common:yes'), value: true }, { title: t('common:no'), value: false }];

    const periodOptions = [{ title: t('visiblePeriod.limitedVisible'), value: true }, { title: t('visiblePeriod.alwaysVisible'), value: false }];

    const activityOptionsTypes = getActivityByTheme(values.theme, values.thirdParty).filter((v) => typeof v === 'number')
        .map((v) => ({ title: t('step3.activityOptions.' + ActivityAllType[v as number]), value: v as ActivityAllType }));

    return (
        <>
            <FormGroup label={t('step3.name')} required>
                <FormikFormControl
                    name="name"
                    placeholder={t('step3.name')}
                    required
                    minLength={1}
                    maxLength={64}
                />
            </FormGroup>
            {values.theme === ChallengeThemeType.Movement &&
                <FormGroup label={t('step3.thirdParty')}>
                    <FormikFormSelect<ChallengeMeasurementType>
                        name="thirdParty"
                        options={thirdPartyTypes}
                    />
                </FormGroup>
            }
            <FormGroup label={t('step3.uniqueFeatureDescription')}>
                <FormikFormControl
                    name="uniqueFeatureDescription"
                    placeholder={t('step3.uniqueFeatureDescription')}
                    maxLength={64}
                />
            </FormGroup>
            <FormGroup label={t('step3.description')} required>
                <FormikFormTextarea
                    name="description"
                    placeholder={t('step3.description')}
                    required
                    minLength={1}
                    maxLength={1024}
                />
            </FormGroup>
            <Row>
                <Col>
                    <FormGroup label={t('step3.activePeriod')}>
                        <FormikFormSelect<string>
                            name="hasDate"
                            options={periodOptions}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                {values.hasDate &&
                    <>
                        <Col sm={6}>
                            <FormGroup label={t('step3.startDate')}>
                                <FormikFormDate name="startDate" onValidate={validateStartDate} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup label={t('step3.endDate')}>
                                <FormikFormDate name="endDate" onValidate={validateEndDate} />
                            </FormGroup>
                        </Col>
                    </>
                }
            </Row>
            <Row>
                <Col>
                    <FormGroup label={t('step3.visiblePeriod')}>
                        <FormikFormSelect<string>
                            name="hasFromDate"
                            options={periodOptions}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                {values.hasFromDate &&
                    <>
                        <Col sm={6}>
                            <FormGroup label={t('step3.startDate')} required>
                                <FormikFormDate name="fromDate" onValidate={validateFromDate} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup label={t('step3.endDate')}>
                                <FormikFormDate name="fromEndDate" onValidate={validateFromEndDate} />
                            </FormGroup>
                        </Col>
                    </>
                }
            </Row>
            <Row>
                <Col>
                    <FormGroup label={t('step3.completionPeriod')}>
                        <FormikFormControlSelect<ChallengeCompletionPeriodType>
                            nameControl="completionAmount"
                            nameSelect="completionPeriod"
                            placeholder={t('step3.completionPeriod')}
                            options={completionPeriodTypes}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <FormGroup label={t('step3.returnFrequency')} required>
                        <FormikFormControl
                            name="frequencyPeriodAmount"
                            min={1}
                            max={100000}
                        />
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup label={t('per')}>
                        <FormikFormControlSelect<ChallengeCompletionPeriodType>
                            nameControl="frequencyAmount"
                            nameSelect="frequencyPeriod"
                            placeholder={t('step3.frequency')}
                            options={frequencyPeriodTypes}
                            onChangeControl={validatePeriod}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <FormCheckFormik
                            name="separateDays"
                            label={t('step3.separatedays')}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup label={t('step3.measurement')} required>
                        <FormikFormSelect<ChallengeMeasurementType>
                            name="measurement"
                            options={measurementTypes}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <FormGroup label={t('step3.measurementUnit')} required>
                        <FormikFormSelect<ChallengeMeasurementUnitType>
                            name="measurementUnit"
                            options={measurementUnitTypes}
                        />
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup label={t('step3.amount')}>
                        <FormikFormControl
                            name="measurementAmount"
                            min={1}
                            max={100000}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup label={t('step3.measure')} required>
                        <FormikFormSelect<ActivityType>
                            name="activity"
                            options={activityOptionsTypes}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup label={t('step3.activityDescription')}>
                        <FormikFormControl
                            name="activityDescription"
                            placeholder={t('step3.activityDescription')}
                            required
                            min={1}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <FormGroup>
                    <Group right>
                        <Button
                            type="button"
                            onClick={() => setFieldValue('activityDescription', generateActivityDescription())}
                            isOutline
                            rounded
                            brand="pink"
                            size="lg"
                        >
                            {t('step3.generateActivityDescription')}
                        </Button>
                    </Group>
                </FormGroup>
            </Row>
            <Row>
                <Col>
                    <FormGroup label={t('step3.challengeReward')} required>
                        <FormikFormControl
                            name="entreoPointsReward"
                            placeholder={t('step3.challengeReward')}
                            min={0}
                            max={100000}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row style={{ paddingBottom: 50 }}>
                <Col sm={6}>
                    <FormGroup label={t('step3.rating')} required>
                        <FormikFormSelect<ChallengeRatingType>
                            name="difficulty"
                            options={ratingTypes}
                        />
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup label={t('step3.sendNotification')}>
                        <FormikFormSelect<string>
                            name="sendNotifications"
                            options={yesNoOptions}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
};

export default Step3;
