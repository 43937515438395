import numeral from 'numeral';
import 'numeral/locales/nl-nl';
import { formatValue } from 'react-currency-input-field';

numeral.locale('nl-nl');

export function formatCurrency(
    value: number,
    format = '$0,0.00'
) {
    return formatNumber(value, format);
}

export function formatNumber(
    value: number,
    format = '0.00'
) {
    return numeral(value).format(format);
}

export function formatCurrencyInput(
    value: string,
    format = '$0.00'
) {
    return numeral(value).format(format);
}

export function formatLargeNumber(value: number) {
    return formatValue({
        value: value.toString(),
        groupSeparator: '.',
        decimalSeparator: '.'
      });
}