import styled from '@emotion/styled';
import theme from 'static/theme';

export const Action = styled.button`
    width: 18px;
    height: 18px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.normal}px;


    &:hover {
        cursor: pointer;
        background-color: ${theme.colors.border};
    }
`;

export const Actions = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    gap: 8px;
`;

export const Floating = styled.div`
    position: sticky;
    bottom: 1rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 60%;
    display: flex;
    align-items: center;
    gap: 1rem;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: ${({ theme }) => theme.borderRadius.large}px;
    padding: 1rem 1.5rem;
    background-color: ${({ theme }) => theme.colors.background};
    box-shadow: 0 4px 8px 4px ${({ theme }) => theme.colors.border};
    z-index: 99;
`;
