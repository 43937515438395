import { PictureBankInputModel } from 'hooks/pictureBank';
import React, { FC } from 'react';
import { Image } from './styles';

export interface PictureBankImageProps {
    id: string;
    image: string;
    model: PictureBankInputModel;
    onClick: (model: PictureBankInputModel) => void;
    isActive?: boolean;
}

const PictureBankImage: FC<PictureBankImageProps> = ({ id, image, model, isActive, onClick }) => {
    // Render.
    return <Image src={image} isActive={isActive} onClick={() => onClick && onClick(model)} />;
};

export default PictureBankImage;
