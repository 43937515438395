import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Icon from 'components/atoms/Icon';

export const H3 = styled.h3<{ color: string; center?: boolean; wrap?: boolean; }>`
    color: ${({ color }) => color};
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 600;
    margin-bottom: 0;
    white-space: ${({ wrap }) => (wrap ? 'pre-wrap' : 'nowrap')};
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Wrapper = styled.div`
    display: flex;
    margin-bottom: 0;
    align-items: center;
    min-width: 0;
`;

export const H3Icon = styled(Icon)`
    margin-right: 12px;
`;

export const NotificationsWrapper = styled.div<{ position?: 'left' | 'right' }>`
    ${({ position }) =>
        position === 'left' &&
        css`
            margin-right: 8px;
        `};

    ${({ position }) =>
        position === 'right' &&
        css`
            margin-left: 8px;
            order: 1;
        `};
`;
