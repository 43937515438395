import styled from '@emotion/styled';

export const Image = styled.img<{ isActive?: boolean }>`
    aspect-ratio: 1;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: ${({ theme }) => theme.borderRadius.normal}px;
    border: ${({ theme, isActive }) => (isActive ? `3px solid${theme.colors.pink}` : 'none')};

    &:hover {
        cursor: pointer;
    }
`;
