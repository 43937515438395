import styled from '@emotion/styled';

export const Action = styled.button`
    width: 1.5rem;
    height: 1.5rem;
    border: none;
    margin-right: 8px;

    &:hover {
        cursor: pointer;
    }
`;
