import React, { FC, InputHTMLAttributes } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import { FormTextarea as Wrapper } from './styles';

export interface FormTextareaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
    error?: string;
    darkBorder?: boolean;
}

const FormTextarea: FC<FormTextareaProps> = ({ error, ...props }) => {
    return (
        <>
            <Wrapper {...props} rows={3} />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormTextarea;
