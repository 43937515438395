import React, { FC, InputHTMLAttributes } from 'react';
import Icon from 'components/atoms/Icon';
import { Checkbox, HiddenCheckbox, Label, Wrapper } from './styles';
import colors from 'static/theme/colors';
import InvalidFeedback from '../InvalidFeedback';
import Margin from 'components/atoms/Margin';

export interface FormCheckProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    error?: string;
    blackCheck?: boolean;
}

const FormCheck: FC<FormCheckProps> = ({ label, checked, error, style, blackCheck, ...props }) => {
    return (
        <>
            <Wrapper label={label}>
                <HiddenCheckbox type="checkbox" checked={checked} {...props} />
                <Checkbox checked={checked} style={style}>
                    {checked && <Icon name="check-bold" size={.75} color={blackCheck ? colors.black : colors.white} />}
                </Checkbox>
                {label && (
                    <Margin left={.5}>
                        <Label>{label}</Label>
                    </Margin>
                )}
            </Wrapper>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormCheck;
