import styled from '@emotion/styled';
import { Paragraph } from 'components/atoms/text';

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    align-items: center;
`;

export const Title = styled(Paragraph)`
    flex: 1;
`;

export const Content = styled.div``;
