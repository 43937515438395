import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { rgba } from 'polished';

export const DropdownMenu = styled(motion.ul) <{ width?: number }>`
    z-index: 104;
    min-width: 100px;
    border-radius: ${({ theme }) => theme.borderRadius.normal}px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0.5rem 0;
    box-shadow: 0 10px 20px ${({ theme }) => rgba(theme.colors.dark, 0.2)};
    overflow-y: auto;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    
    ${({ width }) => width &&
        css`
            min-width: ${width}px;
        `
    }
`;
