import { useField } from 'formik';
import React, { FC } from 'react';
import FormTextarea, { FormTextareaProps } from '..';

interface FormikFormTextareaProps extends FormTextareaProps {
    name: string;
}

const FormikFormTextarea: FC<FormikFormTextareaProps> = (props) => {
    const [field, meta] = useField(props);
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    return <FormTextarea {...field} {...props} error={error} />;
};

export default FormikFormTextarea;
