export interface Header {
    height: number;
    padding: {
        horizontal: number;
    };
}

const header: Header = {
    height: 5.5,
    padding: {
        horizontal: 40
    }
};

export default header;

