import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Size } from 'static/theme';

export const Group = styled.div<{ spaceBetween?: boolean; right?: boolean, size?: keyof Size }>`
    display: flex;
    justify-content: ${({ spaceBetween, right }) => (spaceBetween ? 'space-between' : right ? 'flex-end' : 'flex-start')};
    align-items: center;

    > * {
        margin-right: ${({ size }) => (size === 'lg' ? 16 : 8)}px;

        ${({ spaceBetween, right }) =>
            spaceBetween &&
            right &&
            css`
                :only-child {
                    margin-left: auto;
                }
            `};

        &:last-child {
            margin-right: 0;
        }
    }
`;
