import { css } from '@emotion/react';
import styled from '@emotion/styled';
import theme from 'static/theme';

export const Header = styled.div<{ noBorderLine?: boolean }>`
    position: relative;
    padding: 28px 0px 16px;
    margin: 0px ${({ theme }) => theme.card.padding.horizontal}px 0px;
    font-weight: 500;
    font-size: 0.825rem;
    display: flex;
    flex-direction: column;
    
    ${({ noBorderLine }) =>
        noBorderLine ??
        css` 
            border-bottom: 1px solid ${theme.colors.light};
        `
    }

    .swiper-pagination {
        bottom: 0;
        position: relative;
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
`;

export const ImageRow = styled.div`
    margin-bottom: 1rem;
`;

export const UpperRow = styled.div`
    display: flex;
    justify-content: space-between;

`;

export const LowerRow = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const Right = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const Subtitle = styled.span`
    color: #8e8e8e;
    font-size: 16px;
    margin-top: 6px;
    display: block;
`;
