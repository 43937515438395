import styled from '@emotion/styled';

export const User = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.normal}px;
  font-size: 1rem;
`;

export const Name = styled.span`
  margin-left: 16px;
  font-weight: 600;
  font-size: 1em;
  display: block;
`;

export const Email = styled.span`
  margin-left: 16px;
  font-weight: 600;
  font-size: .75em;
  display: block;
  margin-top: .35rem;
  color: ${({ theme }) => theme.colors.grey};
`;
