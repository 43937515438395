import React, { useCallback, useState } from 'react';
import useModal from '../modal';
import Modal from 'components/organisms/Modal';
import { useTranslation } from 'react-i18next';
import Loading from 'components/atoms/Loading';
import { toast } from 'react-toastify';
import { Form, Formik, FormikHelpers } from 'formik';
import { toFormikErrors } from 'utils/errorhelper';
import Panel from 'components/organisms/Panel';
import { useTagGroup } from 'hooks/entreoTagGroup';
import { AddTagGroupInputModel, EditTagGroupInputModel, TagGroupModel, TagType } from 'hooks/entreoTagGroup/types';
import { FormGroup, FormikFormCheck, FormikFormControl, FormikFormSelect } from 'components/atoms/form';
import { enumSelector } from 'utils/objectAppender';

const useTagGroupModal = (onRefresh: () => void): [(taggroupId?: string) => void, () => void, boolean] => {
    const { t } = useTranslation('entreoProductTagGroup');
    const { addTagGroup, getTagGroup, editTagGroup } = useTagGroup();

    // State.
    const [tagGroup, setTagGroup] = useState<TagGroupModel | undefined>();
    const [typeOptions, setTypeOptions] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const fetchTagGroups = async (tagGroupId: string) => {
        setIsLoading(true);
        const response = await getTagGroup(tagGroupId ?? '');
        if (response && response.data) {
            const tagGroupData = response.data;
            setTagGroup(tagGroupData);
        }
        setIsLoading(false);
    };

    // Methods.
    const handleOnSubmit = async (values: EditTagGroupInputModel, helpers: FormikHelpers<EditTagGroupInputModel>) => {
        if (isSubmitting) {
            return;
        }
        if (!!tagGroup && ('id' in values)) {
            handleEdit(values, helpers as FormikHelpers<EditTagGroupInputModel>);
        } else {
            handleAdd(values, helpers);
        }
    };

    const handleAdd = async (values: AddTagGroupInputModel, helpers: FormikHelpers<AddTagGroupInputModel>) => {
        setIsSubmitting(true);

        const response = await addTagGroup(values);

        if (response.ok) {
            onRefresh();
            toast.success(t('common:successAdd'));
            hide();
        } else if (response.errors) {
            const errors = toFormikErrors(response.errors);
            helpers.setErrors(errors);
        } else {
            toast.warning(t('common:general_error'));
            //Show errors in the steps?
        }
        setIsSubmitting(false);
    };

    const handleEdit = async (values: EditTagGroupInputModel, helpers: FormikHelpers<EditTagGroupInputModel>) => {
        setIsSubmitting(true);
        const response = await editTagGroup(values);

        if (response.ok) {
            onRefresh();
            toast.success(t('common:successEdit'));
            hide();
        } else if (response.errors) {
            const errors = toFormikErrors(response.errors);
            helpers.setErrors(errors);
        } else {
            toast.warning(t('common:general_error'));
            //Show errors in the steps?
        }
        setIsSubmitting(false);
    };

    const setInitialValues = (): EditTagGroupInputModel => {
        return tagGroup ? {
            id: tagGroup.id,
            description: tagGroup.description,
            type: tagGroup.type,
            mandatory: tagGroup.mandatory,
        } : {
            description: '',
            type: undefined,
            mandatory: false
        };
    };

    const getTypes = () => {
        let typeArr = enumSelector(TagType);
        typeArr = typeArr.map((arr) => { return { value: arr.value, title: t(`tagGroupType.${arr.title}`) }; });
        setTypeOptions(typeArr);
    };

    // Render.
    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => {
            if (isLoading) {
                return (
                    <Modal onHide={onHideModal} inProp={inProp} onExited={onExited} title={t('title')} size="large">
                        <Loading />
                    </Modal>
                );
            }
            const initialValues = setInitialValues();

            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            return (
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleOnSubmit}
                    isSubmitting={isSubmitting}
                    validateOnMount
                    validateOnChange
                >
                    {({ submitForm }) => (
                        <Panel
                            onHide={onHideModal}
                            inProp={inProp}
                            onExited={onExited}
                            title={t(`modal.form.title.${tagGroup === undefined ? 'add' : 'edit'}`)}
                            onCloseSide
                            buttons={[{ title: t('common:button.confirm'), onClick: submitForm }]}
                        >
                            <Form>
                                <FormGroup label={t('form.name')} required>
                                    <FormikFormControl name="description" />
                                </FormGroup>
                                <FormGroup label={t('form.type')} required>
                                    <FormikFormSelect<number> name="type" options={typeOptions} />
                                </FormGroup>
                                <FormGroup label={t('form.mandatory')}>
                                    <FormikFormCheck label="Binnen deze taggroep moet een waarde geselecteerd zijn om verder te kunnen in een formulier" name="mandatory" />
                                </FormGroup>
                            </Form>
                        </Panel>
                    )}
                </Formik>
            );
        },
        [isLoading, tagGroup, typeOptions, isSubmitting]
    );

    const showModal = useCallback(
        async (tagGroupId?: string) => {
            getTypes();
            if (tagGroupId) {
                fetchTagGroups(tagGroupId);
            }
            show();
        },
        [show]
    );

    const onHideModal = () => {
        setTagGroup(undefined);
        hide();
    };

    return [showModal, hide, isShowing];
};

export default useTagGroupModal;

