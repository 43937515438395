import Table from 'components/molecules/table/Table';
import { Title } from 'components/organisms/header';
import { useProviderSubscription } from 'hooks/entreoSubscription';
import { SubscriptionAdminModel } from 'hooks/entreoSubscription/types';
import { useSubscriptionModal, useConfirmPanel } from 'hooks/modals';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { toast } from 'react-toastify';
import { formatCurrency } from 'utils/numeralFormatter';
import { useSubscriptionsPermissions } from './useSubscriptionsPermissions';

export interface SubscriptionOverviewProps {}

const SubscriptionOverview: FC<SubscriptionOverviewProps> = () => {
    const { adminSubscriptions, removeSubscription } = useProviderSubscription();
    const permissions = useSubscriptionsPermissions();

    const { t } = useTranslation('entreoSubscription');
    const [isLoading, setIsLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState<SubscriptionAdminModel[]>([]);

    const fetchSubscriptions = async () => {
        setIsLoading(true);
        const response = await adminSubscriptions();

        if (response.ok && response.data) {
            setSubscriptions(response.data);
        } else {
            toast.error(t('fetchAllError'));
        }
        setIsLoading(false);
    };

    const handleRemoveSubscription = async (id?: string) => {
        if (id === undefined) return;

        setIsLoading(true);
        const response = await removeSubscription(id);

        if (response.ok) {
            fetchSubscriptions();
            toast.success(t('common:successDelete'));
        } else {
            toast.error(t('fetchAllError'));
        }
        setIsLoading(false);
    };

    const columns: Column<SubscriptionAdminModel>[] = [
        {
            Header: t('table.column.provider'),
            accessor: (item) => item.providerName
        },
        {
            Header: t('table.column.name'),
            accessor: (item) => item.name
        },
        {
            Header: t('table.column.monthlyCosts'),
            accessor: (item) => formatCurrency(item.monthlyCosts)
        },
        {
            Header: t('table.column.hasImage'),
            accessor: (item) => (item.hasImage ? 'Ja' : 'Nee')
        }
    ];

    const [show] = useSubscriptionModal(fetchSubscriptions);

    const [showDelete] = useConfirmPanel({
        onConfirm: handleRemoveSubscription,
        title: t('modal.delete.title'),
        subtitle: t('modal.delete.subtitle'),
        description: t('modal.delete.description')
    });

    useEffect(() => {
        fetchSubscriptions();
    }, []);

    return (
        <>
            <Title title={t('adminTitle')} />
            <Table<SubscriptionAdminModel>
                search
                columns={columns}
                data={subscriptions}
                isLoading={isLoading}
                actions={[
                    {
                        icon: 'edit',
                        hide: !permissions.hasEditPermission,
                        onClick: (item) => show(item.id)
                    },
                    {
                        icon: 'delete',
                        hide: !permissions.hasDeletePermission,
                        onClick: (item) => showDelete(item.id)
                    }
                ]}
            />
        </>
    );
};

export default SubscriptionOverview;
