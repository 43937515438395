import React, { FC, Suspense, useMemo } from 'react';
import { Wrapper, Content, Empty } from './styles';
import { css, Global, useTheme } from '@emotion/react';
import Loading from 'components/atoms/Loading';
import { rgba } from 'polished';
import Decorations from 'static/images/decorations-background-top-right.svg';
import { Outlet } from 'react-router';
import { AppHeader } from 'components/organisms/AppHeader';
import { AppNavigationMenu } from 'components/organisms/AppNavigationMenu';
import { usePageTemplate } from './usePageTemplate';

const GlobalStyles = () => {
    const theme = useTheme();

    const styles = useMemo(
        () => css`
            body {
                background-attachment: fixed;
                background: ${rgba(theme.colors.blue, 0.1)};
                background: linear-gradient(
                        115deg,
                        ${rgba(theme.colors.blue, 0.1)} -15%,
                        ${rgba(theme.colors.yellow, 0.1)} 46%,
                        ${rgba(theme.colors.pink, 0.1)} 87%
                    ),
                    #f0f3ff;
            }
        `,
        [theme]
    );

    return <Global styles={styles} />;
};

export const PageTemplate: FC = () => {
    const { isOpen, menuItems, navState, userAuthorization, handleNavBarToggle } = usePageTemplate();

    return userAuthorization.userId ? (
        <>
            <GlobalStyles />
            <Wrapper>
                <AppNavigationMenu isOpen={isOpen} items={menuItems} toggle={handleNavBarToggle} />
                <AppHeader />
                <Content navState={navState} decorations={Decorations}>
                    <Suspense fallback={<Loading isFull />}>
                        <Outlet />
                    </Suspense>
                </Content>
            </Wrapper>
        </>
    ) : (
        <Empty>
            <GlobalStyles />
            <Loading isFull />
        </Empty>
    );
};
