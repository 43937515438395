import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Tabs = styled.ul`
    width: fit-content;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    gap: 2.625rem;
    padding-inline-start: 0;
    list-style-type: none;
`;

export const Tab = styled.li<{ active: boolean; }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2.25rem;
    padding-bottom: 0.625rem;
    font-size: 1rem;
    font-weight: 600;
    outline: none;
    color: ${({ theme }) => theme.colors.grey};
    cursor: pointer;

    ${({ active, theme }) =>
        active &&
        css`
        color: ${theme.brand.pink};
        box-shadow: 0 2px 0 ${theme.brand.pink};
    `}
`;

