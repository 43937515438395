import { useApi } from 'hooks/api';
import { UserOverviewModel } from 'hooks/user';

export interface AddContentEnvironmentModel {
    name: string;
    contentRightGroups: string[];
}

export interface ContentEnvironmentPlainModel {
    id: string;
    name: string;
}

export interface ContentEnvironmentModel {
    id: string;
    name: string;
    contentCreators: UserOverviewModel[];
}

export const useContentEnvironment = () => {
    const { callApi } = useApi();

    const addContentEnvironment = async (model: AddContentEnvironmentModel) => {
        const response = await callApi.current('/v1/ContentEnvironment',
            {
                method: 'POST',
                body: JSON.stringify(model)
            }
        );
        return response;
    };

    const deleteContentEnvironment = async (contentEnvironmentId: string) => {
        const response = await callApi.current(
            `/Contentenvironment/v1/${contentEnvironmentId}`,
            {
                method: 'DELETE',
            }
        );
        return response;
    };

    const deleteContentCreatorFromContentEnvironment = async (contentEnvironmentId: string, contentCreatorId: string) => {
        const response = await callApi.current(
            `/Contentenvironment/v1/contentCreators/${contentEnvironmentId}/${contentCreatorId}`,
            {
                method: 'DELETE',
            }
        );
        return response;
    };

    const getAllContentEnvironments = async () => {
        const response = await callApi.current<ContentEnvironmentPlainModel[]>('/ContentEnvironment/v1');
        return response;
    };

    const getContentEnvironment = async (contentEnvironmentId: string) => {
        const response = await callApi.current<ContentEnvironmentModel>(`/ContentEnvironment/v1/${contentEnvironmentId}`);
        return response;
    };


    return {
        getAllContentEnvironments,
        addContentEnvironment,
        deleteContentEnvironment,
        deleteContentCreatorFromContentEnvironment,
        getContentEnvironment
    };
};

