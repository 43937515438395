import styled from '@emotion/styled';
import Icon from 'components/atoms/Icon';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const Check = styled(Icon)`
    margin-bottom: 1rem;
`;
