import styled from '@emotion/styled';
import { css } from '@emotion/react';
import theme from 'static/theme';
import { rgba } from 'polished';

export const Wrapper = styled.div`
    position: relative;
    flex: 1;
`;

export const FormSelect = styled.div<{
    inline?: boolean;
    isLight?: boolean;
    small?: boolean;
    header?: boolean;
    rounded?: boolean;
    applyfilterDropdownStyle?: boolean;
}>`
    min-height: ${({ theme, small }) => (small ? 2 : theme.form.height)}rem;
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.greys[50]};
    border-radius: ${({ theme }) => theme.form.height / 2}rem;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0 16px;
    cursor: pointer;

    ${({ inline, theme }) =>
        inline &&
        css`
            position: absolute;
            background-color: ${theme.colors.background};
            height: 2.5rem;
            right: 6px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            border-radius: ${theme.borderRadius.xl}px;
            padding: 0 12px;
            font-size: 0.8rem;
            font-weight: 600;
            .icon-wrapper {
                margin-right: -5px;
                margin-left: 0;
                transform-origin: center center;
                transform: scale(0.75);
            }
        `}

    ${({ isLight }) =>
        isLight &&
        css`
            border-radius: 0;
            border: none;
            background-color: unset;

            & span {
                font-size: 1rem;
                font-weight: 500;
            }
        `}
    ${({ applyfilterDropdownStyle, theme }) =>
        applyfilterDropdownStyle &&
        css`
            margin-bottom: 20px;
            padding: 10px 20px 10px 20px;
            border: 1px solid ${theme.colors.dark};
            border-radius: 20px;
            justify-content: center;
            width: fit-content;
            min-width: 15rem;
        `}
`;

export const ValueContainer = styled.span`
    white-space: nowrap;
    font-size: ${({ theme }) => theme.form.fontSize}rem;
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    align-items: center;
    line-height: 2;
    gap: 8px;
`;

export const Option = styled.div<{ isSelected: boolean }>`
    display: inline-flex;
    gap: 12px;
    height: 2.5rem;
    width: auto;
    align-items: center;
    padding: 0 16px;
    font-size: 0.75rem;
    line-height: 2;
    transition: background 0.3s ease;
    white-space: nowrap;

    list-style-type: none;
    display: inline-flex;
    height: 2.5rem;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid ${({ theme }) => theme.greys[50]};
    font-size: 0.875rem;
    font-weight: 500;

    &:hover {
        background-color: ${({ theme }) => theme.greys[50]};
        cursor: pointer;
    }

    &:last-of-type {
        border-bottom: none;
    }
    ${({ isSelected, theme }) =>
        isSelected &&
        css`
            background-color: ${theme.greys[50]};
            border-top-color: transparent;

            + div {
                border-top-color: transparent;
            }

            &:hover {
                background-color: ${theme.greys[100]};
            }
        `}
`;

export const Menu = styled.div`
    border-radius: ${theme.borderRadius.normal}px;
    overflow: hidden;
    width: auto;
    min-width: calc(100%);
    top: 100%;
    right: 0;
    position: absolute;
    z-index: 1;
    background-color: ${theme.colors.white};
    margin-top: 0.5rem;
    width: auto;
    box-shadow: 0 10px 20px ${({ theme }) => rgba(theme.colors.dark, 0.2)};
`;

export const MenuList = styled.div`
    width: auto;
    z-index: 100;
    min-width: 100px;
    border-radius: ${({ theme }) => theme.borderRadius.normal}px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0.5rem 0;
    overflow-y: auto;
    max-height: 500px;
    display: flex;
    flex-direction: column;
`;

export const LabelWrapper = styled.div`
    padding-bottom: 5px;
`;
